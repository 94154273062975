import { capitalize } from 'lodash';
import { Component, RouteConfig, Inject } from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { HeaderTitleService } from '../../services/headerTitle';
import { ServerService } from '../../services/server';
import { KeysService } from '../../services/key-service';
import { ErrorsService } from '../../services/errors';
import { currencies, ICurrency, IExchange, ExchangeType, exchangeFunctionalityDisabled } from '../../services/settings';

@RouteConfig('/exchange', '/exchange/:action', '/exchange/:action/:currency')
@Component({
  selector: 'exchange',
  inputs: ['action', 'currency'],
  template: `
    <div layout="column" flex class="exchange-wrapper" ng-class="vm.selectedTab.name">
      <div layout="column" class="setup-information">
        <div layout="row" class="tabs">
          <div class="tab" ng-repeat="tab in vm.tabs" ng-click="vm.selectTab(tab)" ng-class="{true: 'selected'}[tab.selected]">
            {{tab.name}}
          </div>
        </div>
        <div layout="column" class="currencies-selection" ng-if="!vm.selectedTab.disabled">
          <div>
            Amount
          </div>
          <div layout="column" class="currencies">
            <div layout="column" class="selected-currency">
              <div layout="row" class="selected-currency-information">
                <div class="selected-currency-image">
                  <img ng-src="assets/{{vm.selectedCurrency.image}}" alt="{{vm.selectedCurrency.name}}" />
                </div>
                <div class="selected-currency-currency">
                  {{vm.selectedCurrency.currency}}
                </div>
                <div layout="column" class="currency-amounts">
                  <div class="currency-current-amount" layout="row">
                    <div>{{vm.amount | currency:'':8}}</div>
                    <div>{{vm.selectedCurrency.currency}}</div>
                  </div>                
                  <div class="currency-exchange-information">
                    = {{vm.fromToAmount | currency:vm.fromToSymbol:2}}
                  </div>
                </div>
                <div class="dropdown-action" ng-click="vm.showCurrencies()">
                  <img src="assets/dropdown.png" />
                </div>
              </div>
            </div>
            <div layout="column" class="currencies-wrapper" ng-show="vm.canSelectCurrency">
              <div layout="row" class="currency" ng-repeat="currency in vm.currencies" ng-class="{true: 'selected'}[vm.selectedCurrency == currency]" ng-click="vm.selectCurrency(currency)">
                <img ng-src="assets/{{currency.image}}" alt="{{currency.name}}" />
                {{currency.currency}}
              </div>
            </div>
          </div>
          <div layout="row" class="actions">
            <div class="current-price-information">
              {{vm.selectedCurrency.currency}} Price: {{vm.fromToOnePrice | currency:vm.fromToSymbol:2}}
            </div>
            <div class="action">
              <button ng-click="vm.submitAction()" ng-disabled="!vm.amount">
                {{vm.selectedTab.name}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div layout="column" class="action-information coming-soon" ng-if="vm.selectedTab.disabled">
        <div>
          Coming Soon...
        </div>
      </div>
      <div layout="column" class="action-information" ng-if="!vm.selectedTab.disabled">
        <div class="action-command">
          You are {{vm.selectedTab.description}}
        </div>
        <hr class="top-seperator" />
        <div layout="column" class="action-amounts">
          <div class="amount-to-submit">
            <input ng-model="vm.amount" ng-change="vm.onAmountChange()" required flex min="0" fraction="8" placeholder="0.00000000">
            {{vm.selectedCurrency.currency}}
          </div>                
          <div class="currency-exchange-information">
            = {{vm.fromToAmount | currency:vm.fromToSymbol:2}}
          </div>
        </div>
        <hr />
        <div layout="row" class="action-flow">
          <div layout="column" class="from-action-with-image">
            <div class="action-image">
              <img src="assets/action.png" />
            </div>
            <div class="action-type-description">
              {{vm.capitalize(vm.selectedTab.name)}} {{vm.selectedCurrency.currency}}
            </div>
          </div>
          <div layout="row" class="action-process">
            <div class="seperator"></div>
            <div class="forward"></div>
          </div>
          <div layout="column" class="to-action-with-image">
            <div class="action-image">
              <img src="assets/action.png" />
            </div>
            <div class="action-type-description">
              {{vm.selectedTab.toDescription}} {{vm.fromToCurrency}}
            </div>
          </div>
        </div>        
        <hr />
        <div layout="column" class="action-totals">
          <div layout="row" class="submission-information">
            <div class="action-amount-to-submit">
              {{vm.amount | currency:'':8}} {{vm.selectedCurrency.currency}}
            </div>
            <div class="seperator"></div>
            <div class="action-amount-to-pay">
              {{vm.fromToAmount | currency:vm.fromToSymbol:2}}
            </div>
          </div>
          <div layout="row" class="fee-information">
            <div class="action-fee-to-submit">
              Monetum Fee
            </div>
            <div class="seperator"></div>
            <div class="action-amount-to-pay">
              {{vm.fromToFeeAmount | currency:vm.fromToSymbol:2}}
            </div>
          </div>
          <div layout="row" class="total-information">
            <div class="action-total-to-submit">
              Total Payout
            </div>
            <div class="seperator"></div>
            <div class="action-amount-to-pay">
              {{vm.fromToTotalAmount | currency:vm.fromToSymbol:2}}
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
@Inject('$scope','$q','user','headerTitleService', 'server','$mdToast','$location','$translate','keys','errors')
export class ExchangeComponent {
  action:string;
  currency:string;
  selectedCurrency: ICurrency;
  selectedTab: IExchange;
  currencies: ICurrency[];
  tabs: IExchange[];
  amount: string;
  fromToCurrency: string;
  fromToSymbol: string;
  fromToAmount: number;
  fromToFeeAmount: number;
  fromToTotalAmount: number;
  fromToOnePrice: number;
  canSelectCurrency: boolean;
  feePercentage: number;

  constructor(private $scope: angular.IScope,
    private $q: angular.IQService,
    private user: UserService,
    private headerTitleService: HeaderTitleService,
    private server: ServerService,
    private $mdToast: angular.material.IToastService,
    private $location: angular.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private keys: KeysService,
    private errors: ErrorsService) {
      if (!user.requireLogin())
        return;

      this.fromToSymbol = '€';
      this.fromToAmount = 0;
      this.fromToCurrency = 'EUR';
      this.feePercentage = 0.02;
      this.fromToOnePrice = 5500;

      this.canSelectCurrency = false;
      this.currencies = currencies.filter(currency => {
        return !currency.disabled && currency.crypto;
      });

      if(!this.action) {
        this.action = "buy";
        $location.path(`/exchange/${this.action}`);
        return;
      }
      else {
        this.action = this.action.toLowerCase();
      }

      if(this.action === "sell") {
        this.fromToOnePrice = 5400;
      }

      this.tabs = Object.keys(ExchangeType)
        .filter(tab => typeof ExchangeType[tab as any] === "number")
        .map(tab => {
          const type: ExchangeType = ExchangeType[tab.toUpperCase()];
          const tabInformation: IExchange = {
            name: tab.toLowerCase(),
            type,
            disabled: exchangeFunctionalityDisabled.indexOf(type) != -1,
            selected: this.action === tab.toLowerCase(),
            description: '',
            toDescription: ''
          };

          switch(tabInformation.name) {
            case "buy":
              tabInformation.description = "Buying";
              tabInformation.toDescription = "Pay";
            break;
            case "sell":
              tabInformation.description = "Selling";
              tabInformation.toDescription = "Receive";
            break;
            case "trade":
              tabInformation.description = "Trading";
              tabInformation.toDescription = "Trade";
            break;
          }

          return tabInformation;
      });

      this.selectTab();

      if(!this.selectedTab.disabled) {
        if(this.currency) {
          this.selectedCurrency = this.currencies.find(currency => currency.currency.toLowerCase() === this.currency.toLowerCase());
        }
        else {
          const currency = this.currencies[0].currency;
          this.currency = currency.toLowerCase();
          $location.path(`/exchange/${this.action}/${this.currency}`);
        }
      }
  }

  onAmountChange() {
    const currentToAmount = this.fromToOnePrice;
    const currentAmount = this.amount || 0;

    this.fromToAmount = currentToAmount * +currentAmount;
    this.fromToFeeAmount = this.feePercentage * this.fromToAmount;

    if(this.action === "sell")
      this.fromToTotalAmount = this.fromToAmount - this.fromToFeeAmount;
    else
      this.fromToTotalAmount = this.fromToAmount + this.fromToFeeAmount;
  }

  selectCurrency(currency: ICurrency) {
    const currencyToUse = currency.currency.toLowerCase();
    if(this.currency.toLowerCase() !== currencyToUse) {
      this.$location.path(`/exchange/${this.action}/${currencyToUse}`);
      return;
    }

    this.selectedCurrency = currency;
    this.canSelectCurrency = false;
  }

  showCurrencies() {
    this.canSelectCurrency = !this.canSelectCurrency;
  }

  capitalize(text: string) {
    return capitalize(text);
  }

  unselectAllTabs() {
    this.tabs.forEach(tab => tab.selected = false);
  }

  selectTab(tab?: IExchange) {
    this.amount = '';
    this.unselectAllTabs();

    if(tab) {
      let path = `/exchange/${tab.name}`;
      if(!tab.disabled && this.currency) {
        path += `/${this.currency}`;
      }
      this.$location.path(path);
      return;
    }

    const tabToSelect = this.tabs.find(tab => tab.name === this.action.toLowerCase());
    tabToSelect.selected = true;
    this.selectedTab = tabToSelect;
    this.onAmountChange();
    this.updateHeaderTitle();
  }

  updateHeaderTitle() {
    const title = {
      title: this.$translate.instant(this.getTitleString())
    };

    if(this.currency) {
      title['value'] = this.currency.toUpperCase();
    }

    this.headerTitleService.setTitle(title);
  }

  getTitleString() {
    return `translate.toolbar.${this.action.toLowerCase()}.label`;
  }

  submitAction() {
    
  }
}