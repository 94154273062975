import { RouteConfig, Component, Inject} from '../../core/decorators';
import { ServerService, IPaytahClient } from '../../services/server';
import { instance } from '../../lib/sdk';
import { keystore } from '../../lib/keystore';
import { HeaderTitleService } from '../../services/headerTitle';
import * as _ from 'lodash';
import { KeysService } from '../../services/key-service';
import { validatePassword } from '../../lib/utils';

@RouteConfig('/import')
@Component({
  selector: 'import',
  template: `
    <div layout="column" flex layout-padding>
      <div ng-if="vm.state=='store'" layout="column">
        <form name="importForm" layout="column" flex>
          <img class="key" src="assets/key.png">
          <h1>Save <span>Private Key</span></h1>  
          <hr />
          <p>Please choose a password for your key on this device.</p>
          <md-input-container flex md-no-float>
            <input type="text" placeholder="Email" ng-model="vm.email" name="email" readonly flex>
          </md-input-container>          
          <md-input-container md-no-float ng-class="!vm.passwordIsValid && importForm.password.$touched ? 'md-input-invalid' : ''" flex>
            <input type="password" placeholder="Password" ng-model="vm.password" name="password" required flex ng-change="vm.validate()">
            <span class="invalid" ng-show="importForm.password.$touched && !vm.passwordIsValid">Your password should have a minimum length of 8 characters, maximum length of 32 characters, contain 1 capital letter, 1 number and 1 special character.</span>
          </md-input-container>
          <md-input-container flex md-no-float>
            <input type="password" placeholder="Password Confirm" ng-model="vm.passwordConfirm" name="password-confirm" required flex>
          </md-input-container>        
          <div layout="row" flex>
            <submit-and-back flex
              disable="!importForm.$valid || vm.password!=vm.passwordConfirm || !vm.passwordIsValid"
              submit="vm.save($event)"
              label="'Save on Device'"></submit-and-back>
          </div>         
        </form>
      </div>    
      <div ng-if="vm.state=='import'" layout="column">
        <form name="importForm" layout="column" flex>          
          <img class="key" src="assets/key.png">
          <h1>Import <span>Private Key</span></h1>   
          <hr />   
          <p>Please enter your 12 word private key exactly as you stored it during signup.</p>
          <md-input-container flex md-no-float ng-if="!vm.showPrivateKey">
            <input type="password" placeholder="Private Key" ng-model="vm.privateKey" name="privatekey" required flex ng-change="vm.privateKeyChangedAsync()"></input>
          </md-input-container>
          <md-input-container flex md-no-float ng-if="vm.showPrivateKey">
            <textarea ng-model="vm.privateKey" placeholder="Private Key" name="privatekey" rows="4" required flex ng-change="vm.privateKeyChangedAsync()"></textarea>
          </md-input-container>
          <div layout="row" ng-if="vm.invalidKey">
            <span class="invalid">Invalid Private Key</span>
          </div>              
          <md-input-container flex md-no-float>
            <md-checkbox ng-model="vm.showPrivateKey">Show Private Key</md-checkbox>
          </md-input-container>        
          <div layout="row" flex>
            <submit-and-back flex
              disable="!vm.privateKey"
              submit="vm.state='store'"
              label="'Continue'">
            </submit-and-back>
          </div>  
        </form
      </div>
    </div>
  `
})
@Inject('$scope','$location','server','keys','headerTitleService','$translate')
export class ImportComponent {
  state='import'
  email:string = '';
  password: string = '';
  passwordConfirm: string = '';
  privateKey: string = '';
  showPrivateKey = false
  passwordIsValid= false
  invalidKey=false
  privateKeyChangedAsync;
  client: IPaytahClient = null;

  constructor(private $scope: angular.IScope,
              private $location: angular.ILocationService,
              private server: ServerService,
              private keys: KeysService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService) { 
                this.privateKeyChangedAsync = _.debounce(this.privateKeyChanged.bind(this), 500);
                this.headerTitleService.setTitle({
                  title: this.$translate.instant('translate.import.label')
                });
              }

  privateKeyChanged() {
    let account = instance.crypto.getAccountId(this.privateKey)
    this.keys.setOneTimeKey(this.privateKey);
    this.client = null;
    this.server.clientFindById(account).then(
      client => {
        this.$scope.$evalAsync(()=>{
          if (client) {
            this.email = client.supportEmail;
            this.invalidKey = false;
            this.client = client;
          }
          else {
            this.invalidKey = true;
          }
        })
      },
      () => {
        this.$scope.$evalAsync(()=>{
          this.invalidKey = true;
        })        
      }
    )    
  }                
  
  save() {
    let email = this.email
    let password = this.password
    let secretPhrase = this.privateKey
    keystore.storeKey(email, password, secretPhrase);
    this.$location.path("login");
  }

  validate() {
    this.passwordIsValid = validatePassword(this.password);
  }
}