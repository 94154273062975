import { storage } from './storage'
import { instance } from './sdk'

export class KeyStoreService {

  constructor() {
    this.storeKey("de@nn.is","password","BUNNIES")
    var ret = this.loadKey("de@nn.is","password")
    if (ret != "BUNNIES") {
      throw new Error("keystore store and load out of sync")
    }
  }

  private normalizeEmail(email:string) {
    return email.toLocaleLowerCase().trim()
  }

  /**
   * Loads the key from storage and decrypts it
   * 
   * @param email 
   * @param password 
   * @returns string | secret phrase
   */
  public loadKey(email: string, password: string): string {
    if (email) {
      email = this.normalizeEmail(email)
      let encodedEncryptedMessage = this.storageRead(email)    
      if (encodedEncryptedMessage) {
        let encryptedMessage = instance.crypto.PassphraseEncryptedMessage.decode(encodedEncryptedMessage)
        let passwordAsHex = instance.converters.stringToHexString(password)
        let secretAsHex = instance.crypto.passphraseDecrypt(encryptedMessage, passwordAsHex)
        return instance.converters.hexStringToString(secretAsHex)
      }
    }
    return null
  }

  /**
   * Passphrase encrypts the secretphrase
   * 
   * @param email 
   * @param password 
   * @param secretPhrase 
   */
  public storeKey(email: string, password: string, secretPhrase: string) {
    if (email) {
      email = this.normalizeEmail(email)
      let passwordAsHex = instance.converters.stringToHexString(password)
      let secretAsHex = instance.converters.stringToHexString(secretPhrase)
      let encryptedMessage = instance.crypto.passphraseEncrypt(secretAsHex, passwordAsHex)
      let encoded = encryptedMessage.encode()
      this.storageWrite(email, encoded)
    }
  }

  /**
   * Returns the encrypted secret phrase if one is stored for this email address
   * To obfuscate the user info we store the email as HEX string
   * 
   * @param email 
   * @returns string - secret phrase
   */
  private storageRead(email:string) {
    email = this.normalizeEmail(email)
    let emailAsHex = instance.converters.stringToHexString(email)
    let encryptedMessageHex = storage.get(emailAsHex)
    if (encryptedMessageHex)
      return instance.converters.hexStringToString(encryptedMessageHex)
    return null
  }

  /**
   * Stores the encrypted secret phrase
   * @param email 
   * @param encryptedSecretPhrase 
   */
  private storageWrite(email: string, encryptedMessage: string) {
    email = this.normalizeEmail(email)
    let emailAsHex = instance.converters.stringToHexString(email)
    let encryptedMessageHex = instance.converters.stringToHexString(encryptedMessage)
    storage.set(emailAsHex, encryptedMessageHex)
  }
}

export var keystore = new KeyStoreService()