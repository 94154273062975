import { Service, Inject } from '../core/decorators';
import { SubscriberTopic } from './subscriber-topic';
import { WSURL } from './settings';
import { IHeatBlock, IHeatTransaction } from './heat';
declare var angular: angular.IAngularStatic;

/**
 * Always use the websocket subscriber as follows:
 * 
 * When subscribing to a topic on the server always make sure you also un-subscribe,
 * this especially goes for (route) components. Note that every time you visit a route a 
 * new component is created and the component constructor is called. So if you subscribe
 * in a component constructor you MUST unsubscribe when the component is destroyed.
 * 
 * Component destruction can be listened to through $scope.$on('$destroy', ()=>{}). 
 * 
 * Best practice is to always pass the component $scope to the subscriber method, this
 * way the subscriber will automatically un-subscribe when the scope is destroyed.
 */

@Service('subscriber')
@Inject('$q','$timeout')
export class SubscriberService {

  private RETRY_SYNC_DELAY = 2.5 * 1000; // 2.5 seconds in milliseconds

  // websocket subscription topics - these match the topics in the java com.heatledger.websocket package
  private BLOCK_PUSHED = "1";
  private BLOCK_POPPED = "2";
  private BALANCE_CHANGED = "3";
  private ORDER = "4";
  private TRADE = "5";
  private MESSAGE = "6";
  private UNCONFIRMED_TRANSACTION = "7";
  private MICROSERVICE = "8";

  private connectedSocketPromise: angular.IPromise<WebSocket> = null;
  private subscribeTopics: Array<SubscriberTopic> = [];
  private unsubscribeTopics: Array<SubscriberTopic> = [];

  constructor(private $q: angular.IQService,
              private $timeout: angular.ITimeoutService) {
  }

  /* Put all subscriber options here */

  public balanceChanged(filter: ISubscriberBalanceChangedFilter, callback: (val:IHeatSubscriberBalanceChangedResponse)=>void, $scope?: angular.IScope): () => void {
    return this.subscribe(new SubscriberTopic(this.BALANCE_CHANGED, filter), callback, $scope);
  }  

  public blockPushed(filter: ISubscriberBlockPushedFilter, callback: (val:IHeatBlock)=>void, $scope?: angular.IScope): () => void {
    return this.subscribe(new SubscriberTopic(this.BLOCK_PUSHED, filter), callback, $scope);
  }

  public blockPopped(filter: ISubscriberBlockPoppedFilter, callback: (val:IHeatBlock)=>void, $scope?: angular.IScope): () => void {
    return this.subscribe(new SubscriberTopic(this.BLOCK_POPPED, filter), callback, $scope);
  }

  public unconfirmedTransaction(filter: ISubscriberUnconfirmedTransactionFilter, callback: (val:IHeatTransaction)=>void, $scope?: angular.IScope): () => void {
    return this.subscribe(new SubscriberTopic(this.UNCONFIRMED_TRANSACTION, filter), callback, $scope);
  }

  // public message(filter: ISubscriberMessageFilter, callback: (val:IHeatMessage)=>void, $scope?: angular.IScope): () => void {
  //   return this.subscribe(new SubscriberTopic(this.MESSAGE, filter), callback, $scope);
  // }  

  // public order(filter: ISubscriberOrderFilter, callback: (val:IHeatOrder)=>void, $scope?: angular.IScope): () => void {
  //   return this.subscribe(new SubscriberTopic(this.ORDER, filter), callback, $scope);
  // }

  // public trade(filter: ISubscriberTradeFilter, callback: (val:IHeatTrade)=>void, $scope?: angular.IScope): () => void {
  //   return this.subscribe(new SubscriberTopic(this.TRADE, filter), callback, $scope);
  // }

  public microservice(filter: {[key: string] : string} , callback: (any)=>void, $scope?: angular.IScope): () => void {
    return this.subscribe(new SubscriberTopic(this.MICROSERVICE, filter), callback, $scope);
  }

  /* End subscriber options, start of general implementation code */

  private subscribe(newTopic: SubscriberTopic, callback: (any)=>void, $scope?: angular.IScope): () => void {
    var topic = this.findExistingOrAddNewTopic(newTopic);
    topic.addListener(callback);
    var unsubscribe = this.createUnsubscribeFunction(topic, callback);
    if (angular.isDefined($scope)) {
      $scope.$on('$destroy', ()=>{ unsubscribe() });
    }
    this.syncTopicSubscriptions();
    return unsubscribe;
  }

  private findExistingOrAddNewTopic(topic: SubscriberTopic): SubscriberTopic {
    for (var i=0; i<this.subscribeTopics.length; i++) {
      if (this.subscribeTopics[i].equals(topic)) {
        return this.subscribeTopics[i];
      }
    }
    this.subscribeTopics.push(topic);
    return topic;
  }

  private createUnsubscribeFunction(topic: SubscriberTopic, callback: (any)=>void): () => void {
    return ()=>{
      topic.removeListener(callback);
      if (topic.isEmpty()) {
        this.unsubscribeTopic(topic);
      }
    };
  }

  private unsubscribeTopic(topic: SubscriberTopic) {
    this.subscribeTopics = this.subscribeTopics.filter(t => t !== topic);
    this.unsubscribeTopics.push(topic);
    this.syncTopicSubscriptions();
  }

  private syncTopicSubscriptions() {
    this.getConnectedSocket().then(
      (websocket)=>{
        this.unsubscribeTopics.forEach(topic => {
          if (topic.isSubscribed()) {
            this.sendUnsubscribe(websocket, topic);
          }
        });
        this.unsubscribeTopics = this.unsubscribeTopics.filter(topic => !topic.isSubscribed());
        this.subscribeTopics.forEach(topic => {
          if (!topic.isSubscribed()) {
            this.sendSubscribe(websocket, topic);
          }
        });
        // if there is a topic which is not subscribed we need to sync again
        if (this.subscribeTopics.find(topic => !topic.isSubscribed())) {
          this.$timeout(this.RETRY_SYNC_DELAY).then(() => {
            this.syncTopicSubscriptions();
          });
        }
      },
      ()=>{
        // on failure call syncTopicSubscriptions again after 5 seconds.
        this.$timeout(this.RETRY_SYNC_DELAY).then(() => {
          this.syncTopicSubscriptions();
        });
      }
    )
  }

  private getConnectedSocket(): angular.IPromise<WebSocket> {
    if (this.connectedSocketPromise) {
      return this.connectedSocketPromise;
    }
    var deferred  = this.$q.defer();
    var websocket = new WebSocket(WSURL);
    this.hookupWebsocketEventListeners(websocket, deferred);
    return (this.connectedSocketPromise = <angular.IPromise<WebSocket>> deferred.promise);
  }

  private hookupWebsocketEventListeners(websocket: WebSocket, deferred: angular.IDeferred<{}>) {
    var onclose = (event) => {
      deferred.reject();
      this.connectedSocketPromise = null;
      websocket.onclose = null;
      websocket.onopen = null;
      websocket.onerror = null;
      websocket.onmessage = null;
      this.subscribeTopics.forEach(topic => { topic.setSubscribed(false) })
      this.syncTopicSubscriptions();
    };
    var onerror = onclose;
    var onopen = (event) => {
      deferred.resolve(websocket);
    };
    var onmessage = (event) => {
      try {
        this.onMessageReceived(JSON.parse(event.data));
      } catch (e) {
        console.log("Websocket parse error", e);
      }
    };
    websocket.onclose = onclose;
    websocket.onopen = onopen;
    websocket.onerror = onerror;
    websocket.onmessage = onmessage;
  }

  private sendUnsubscribe(websocket: WebSocket, topic: SubscriberTopic) {
    if (websocket.readyState == 1) {
      websocket.send(JSON.stringify(["unsubscribe",[[topic.topicId,topic.params]]]));
      topic.setSubscribed(false);
    }
  }

  private sendSubscribe(websocket: WebSocket, topic: SubscriberTopic) {
    if (websocket.readyState == 1) {
      websocket.send(JSON.stringify(["subscribe",[[topic.topicId,topic.params]]]));
      topic.setSubscribed(true);
    }
  }

  private onMessageReceived(messageJson: Object) {
    if (!angular.isArray(messageJson) || messageJson.length != 3) {
      console.log("Websocket invalid message", messageJson);
      return;
    }
    var topicAsStr = messageJson[0], details = messageJson[1], contents = messageJson[2];
    if (!angular.isString(topicAsStr)||!angular.isObject(details)) {
      console.log("Websocket invalid field", messageJson);
      return;
    }

    this.subscribeTopics.forEach(topic => {
      if (topic.topicId == topicAsStr && this.topicMatchesDetails(topic, details)) {
        this.invokeListeners(topic, contents);
      }
    });
  }

  private topicMatchesDetails(topic: SubscriberTopic, details: Object) {
    var filterKeys = Object.getOwnPropertyNames(topic.params);
    for (var i=0, key = filterKeys[i]; i<filterKeys.length; i++) {
      if (topic.params[key] != details[key]) return false;
    }
    return true;
  }

  private invokeListeners(topic: SubscriberTopic, contents: Object) {
    topic.listeners.forEach(listener=>{
      try {
        listener(contents);
      } catch (e) {
        console.error(e);
      }
    });
  }
}

interface ISubscriberBlockPushedFilter {
  generator?: string;
}

interface ISubscriberBlockPoppedFilter {
  generator?: string;
}

interface ISubscriberBalanceChangedFilter {
  account?: string;
  currency?: string;
}

export interface IHeatSubscriberBalanceChangedResponse {
  account: string;
  currency: string;
  quantity: string;
}

// interface ISubscriberOrderFilter {
//   account?: string;
//   currency?: string;
//   asset?: string;
//   unconfirmed?: string; // true or false
//   type?: string; // ask or bid
// }

// interface ISubscriberTradeFilter {
//   seller?: string;
//   buyer?: string;
//   currency?: string;
//   asset?: string;
// }

// interface ISubscriberMessageFilter {
//   sender?: string;
//   recipient?: string;
// }

interface ISubscriberUnconfirmedTransactionFilter {
  sender?: string;
  recipient?: string;
}
