import { Component } from '../../core/decorators';

@Component({
  selector: 'disclaimer',
  template: `
    <div ng-if="!vm.isTestnet">
      Blockchain Exchange Ltd | Company No. C86671 | 5th Floor, Valletta Buildings, South Street, Valletta, VLT1103, Malta | <a target="_blank" href="http://paytah.com/privacy-policy/">Privacy Policy</a> | <a target="_blank" href="http://paytah.com/terms-of-services/">Terms & Conditions</a>
    </div>
    <div ng-if="vm.isTestnet">
      <b>THIS IS TESTNET, REMOVE ?testnet FROM ADDRESS FOR MAIN NET</b>
    </div>
  `
})

export class ToolbarComponent {
  isTestnet: boolean
  constructor() {
    this.isTestnet = window['isTestnet']
  }
}