import { Service, Inject } from "../core/decorators";
import { ServerEngineError } from "./http";

@Service('errors') 
@Inject('$mdToast')
export class ErrorsService {

  /* Method that is bound to 'this' can be passed as method to lambdas */
  public error: (message: any, details?: any)=> void

  constructor(private $mdToast: angular.material.IToastService) {
    this.error = this.errorImpl.bind(this)
  }

  private errorImpl(message: any, details?: any) {
    if (details)
      console.error(message, details);
    else
      console.error(message)
    this.$mdToast.show(this.$mdToast.simple().textContent(this.formatMessage(message, details)).hideDelay(5000));
  }

  private formatMessage(message: any, details?: any) {
    if (message instanceof ServerEngineError)
      return message.description
    if (!(typeof message == 'string'))
      message = JSON.stringify(message)
    return message + '[' + details + ']'
  }
}