import { Service, Inject } from '../core/decorators';
import { ServerService, IServerDepositMethod, IServerCurrencyDepositMethod } from './server';
import { AssetService } from './asset';
import { currencies } from './settings';
import { UserService } from './user-service';
import { groupBy } from 'lodash';

@Service('depositMethod')
@Inject('$q','user','server','asset')
export class DepositMethodService {
  private readyPromise: angular.IPromise<{}>;
  private depositMethods: IServerCurrencyDepositMethod = {};

  constructor(private $q: angular.IQService,
              private user: UserService,
              private server: ServerService,
              private asset: AssetService) {
                asset
                  .ready()
                  .then(assets => {
                    const fiat = [];
                    const crypto = [];

                    assets.forEach(asset => {                      
                      const currencyFound = currencies.find(currency => currency.currency.toLowerCase() === asset.symbol.toLowerCase());

                      if(currencyFound) {
                        if(currencyFound.crypto) {
                          crypto.push(asset.asset);
                        }
                        else {
                          fiat.push(asset.asset);
                        }
                      }
                    });

                    if(crypto.length > 0)
                      this.getDepositMethodList(true, crypto);
                    
                    if(fiat.length > 0)
                      this.getDepositMethodList(false);
                  });
  }

  public ready(): angular.IPromise<{}> {
    return this.readyPromise;
  }

  public getDepositMethodList(crypto: boolean, currencies?: string[]) { 
    let deferred = this.$q.defer();
    this.readyPromise = deferred.promise;
    let method: angular.IPromise<IServerDepositMethod[]>;

    if(crypto) {
      method = this.server.cryptoDepositMethodList(currencies);
    }   
    else {
      method = this.server.depositMethodList();
    }

    method
      .then(depositMethods => {
        this.depositMethods = {
          ...this.depositMethods,
          ...groupBy(depositMethods, 'currency')
        };
        deferred.resolve(this.depositMethods);
      }, deferred.reject);

    return method;
  }

  public getDepositMethodsByCurrency(currency: string) {
    return this.depositMethods[currency] || [];
  }

  public getDepositMethodByCurrencyById(currency: string, id: string) {
    return this.getDepositMethodsByCurrency(currency).find(method => method.id === id);
  }
}