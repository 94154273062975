import './main/main';
import './toolbar/toolbar';
import './submitandback/submitAndBack';
import './login/login';
import './greet/greet';
import './signup/signup';
import './signup/signup-complete';
import './deposit/deposit';
import './payout/payout';
import './history/history';
import './messages/messages';
import './settings/settings';
import './upload/upload';
import './recipient-auto-complete';
import './payment/payment';
import './import/import';
import './disclaimer/disclaimer';
import './exchange/exchange';