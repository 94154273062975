import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService } from '../../services/server';
import { HeaderTitleService } from '../../services/headerTitle';
import { 
  startCamera, 
  getImageFromCamera, 
  stopCamera 
} from '../../lib/video';
import { IPromise } from 'angular';
import { ClientType, SourceOfFunds, SourceOfWealth, Occupations } from '../../lib/protocol';

@RouteConfig('/upload/')
@Component({
  selector: 'upload',
  template: `
    <div layout="column" flex>
      <div layout="column" class="company" ng-if="vm.userIsCompany">
        <div class="info">
          Thank you for your business with us. In order to verify your account and increase receive and spend limit please contact our compliance via email compliance@paytah.com or using the internal messaging system.
        </div>
      </div>
      <div layout="column" class="individual" ng-if="!vm.userIsCompany">
        <div class="info">
          <p>To verify your account and be able to send and receive more than €1,000, please choose one of the followig options:</p>
          <ul>
            <li>
              <p>Send a €1 deposit from a bank account in your name to the following account by clicking <a href="#/deposit">here</a>. Please include the following '<b>{{ vm.user.getAccount() }}</b>' in the reference/message to beneficiary field</p>
            </li>
            <li>
              <p>Upload an identity document (Passport, Drivers License, National ID card)</p>
            </li>
            <li>
              <p>Upload a recent proof of address (bank statement, credit card statement, utility bill)</p>
            </li>
          </ul>
          <hr />
          <p>Alternatively</p>
          <ul>
            <li>
              <p>Upload an identity document (Passport, Drivers License, National ID card)</p>
            </li>
            <li>
              <p>Upload a second identity document (Passport, Drivers License, National ID card)</p>
            </li>
            <li>
              <p>Upload a recent proof of address (bank statement, credit card statement, utility bill)</p>
            </li>
          </ul>
        </div>
        <form name="uploadForm">
          <div class="row">
            <div id="front-photo" class="document">
              <div class="actions" ng-class="{true: 'added'}[vm.frontDocumentAdded]">
                <div class="description">
                  Front Side of Document
                </div>
                <div class="file-wrapper">
                  <label for="frontFile">Upload File</label>  
                  <input type="file" id="frontFile" accept="image/*" custom-on-change="vm.updateFileInformation('front')">
                </div>
                <div class="photo-button" ng-click="vm.enableCamera('front')">
                  Take Photo
                </div>
              </div>
              <img ng-src="{{vm.frontDocument}}" ng-show="vm.frontDocumentAdded" id="front" />
            </div>
            <div id="back-photo" class="document">
              <div class="actions" ng-class="{true: 'added'}[vm.backDocumentAdded]">
                <div class="description">
                  Back Side of Document
                </div>
                <div class="file-wrapper">
                  <label for="backFile">Upload File</label>  
                  <input type="file" id="backFile" accept="image/*" custom-on-change="vm.updateFileInformation('back')">
                </div>
                <div class="photo-button" ng-click="vm.enableCamera('back')">
                  Take Photo
                </div>
              </div>
              <img ng-src="{{vm.backDocument}}" ng-show="vm.backDocumentAdded" id="back" />
            </div>
          </div>
          <div id="address-photo" class="document">
            <div class="actions" ng-class="{true: 'added'}[vm.addressDocumentAdded]">
              <div class="description">
                Proof of Address Document
              </div>
              <div class="file-wrapper">
                <label for="addressFile">Upload File</label>  
                <input type="file" id="addressFile" accept="image/*" custom-on-change="vm.updateFileInformation('address')">
              </div>
              <div class="photo-button" ng-click="vm.enableCamera('address')">
                Take Photo
              </div>
            </div>
            <img ng-src="{{vm.addressDocument}}" ng-show="vm.addressDocumentAdded" id="address" />
          </div>
          <div class="row">
            <div id="source-of-funds" class="document">
              <md-input-container flex>
                <label>Source of Funds</label>
                <md-select ng-model="vm.funds" name="funds" required flex multiple>
                  <md-option ng-repeat="item in vm.allFunds" ng-value="item">{{ item }}</md-option>
                </md-select>
              </md-input-container>
            </div>
            <div id="source-of-wealth" class="document">
              <md-input-container flex>
                <label>Source of Wealth</label>
                <md-select ng-model="vm.wealth" name="wealth" required flex multiple>
                  <md-option ng-repeat="item in vm.allWealth" ng-value="item">{{ item }}</md-option>
                </md-select>
              </md-input-container>
            </div>
          </div>
          <div id="occupation" class="document">
            <md-input-container flex>
              <label>Occupation</label>
              <md-select ng-model="vm.occupation" name="occupation" required flex>
                <md-option ng-repeat="item in vm.allOccupations" ng-value="item">{{ item }}</md-option>
              </md-select>
            </md-input-container>
          </div>
          <div class="video-wrapper" ng-show="vm.showVideo">
            <video autoplay="true" id="videoElement" ng-click="vm.capturePhoto()"></video>
          </div>
          <div class="loader" ng-show="vm.submittingFiles">
            <img src="assets/ripple.gif">
          </div>
          <div class="files-submitted" ng-show="vm.filesSubmitted">
            Files submitted successfully and will be verified accordingly.
          </div>
          <submit-and-back
            disable="!uploadForm.$valid || vm.submittingFiles"
            submit="vm.submit($event)"
            label="'Upload Files'"
          >
          </submit-and-back>
        </form>
      </div>
    </div>
  `
})
@Inject('$scope','user','$mdToast','headerTitleService','$translate','server')
export class UploadComponent {
  loading:false;
  proofOfAddress: string;
  idDocument: string;
  frontDocument: string;
  frontDocumentAdded: boolean;
  backDocument: string;
  backDocumentAdded: boolean;
  selfieDocument: string;
  selfieDocumentAdded: boolean;
  addressDocument: string;
  addressDocumentAdded: boolean;
  takingPhotoFor: string;
  showVideo: boolean;
  fileBlobs: IDocumentBlobs;
  filesSubmitted: boolean;
  submittingFiles: boolean;
  userIsCompany: boolean;
  allFunds: string[];
  funds: string[];
  allWealth: string[];
  wealth: string[];
  allOccupations: string[];
  occupation: string;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              private $mdToast: angular.material.IToastService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService,
              private server: ServerService) {
                if (!user.requireLogin())
                  return;

    const client = user.getClient();
    this.userIsCompany = client && client.type == ClientType.LEGAL;
    this.allFunds = SourceOfFunds;
    this.allWealth = SourceOfWealth;
    this.allOccupations = Occupations;
    this.headerTitleService.setTitle({
      title: this.$translate.instant('translate.toolbar.upload.label')
    });
    this.fileBlobs = {
      front: null,
      back: null,
      selfie: null,
      address: null
    }
  }

  enableCamera(takingPhotoFor: string) {
    this[`${takingPhotoFor}DocumentAdded`] = false;
    this.showVideo = true;
    startCamera("videoElement");
    this.takingPhotoFor = takingPhotoFor;
  }

  capturePhoto() {
    const photoFor = this.takingPhotoFor;
    this[`${photoFor}DocumentAdded`] = false;
    this[`${photoFor}Document`] = getImageFromCamera("videoElement", blob => {
      this.$scope.$evalAsync(() => {
        this.fileBlobs[photoFor] = blob;
        this[`${photoFor}DocumentAdded`] = true;
      });
    });
    stopCamera("videoElement");
    this.showVideo = false;      
  }

  updateFileInformation(takingPhotoFor: string) { 
    return () => {
      this[`${takingPhotoFor}DocumentAdded`] = false;

      const file = document.getElementById(`${takingPhotoFor}File`) as HTMLInputElement;
      const fileList = file.files;
      const currentFile = fileList[0];
      
      const reader = new FileReader();

      reader.onloadend = () => {
        this.$scope.$evalAsync(() => {
          this[`${takingPhotoFor}Document`] = reader.result;
          this[`${takingPhotoFor}DocumentAdded`] = true;
        });
      }

      if (currentFile) {
        this.fileBlobs[takingPhotoFor] = currentFile;
        reader.readAsDataURL(currentFile);
      } else {
        this.fileBlobs[takingPhotoFor] = null;
        this[`${takingPhotoFor}Document`] = "";
      }
    }
  }

  submit() {
    this.filesSubmitted = false;
    this.submittingFiles = false;

    const userAccount = this.user.getAccount();
    const promises: IPromise<any>[] = [];
    for(const key in this.fileBlobs) {
      if(this[`${key}DocumentAdded`]) {
        const formData: FormData = new FormData();
        const blob = this.fileBlobs[key];
        formData.append('account', userAccount);
        formData.append('documentType', key);
        formData.append('fileType', blob.type);
        formData.append('doc', blob);
        formData.append('sourceOfFunds', JSON.stringify(this.funds));
        formData.append('sourceOfWealth', JSON.stringify(this.wealth));
        formData.append('occupation', JSON.stringify(this.occupation));
        promises.push(this.server.upload(userAccount, formData));
      }
    }

    if(promises.length) {
      this.submittingFiles = true;
      Promise.all(promises)
        .then(() => {
          this.submittingFiles = false;
          this.filesSubmitted = true;
        });
    }
  }
}

interface IDocumentBlobs {
  front: Blob;
  back: Blob;
  selfie: Blob;
  address: Blob;
}