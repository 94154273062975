/* This step is not used currently in Monetum client app */

import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { CountriesService } from '../../services/countries';
import { ServerService, IServerSignupComplete } from '../../services/server';

@RouteConfig('/signup-complete')
@Component({
  selector: 'signupComplete',
  template: `
    <div layout="column" flex layout-padding>
      <h2>Please complete signup process</h2>
      <div ng-show="vm.errorMessage">{{vm.errorMessage}}</div>
      <form name="signupForm" layout="column" flex>
        <md-input-container flex>
          <label>Name</label>
          <input ng-model="vm.name" name="name" required flex>
        </md-input-container>
        <md-input-container flex>
          <label>Email</label>
          <input ng-value="vm.user.getEmail()" readonly flex>
        </md-input-container>        
        <md-input-container flex>
          <label>Address</label>
          <input ng-model="vm.address" name="address" required flex>
        </md-input-container>        
        <md-input-container flex>
          <label>Postal Code</label>
          <input ng-model="vm.postalCode" name="postalCode" required flex>
        </md-input-container>
        <md-input-container flex>
          <label>City</label>
          <input ng-model="vm.city" name="city" required flex>
        </md-input-container>
        <md-input-container flex>
          <label>Country</label>
          <md-select ng-model="vm.country" name="country" required flex>
            <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
          </md-select>
        </md-input-container>
        <md-input-container flex>
          <label>Phone</label>
          <input ng-model="vm.phone" name="phone" required flex>
        </md-input-container>
        <md-input-container flex>
          <label>Social ID</label>
          <input ng-model="vm.socialId" name="socialId" required flex>
        </md-input-container>
        <md-input-container flex>
          <label>Iban</label>
          <input ng-model="vm.iban" name="iban" required flex>
        </md-input-container>              

        <md-button class="md-primary" ng-click="vm.submit($event)"
          ng-disabled="!signupForm.$valid">Signup</md-button>
      </div>
    </div>
  `
})
@Inject('$scope','user','countries','server','$location')
export class SignupCompleteComponent {

  name: string = "";
  address: string = "";
  postalCode: string = "";
  city: string = "";
  country: string = "";
  phone: string = "";
  socialId: string = "";
  iban: string = "";  

  errorMessage: string;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              public countries: CountriesService,
              private server: ServerService,
              private $location: angular.ILocationService) {
                if (!user.requireLogin())
                  return;

  }

  // submit($event) {
  //   this.errorMessage = null;
  //   let data: IServerSignupComplete = {
  //     email: this.user.getEmail(),
  //     password: this.user.getPassword(),
  //     name: this.name,
  //     address: this.address,
  //     postalCode: this.postalCode,
  //     city: this.city,
  //     country: this.country,
  //     phone: this.phone,
  //     socialId: this.socialId,
  //     iban: this.iban
  //   };
  //   this.server.signupComplete(data).then(
  //     ()=>{
  //       this.user.refresh().then(
  //         ()=>{
  //           this.$location.path('greet');
  //         }
  //       );
  //     },
  //     (response)=>{
  //       this.errorMessage = JSON.stringify(this.errorMessage);
  //     }
  //   );
  // }
}