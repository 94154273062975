import { Service, Inject } from '../core/decorators';
import { ServerService, IPaytahClient } from './server';
import { SubscriberService } from './subscriber';
import { EUR } from './settings';
import * as utils from '../lib/utils';
declare var angular: angular.IAngularStatic;

@Service('user')
@Inject('$q','$location','server','$rootScope','subscriber')
export class UserService {
  private email: string;
  private password: string;
  private account: string;
  private unlocked = false;
  private client: IPaytahClient;
  private balanceEURFormatted: string = "*.** EUR";
  private balance: string;
  private unsubscribe: ()=>void;

  constructor(private $q: angular.IQService,
              private $location: angular.ILocationService,
              private server: ServerService,
              private $rootScope: angular.IRootScopeService,
              private subscriber: SubscriberService) {}

  public unlock(email: string, password: string, account:string) {
    this.email = email;
    this.password = password;
    this.account = account;
    this.unlocked = true;
    this.subscribeBalanceChanged();
    this.refresh()
  }

  public isUnlocked() {
    return this.unlocked;
  }

  public getEmail() {
    return this.email;
  }

  public getPassword() {
    return this.password;
  }

  public getCurrencyBalance(currency: string, withCurrency: boolean = false) {
    const balance = this.client[`${currency.toLowerCase()}Balance`] || 0;

    return utils.formatMoney(balance, 2) + (withCurrency ? (' ' + currency) : '');
  }

  public getBalanceEURFormatted() {
    return this.balanceEURFormatted;
  }

  public getPasswordPromise(): Promise<string> {
    return new Promise(resolve => {
      resolve(this.getPassword())
    })
  }

  public getClient(): IPaytahClient {
    return this.client;
  }

  public getAccount() {
    return this.account;
  }

  public hasIBAN() {
    const paymentAccounts = this.client.paymentAccounts;
    
    return paymentAccounts.length > 0;
  }

  public getIBAN() {    
    const paymentAccounts = this.client.paymentAccounts;
    
    if(this.hasIBAN())
      return paymentAccounts[0].iban;

    return null;
  }

  public getBalance(formatted?: boolean) {
    if(formatted) {
      return this.balance;
    }

    return this.balance.replace(",", "");
  }

  public refresh(): angular.IPromise<{}> {
    let deferred = this.$q.defer();
    this.server.clientFindById(this.account).then(
      (client)=>{
        this.client = client;
        this.balance = utils.formatMoney(client.balance, 2);
        this.balanceEURFormatted = this.balanceEURFormatted = `${this.balance} EUR`;
        deferred.resolve(client);
      },
      (response) => {
        console.log('find user response', response);
        deferred.resolve(response);
      }
    );    
    return deferred.promise;
  }

  private subscribeBalanceChanged() {
    if (angular.isDefined(this.unsubscribe)) {
      this.unsubscribe();
    }
    this.unsubscribe = this.subscriber.balanceChanged({currency: EUR.id, account:this.account}, ()=>{
      this.refresh().then(()=>{
        this.$rootScope.$evalAsync(()=>{});
      });
    });
  }

  public requireLogin() {
    if (!this.isUnlocked()) {
      this.$location.path('login');
      return false;
    }
    return true;
  }

  public signout() {
    this.email = null;
    this.password = null;
    this.account = null;
    this.unlocked = false;
    this.balanceEURFormatted = null;
    if (angular.isDefined(this.unsubscribe)) {
      this.unsubscribe();
    }    
    this.$location.path('login');
  }
}