import { Component, Inject} from '../core/decorators';
import { UserService } from '../services/user-service';
import { ServerService } from '../services/server';
declare var angular: angular.IAngularStatic;

@Component({
  selector: 'recipientAutoComplete',
  inputs: ['required','disabled','placeholder','searchText'],
  template: `
    <md-autocomplete
      ng-disabled="vm.disabled"
      md-no-cache="true"
      md-selected-item="vm.selectedItem"
      md-search-text-change="vm.searchTextChange()"
      md-search-text="vm.searchText"
      md-selected-item-change="vm.selectedItemChange(item)"
      md-items="item in vm.querySearch()"
      md-item-text="item.email"
      md-min-length="0"
      md-autocomplete-snap="width"
      placeholder="{{vm.placeholder}}"
      required="{{vm.required}}">
      <md-item-template>
        <span md-highlight-text="vm.searchText" md-highlight-flags="^i">{{item.email}}</span>
      </md-item-template>
    </md-autocomplete>
  `
})
@Inject('$scope','user','server','$q')
export class RecipientAutoCompleteComponent {
  disabled:boolean;
  placeholder:string;
  required:boolean;
  selectedItem:any;
  searchText:string;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              private server: ServerService,
              private $q: angular.IQService) {
  }

  searchTextChange() {

  }

  selectedItemChange(item:any) {

  }

  querySearch() {
    let deferrred = this.$q.defer();
    this.server.contacts(this.user.getAccount(), this.searchText||"*", 0, 100).then(
      contacts=>{
        deferrred.resolve(contacts);
      },
      ()=>{
        deferrred.resolve([]);
      }
    );
    return deferrred.promise;
  }
}