import * as moment from '../../../node_modules/moment/moment';
import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService, IServerMessage, IServerMessageHeader } from '../../services/server';
import { SubscriberService } from '../../services/subscriber';
import { HeaderTitleService } from '../../services/headerTitle';
import * as utils from '../../lib/utils';
import { ErrorsService } from '../../services/errors';
import { KeysService } from '../../services/key-service';
import { ThreadStart, ThreadReply, UpdateRead, UpdateStarred, UpdateTrashed } from '../../lib/protocol';
import { ServerEngineError } from '../../services/http';
import * as Long from "long"
import { IMessage, MessageType, messageFunctionalityDisabled } from '../../services/settings';

@RouteConfig('/messages/:show', '/messages/:show/:id')
@Component({
  selector: 'messages',
  inputs: ['show', 'id'],
  template: `
    <div layout="column" flex class="messages-wrapper" ng-class="vm.selectedTab.name">
      <div layout="row" class="tabs">
        <div class="tab" ng-repeat="tab in vm.tabs" ng-if="!tab.disabled && !tab.notAsTab" ng-click="vm.selectTab(tab)" ng-class="{true: 'selected'}[tab.selected]">
          {{tab.name}}
        </div>
      </div>
      <div layout="column" flex ng-if="vm.show=='new'" class="messages-container">
        <div layout="column" class="message-new container">
          <form name="sendMessageForm" layout="column" flex>
            <md-input-container flex>
              <label>Recipient email</label>
              <recipient-auto-complete required="true" placeholder="Recipient" search-text="vm.recipient"></recipient-auto-complete>
            </md-input-container>
            <md-input-container flex>
              <label>Topic</label>
              <input ng-model="vm.topic" name="topic" required flex>
            </md-input-container>
            <textarea rows="5" required name="message" ng-model="vm.message"></textarea>
            <div>{{vm.status}}</div>
            <submit-and-back
              disable="!sendMessageForm.$valid"
              submit="vm.sendMessage($event)"
              label="'Send'"
            >
            </submit-and-back>
          </form>
        </div>
      </div>
      <div layout="column" flex ng-if="!vm.id && vm.show!='new'" class="messages-container">
        <div layout="row" class="compose-search">
          <md-button
            class="md-primary compose"
            href="messages/new"
          >
            Compose
          </md-button>
          <div class="search">
            <div class="search-image">
              <img src="assets/search.png" alt="Search" />
            </div>
            <div class="search-input">
              <input type="text" ng-model="vm.search" ng-change="vm.onSearch()" placeholder="Search Here..." />
            </div>
          </div>
        </div>
        <div class="messages" ng-if="vm.hasMessages">
          <table>
            <tr>
              <th>
                
              </th>
              <th>
                Name
              </th>
              <th>
                Starred
              </th>
              <th>
                Message
              </th>
              <th>
                
              </th>
              <th>
                Date
              </th>
            </tr>
            <tr class="message" ng-repeat="item in vm.messageHeaders" ng-click="vm.showMessage(item)" data-id="{{item.id}}">
              <td>
                <div class="check-wrapper">
                  <div class="check" ng-class="{true: 'checked'}[item.checked]" ng-click="vm.toggleCheck($event, item)">
                  </div>
                </div>
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <div class="star-wrapper">
                  <div class="star" ng-class="{true: 'starred'}[item.starred]" ng-click="vm.toggleStar($event, item)">
                  </div>
                </div>
              </td>
              <td class="message-topic-preview">
                {{ item.topic }} - {{ item.preview }}
              </td>
              <td>
                <div class="actions-wrapper">
                  <div class="status" ng-class="{true: 'unread'}[item.read]" ng-click="vm.toggleMessageRead($event, item)">
                  </div>
                  <div class="available" ng-class="{true: 'undelete', false: 'delete'}[item.trashed]" ng-click="vm.toggleMessageDelete($event, item)">
                  </div>
                </div>
              </td>
              <td>
                {{ item.date }}
              </td>
            </tr>
          </table>
          <div class="show-more-wrapper" ng-if="vm.showViewMoreMessageHeaders">
            <div class="show-more" ng-click="vm.viewMoreMessageHeaders()">
              View More
            </div>
          </div>
        </div>
        <div ng-if="!vm.hasMessages" class="no-messages">
          There are no conversations
        </div>
      </div>
      <div layout="column" flex ng-if="vm.id&&vm.show!='new'" class="existing messages-container">
        <div layout="column" class="container">
          <div class="header">
            <div class="left-col">
              <div class="title">
                {{vm.messages[0].topic}}
              </div>
            </div>
          </div>
          <div layout="column" ng-repeat="item in vm.messages" class="message-content">
            <div layout="row" class="message-header">
              <span class="date">{{item.date}}</span>
              <span ng-if="item.incoming">From&nbsp;:&nbsp;&nbsp;</span>
              <span ng-if="!item.incoming">To&nbsp;:&nbsp;&nbsp;</span>
              <span class="email">{{item.email}}</span>
            </div>
            <span class="content">{{item.content}}</span>
          </div>
          <div class="show-more-wrapper" ng-if="vm.showViewMoreMessages">
            <div class="show-more" ng-click="vm.viewMoreMessages()">
              View More
            </div>
          </div>
        </div>
        <div layout="column" class="message-reply container">
          <form name="messageForm" layout="column" flex>
            <textarea required name="content" ng-model="vm.content"></textarea>
            <div layout="row">
              <submit-and-back
                disable="!messageForm.$valid"
                submit="vm.sendReply()"
                label="'Send'"
              >
              </submit-and-back>
            </div>
          </form>
        </div>
      </div>
    </div>
  `
})
@Inject('$scope','user','server','$location','$q','subscriber','headerTitleService','$translate','errors','keys')
export class MessagesComponent {
  id:string;
  show:string;
  hasMessages: boolean;
  messageHeaders = [];
  messages = [];
  content: string;
  recipient: string;
  topic: string;
  message: string;
  status: string;
  selectedTab: IMessage;
  tabs: IMessage[];

  currentPage: number = 1;
  pageSize: number = 100;
  amount: number = 0;
  showViewMoreMessageHeaders: boolean;
  showViewMoreMessages: boolean;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              private server: ServerService,
              private $location: angular.ILocationService,
              private $q: angular.IQService,
              private subscriber: SubscriberService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService,
              private errors: ErrorsService,
              private keys: KeysService) {
                if (!user.requireLogin())
                  return;

                let reload;
                if (!this.id) {
                  reload = utils.debounce(()=>{ this.getMessageHeaders() }, 1000, false);
                  this.getMessageHeaders();
                }
                else {
                  reload = utils.debounce(()=>{ this.getMessages() }, 1000, false);
                  this.getMessages();
                }

                this.subscriber.unconfirmedTransaction({sender:user.getAccount()},reload,$scope);
                this.subscriber.unconfirmedTransaction({recipient:user.getAccount()},reload,$scope);

                this.tabs = Object.keys(MessageType)
                  .filter(tab => typeof MessageType[tab as any] === "number")
                  .map(tab => {
                    const type: MessageType = MessageType[tab.toUpperCase()];
                    const tabInformation: IMessage = {
                      name: tab.toLowerCase(),
                      type,
                      disabled: messageFunctionalityDisabled.indexOf(type) != -1,
                      selected: this.show === tab.toLowerCase(),
                      route: '',
                      description: ''
                    };

                    switch(tabInformation.name) {
                      case "inbox":
                        tabInformation.description = "Inbox";
                        tabInformation.route = `messages/inbox`;
                      break;
                      case "sent":
                        tabInformation.description = "Sent";
                        tabInformation.route = `messages/sent`;
                      break;
                      case "drafts":
                        tabInformation.description = "Drafts";
                        tabInformation.route = `messages/drafts`;
                      break;
                      case "trash":
                        tabInformation.description = "Trash";
                        tabInformation.route = `messages/trash`;
                      break;
                      case "starred":
                        tabInformation.description = "Starred";
                        tabInformation.route = `messages/starred`;
                      break;
                      case "new":
                        tabInformation.description = "New";
                        tabInformation.route = `messages/new`;
                        tabInformation.notAsTab = true;
                      break;
                    }

                    return tabInformation;
                });

                this.selectTab();
  }

  unselectAllTabs() {
    this.tabs.forEach(tab => tab.selected = false);
  }

  selectTab(tab?: IMessage) {
    this.unselectAllTabs();

    if(tab) {
      this.$location.path(`messages/${tab.name}`);
      return;
    }

    const tabToSelect = this.tabs.find(tab => tab.name === this.show.toLowerCase());
    tabToSelect.selected = true;
    this.selectedTab = tabToSelect;
    this.updateHeaderTitle();
  }

  updateHeaderTitle() {
    this.headerTitleService.setTitle({
      title: this.$translate.instant(`translate.toolbar.${this.show}.label`)
    });
  }

  showMessage(message: IServerMessageHeader) {
    this.$location.path(`messages/${this.show}/${message.id}`);
  }

  toggleCheck(event, message: IServerMessageHeader) {
    event.stopPropagation();

    if(!message.checked)
      message.checked = true;
    else
      message.checked = false;

    return false;
  }

  toggleStar(event, message: IServerMessageHeader) {
    event.stopPropagation();

    message.starred = !message.starred;
    this.update(message.id, "starred", message.starred);

    return false;
  }

  toggleMessageRead(event, message: IServerMessageHeader) {
    event.stopPropagation();

    message.read = !message.read;
    this.update(message.id, "read", message.read);

    return false;
  }

  toggleMessageDelete(event, message: IServerMessageHeader) {
    event.stopPropagation();

    message.trashed = !message.trashed;
    this.update(message.id, "trashed", message.trashed);

    return false;
  }

  viewMoreMessageHeaders() {
    this.currentPage++;
    this.getMessageHeaders(true);
  }

  viewMoreMessages() {
    this.currentPage++;
    this.getMessages(true);
  }

  addMessageHeaders(messages: IServerMessageHeader[], keepPreviousMessages?: boolean): number {
    if(!keepPreviousMessages) {
      this.messageHeaders = [];
    }
    
    const regexp = /^\<(\d+)\>/;
    const messageHeaders = this.messageHeaders;

    messages.map((message:IServerMessageHeader|any) => {
      const messageFound = messageHeaders.find(msg => msg.id === message.id);

      if(!messageFound) {
        message['date'] = moment(utils.timestampToDate(message.timestamp)).fromNow();
        let match = message.preview.match(regexp);
        if (match) {
          if (match[1]==this.user.getAccount()) {
            message.preview = message.preview.replace(match[0], `<${this.user.getEmail()}>`);
          }
          else {
            message.preview = message.preview.replace(match[0], `<${message.email}>`);
          }
        }
        messageHeaders.push(message);
      }
    });
    this.hasMessages = messageHeaders.length > 0;

    return messageHeaders.length;
  }

  addMessages(messages: IServerMessage[], keepPreviousMessages?: boolean): number {
    if(!keepPreviousMessages) {
      this.messages = [];
    }

    const msgs = this.messages;
    this.recipient = null;
    
    messages.map(message => {
      const messageFound = msgs.find(msg => msg.id === message.id);

      if(!messageFound) {
        if (this.recipient == null) {
          this.recipient = (message.recipient == this.user.getAccount()) ? message.sender : message.recipient;
        }

        const msg = {
          id: message.id,
          date: moment(utils.timestampToDate(message.timestamp)).fromNow(),
          email: ((message.recipient == this.user.getAccount()) ? message.senderEmail : message.recipientEmail),
          incoming: message.recipient == this.user.getAccount(),
          topic: message.topic,
          content: message.content
        }

        msgs.push(msg);
      }
    });
    this.hasMessages = msgs.length > 0;

    return msgs.length;
  }

  getMessageHeaders(keepPreviousMessages?: boolean) {
    let receivedOnly,sentOnly,trashOnly;
    if (this.show=="inbox") {
      receivedOnly=true;
      sentOnly=false;
      trashOnly=false;
    }
    else if (this.show=="sent") {
      receivedOnly=false;
      sentOnly=true;
      trashOnly=false;
    }
    else if (this.show=="trash") {
      receivedOnly=false;
      sentOnly=false;
      trashOnly=true;
    }

    const page = this.currentPage;
    const pageSize = this.pageSize;

    const firstItem = (page - 1) * pageSize;
    const lastItem = page * pageSize - 1;

    this.server.messageHeaders2(this.user.getAccount(), receivedOnly, sentOnly, this.id || '0', trashOnly, firstItem, lastItem).then(
      messageHeaders => {
        this.$scope.$evalAsync(()=>{
          this.showViewMoreMessageHeaders = messageHeaders.length == pageSize;
          this.amount = this.addMessageHeaders(messageHeaders, keepPreviousMessages);
        })
      }
    )
  }

  getMessages(keepPreviousMessages?: boolean) {
    const thread = this.id || '0';

    const page = this.currentPage;
    const pageSize = this.pageSize;

    const firstItem = (page - 1) * pageSize;
    const lastItem = page * pageSize - 1;

    return this.server.messages(thread, firstItem, lastItem).then(
      messages => {
        this.$scope.$evalAsync(()=>{
          this.showViewMoreMessages = messages.length == pageSize;
          this.amount = this.addMessages(messages, keepPreviousMessages);
        })
      }
    )
  }

  sendMessage() {
    /* this.recipient is an email address when used here */    
    this.server.clientFindByEmail(this.recipient).then(
      client => {
        this.keys.getKey().then(
          key => {
            ThreadStart.createMessageTransaction(key, client.id, {
              topic: this.topic,
              contents: this.message
            }).then(
              txBytes => {
                this.server.broadcast(txBytes).then(response => {
                  if (response.success) {
                    this.$location.path('messages/inbox');
                  }
                  else {
                    this.errors.error('unexpected error', response)
                  }
                }).catch(e => { 
                  const error: ServerEngineError = e;
                  this.errors.error(error.description) 
                })
              }
            )
          },
          this.errors.error
        )
      }, () => {
        this.errors.error("No such user")
        this.$scope.$evalAsync(()=>{
          this.status = "no such user";
        })      
      }
    )
  }

  sendReply() {
    /* this.recipient has become an account id here all of a sudden */
    this.server.clientFindById(this.recipient).then(
      client => {
        this.keys.getKey().then(
          key => {
            ThreadReply.createMessageTransaction(key, client.id, {
              contents: this.content,
              replyTo: Long.fromString(this.id || '0', true)
            }).then(
              txBytes => {
                this.server.broadcast(txBytes).then(response => {
                  if (response.success) {
                    this.getMessages();
                    this.$scope.$evalAsync(() => {
                      this.content = "";
                    })
                  }
                  else {
                    this.errors.error('unexpected error', response)
                  }
                }).catch(e => { 
                  const error: ServerEngineError = e;
                  this.errors.error(error.description) 
                })
              },
              this.errors.error    
            )
          },
          this.errors.error
        )        
      }, () => {
        this.errors.error("No such user")
        this.$scope.$evalAsync(()=>{
          this.status = "no such user";
        })      
      }
    )
  }

  update(thread:string, property:string, value:boolean) {
    this.keys.getKey().then(
      key => {
        let promise;
        if (property == 'read') {
          promise = UpdateRead.createMessageToSelfTransaction(key, { id: Long.fromString(thread, true), value })
        }
        else if (property == 'starred') {
          promise = UpdateStarred.createMessageToSelfTransaction(key, { id: Long.fromString(thread, true), value })
        }
        else if (property == 'trashed') {
          promise = UpdateTrashed.createMessageToSelfTransaction(key, { id: Long.fromString(thread, true), value })
        }
        else {
          this.errors.error("Internal error no such update type "+property)
          return
        }
        promise.then(
          txBytes => {
            this.server.broadcast(txBytes).then(response => {
              if (response.success) {
                this.getMessageHeaders();
              }
              else {
                this.errors.error('unexpected error', response)
              }
            }).catch(e => { 
              const error: ServerEngineError = e;
              this.errors.error(error.description) 
            })     
          }
        )
      },
      this.errors.error
    )
  }
}