export var configEN = {
  toolbar: {
    title: "Blocktech",
    cashbalance: "Cash balance: {{balance}}",
    greet: {
      label: "Menu",
      tooltip: "Click to go Back Home"
    },
    deposit: {
      label: "Add Funds",
      tooltip: "Click to deposit money"
    },
    payout: {
      label: "Withdraw",
      tooltip: "Click to withdraw money"
    },
    history: {
      label: "History",
      tooltip: "Click to go to history"
    },
    send: {
      label: "Send Money",
      tooltip: "Click to go to send money"
    },
    upload: {
      label: "Verify Account"
    },
    messages: {
      label: "Messages",
      tooltip: "Click to go messages"
    },
    inbox: {
      label: "Inbox Messages",
      tooltip: "Click to go to Inbox Messages"
    },
    sent: {
      label: "Sent Messages",
      tooltip: "Click to go to Sent Messages"
    },
    drafts: {
      label: "Drafts Messages",
      tooltip: "Click to go to Draft Messages"
    },
    trash: {
      label: "Trash Messages",
      tooltip: "Click to go to Trash Messages"
    },
    starred: {
      label: "Starred Messages",
      tooltip: "Click to go to Starred Messages"
    },
    new: {
      label: "New Message",
      tooltip: "Click to create a New Message"
    },
    settings: {
      label: "Profile",
      tooltip: "Click to go to profile"
    },
    buy: {
      label: "Buy / Sell",
      tooltip: "Click to buy"
    },
    sell: {
      label: "Buy / Sell",
      tooltip: "Click to sell"
    },
    trade: {
      label: "Trade",
      tooltip: "Click to trade"
    }
  },
  login: {
    label: "Login"
  },
  register: {
    individual: {
      label: "Register Individual"
    },
    company: {
      label: "Register Company"
    }
  },
  import: {
    label: "Import Private Key"
  },
  sidenav: {
    home: "Home",
    deposit: "Add Funds",
    payout: "Withdrawal",
    history: "History",
    send: "Send Money",
    messages: "Messages",
    settings: "Profile",
    signout: "Sign out"
  },
  deposit: {
    you_have_a_pending_deposit_initiated_on: "You have a pending topup initiated on {{date}}",
    please_transfer: "Please transfer {{amount}} to IBAN account: {{destination}}",
    receiver_name: "Receiver name: Monetum.io",
    reference: "Reference /Memo: {{ref}}",
    arrival: "Upon arrival your topup will be confirmed and the EUR credited to your Monetum.io account. Thank you!",
    amount_label: "Amount EUR",
    destination_label: "Destination IBAN",
    create_btn_label: "Topup",
    beneficiary_name: "Beneficiary name: Phoenix Payments Ltd",
    beneficiary_address: "Beneficiary address: 5th Floor, Valletta Buildings, South Street, Valletta, VLT1103, Malta"
  },
  payout: {
    title: "Withdrawal",
    amount_label: "Amount EUR",
    receivername_label: "Receiver name",
    destination_label: "Destination",
    swift_label: "Swift",
    speed_label: "Speed",
    normal_label: "Normal",
    fast_label: "Fast",
    fee: "FIXED FEE: €{{fixedFee}} + UNLOAD FEE: {{unloadFee}}%",
    send_label: "Withdraw"
  },
  history: {
    title: "History",
    payout_to: "Withdrawal to {{to}}",
    transfer_fee_for: "Transfer fee for {{transaction}}",
    status: {
      pending: "Pending",
      completed: "Completed",
      cancelled: "Cancelled",
      frozen: "Frozen"
    }
  }
};
