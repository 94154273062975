import { Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { IPaytahClient } from '../../services/server';
import { HeaderTitleService } from '../../services/headerTitle';
import { formatIBAN } from '../../lib/utils';

@Component({
  selector: 'toolbar',
  template: `
    <md-toolbar resize="vm.isSmallerVersion">
      <div class="md-toolbar-tools" ng-class="{true: 'show-menu'}[vm.showMenu]">
        <div class="left-col">
          <a href="#/greet">
            <img class="logo show-gt-sm" src="assets/monetum.png" />
            <img class="logo hide-gt-sm" src="assets/logo-small.png" />
          </a>
        </div>
        <div layout="row" class="pages-actions" ng-show="!vm.showLogoOnly">          
          <div class="burger-menu hide-gt-sm" ng-click="vm.toggleMenu()">
            <div class="burger-menu-img" ng-class="{true: 'active'}[vm.showMenu]"></div>
          </div>
          <div layout="row" class="page-actions show-gt-sm" ng-click="vm.toggleMenu()">
            <div class="arrow-up hide-gt-sm"></div>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.deposit.label'|translate}}" ng-href="#/deposit">
              <img src="assets/funds.png" alt="Add Funds" />
              <span translate="translate.toolbar.deposit.label"></span>
            </md-button>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.payout.label'|translate}}" ng-href="#/payout">
              <img src="assets/withdraw.png" alt="Withdraw" />
              <span translate="translate.toolbar.payout.label"></span>
            </md-button>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.history.label'|translate}}" ng-href="#/history">
              <img src="assets/history.png" alt="Transaction History" />
              <span translate="translate.toolbar.history.label"></span>
            </md-button>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.settings.label'|translate}}" ng-href="#/settings">
              <img src="assets/profile.png" alt="Profile" />
              <span translate="translate.toolbar.settings.label"></span>
            </md-button>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.buy.label'|translate}}" ng-href="#/exchange/buy">            
              <img src="assets/buysell.png" alt="Buy and Sell" />
              <span translate="translate.toolbar.buy.label"></span>
            </md-button>
            <md-button class="md-icon-button" aria-label="{{'translate.toolbar.trade.label'|translate}}" ng-href="#/exchange/trade">
              <img src="assets/trade.png" alt="Trade" />            
              <span translate="translate.toolbar.trade.label"></span>
            </md-button>
          </div>
        </div>
        <div ng-if="vm.user.isUnlocked()" class="client">          
          <div class="client-information">
            <div class="show-gt-sm">
              <span class="client-name">{{vm.client.name}}</span>
              <span class="client-email">{{vm.user.getEmail()}}</span>
              <hr />
              <span class="client-balance" translate="translate.toolbar.cashbalance" translate-values="{balance:vm.balanceFormatted}"></span>
              <span class="client-status">{{vm.isVerified ? 'Verified' : 'Unverified'}}</span>
            </div>
            <div class="hide-gt-sm" title="Profile">
              <a href="#/settings">
                <img alt="Profile" src="assets/user.png" />
              </a>
            </div>
          </div>
          <div class="messaging" ng-class="{true: 'has-messages'}[vm.hasMessages]">
            <a href="#/messages/inbox" title="Messages">
              <img alt="Messaging" src="assets/messaging.png" />
              <span class="unread-messages" ng-show="vm.hasMessages">{{vm.unreadMessages}}</span>
            </a>
          </div>
          <div class="logout" ng-click="vm.signout()" title="Logout">
            <img alt="Logout" src="assets/logout.png" />
          </div>
        </div>
      </div>
    </md-toolbar>
  `
})
@Inject('$scope','user','headerTitleService')
export class ToolbarComponent {
  title: string;
  client: IPaytahClient;
  isVerified: boolean;
  verificationLabel: string = '';
  showLogoOnly: boolean = true;
  balanceFormatted: string;
  hasMessages: boolean = false;
  unreadMessages: number = 0;
  showMenu: boolean = false;
  tabletVersion: boolean = false;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              private headerTitleService: HeaderTitleService) {
                this.isSmallerVersion.bind(this);
                this.headerTitleService.subscribe(this.setTitle.bind(this));
                this.getUserInformation();
                this.isSmallerVersion();
  }

  getWindowWidth() {
    return this.$scope['windowWidth'];
  }

  isSmallerVersion() {
    this.$scope.$evalAsync(() => {
      this.tabletVersion = this.getWindowWidth() < 960;
    })
  }

  toggleMenu() {
    if(this.tabletVersion)
      this.showMenu = !this.showMenu;
    else
      this.showMenu = false;
  }

  getUserInformation() {
    if(this.user.isUnlocked()) {
      this.user.refresh().then(() => {
        this.$scope.$evalAsync(() => {
          this.client = this.user.getClient();
          if (this.client) {
            this.isVerified = this.client.category.id.endsWith('.verified')
            this.balanceFormatted = `<span class="balance-amount">${this.user.getBalanceEURFormatted()}</span>`;

            if(this.isVerified) {
              this.verificationLabel = `<p>Verified</p>`;

              if(this.user.hasIBAN()) {
                this.verificationLabel += `<p>${formatIBAN(this.user.getIBAN())}</p>`;
              }
              else {
                this.verificationLabel += `<p><a href='#/settings'>Request IBAN</a></p>`;                
              }
            }
            else {
              this.verificationLabel = "<p><a href='#/upload'>Verify Account</a><p>";
            }
          }
        })
      });
    }
    else
      this.client = null;
  }

  setTitle(title: any) {
    if (title.isMainMenu || !this.user.isUnlocked()) {
      this.showLogoOnly = true;
    } else {
      this.showLogoOnly = false;
    }

    this.getUserInformation();
  }

  signout() {
    this.showMenu = false;
    this.hasMessages = false;
    this.unreadMessages = 0;
    this.showLogoOnly = true;
    this.user.signout();
  }
}