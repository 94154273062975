import { Component } from '../../core/decorators';

@Component({
  selector: 'submitAndBack',
  outputs: [ 'submit' ],
  inputs: [ 'disable', 'label', 'image', 'imageHover' ],
  template: `
    <div layout="row" flex>
      <md-button
        class="md-primary back"
        ng-click="vm.back()">
        <img src="assets/back.png" />
        Back
      </md-button>
      <md-button
        class="md-primary submit"
        ng-click="vm.submit($event)"
        ng-disabled="vm.disable"
        layout="row"
      >
        <div ng-if="vm.image" class="image">
          <img ng-src="{{vm.image}}" />
        </div>
        {{ vm.label }}
      </md-button>
    </div>
  `
})
export class SubmitAndBackComponent {
  /* @input */
  xx: string;
  constructor() {}
  back() {
    window.history.back();
  }
}