import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService } from '../../services/server';
import { KeysService } from '../../services/key-service';
import { ClientState, SignupState } from '../../lib/protocol';
import { ErrorsService } from '../../services/errors';
import { HeaderTitleService } from '../../services/headerTitle';
declare var angular: angular.IAngularStatic;

@RouteConfig('/login')
@Component({
  selector: 'login',
  template: `
    <div layout="column" flex layout-padding>
      <form name="loginForm" layout="column" flex autocomplete="nope">
        <img class="lock" src="assets/lock.png">
        <h1><span>Login</span> to your account</h1>
        <hr />
        <md-input-container flex md-no-float>
          <input type="text" autofocus id="email" placeholder="Email" ng-model="vm.email" autocomplete="nope" required flex>
        </md-input-container>
        <md-input-container flex md-no-float>
          <input type="password" id="password" placeholder="Password" ng-model="vm.password" autocomplete="nope" required flex>
        </md-input-container>
        <div ng-show="vm.errorMessage" class="error-message">{{vm.errorMessage}}</div>
        <div layout="row" flex class="actions">
          <md-button ng-click="vm.submit($event)"
          ng-disabled="!loginForm.$valid">Login</md-button>
          <a class="md-button" href="#/forgot-password">Forgot Password</a>
        </div>
        <a href="#/signup/individual">Signup Individual</a>
        <a href="#/signup/company">Signup Company</a>
        <a href="#/import">Import Key</a>
      </form>
    </div>
  `
})
@Inject('$scope','user','$location','server','headerTitleService','$translate','keys','errors','$mdToast', '$mdDialog')
export class LoginComponent {
  email:string = "";
  password: string = "";
  errorMessage: string;

  constructor(private $scope: angular.IScope,
              private user: UserService,
              private $location: angular.ILocationService,
              private server: ServerService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService,
              private keys: KeysService,
              private errors: ErrorsService,
              private $mdToast: angular.material.IToastService,
              private $mdDialog: angular.material.IDialogService) {
    if (user.isUnlocked()) {
      if (this.user.getClient()) {
        this.$location.path('greet');
      }
      else {
        this.$location.path('signup-complete');
      }
    } else if (this.$location.search().isconfirm){
      this.$mdDialog.show(
        this.$mdDialog.alert()
          .clickOutsideToClose(true)
          .title('Confirmation Success')
          .textContent('Thank you for confirming your account. You may now sign in with your provided details.')
          .ok('OK')
      )
    }
    else {
      this.headerTitleService.setTitle({
        title: this.$translate.instant('translate.login.label')
      });
    }
  }

  submit($event) {
    this.errorMessage = '';
    let account = this.keys.getAccount(this.email, this.password)
    if (angular.isString(account)) {
      this.user.unlock(this.email, this.password, account)

      // Lookup the client (if any)
      this.server.clientFindById(account).then(
        (client)=>{

          // The client exists and is ACTIVE forward to greet screen
          if (client.state == ClientState.ACTIVE) {
            this.$location.path('greet');
          }

          // The client exists but is disabled still
          else if (client.state == ClientState.DISABLED) {
            this.toast("Your account disabled, what should we do here? ")
            this.$location.path('greet');
          }

          // The client exists but is deleted need to contact support
          else if (client.state == ClientState.DELETED) {
            this.toast("Your account has been deleted, please contact support@monetum.com for information")
            this.user.signout()
            this.$location.path('login');            
          }

          // This should not happen
          else {
            this.errors.error("Unhandled internal error (resp is null)")
            this.user.signout()
            this.$location.path('login');            
          }
        }, () => {
          // Lookup the signup (if any)
          this.server.signupFindById(account).then(
            signup => {

              // The signup exists but is pending
              if (signup.state = SignupState.PENDING) {
                this.toast("Please first confirm the link emailed to you")
                this.user.signout()
                this.$location.path('login');                
              }

              // The signup exists but is rejected
              else if (signup.state == SignupState.REJECTED) {
                this.toast("Your signup has been rejected")
                this.user.signout()
                this.$location.path('login');                
              }

              // The signup exists and is accepted
              else if (signup.state == SignupState.ACCEPTED) {
                this.toast("Your signup is being processed still, please try logging in again in a moment")
                this.user.signout()
                this.$location.path('login');                
              }
            },
            this.errors.error
          )
        }
      );
    }
    else {
      this.$scope.$evalAsync(()=>{
        this.errorMessage = 'Invalid email or password';
      })
    }
  }

  toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000));
  }
}