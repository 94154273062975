import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { DepositMethodService } from '../../services/deposit-method';
import { ServerService, IServerDeposit, IServerDepositMethod, IPaytahPaymentAccount, IAsset } from '../../services/server';
import { HeaderTitleService } from '../../services/headerTitle';
import * as utils from '../../lib/utils';
import { KeysService } from '../../services/key-service';
import { ErrorsService } from '../../services/errors';
import { DepositCreate, DepositUpdateStatus, DepositStatus } from '../../lib/protocol';
import * as Long from "long"
import { ServerEngineError } from '../../services/http';
import { currencies, ICurrency } from '../../services/settings';
import { AssetService } from '../../services/asset';

@RouteConfig('/deposit', '/deposit/:currency', '/deposit/:currency/:method')
@Component({
  selector: 'deposit',
  inputs: ['currency', 'method'],
  template: `
    <div layout="column" flex>
      <div class="wrapper">
        <div layout="row" class="currencies">
          <div class="currency" ng-repeat="currency in vm.currencies" ng-click="vm.selectCurrency(currency)">
            <img ng-if="!vm.selectedCurrency || vm.selectedCurrency.name !== currency.name" ng-src="assets/{{currency.image}}" alt="{{currency.name}}" />
            <img ng-if="vm.selectedCurrency && vm.selectedCurrency.name === currency.name" ng-src="assets/{{currency.imageHover}}" alt="{{currency.name}}" />
          </div>
        </div>
        <div layout="row" class="selected-currency" ng-if="vm.hasSelectedCurrency">
          <div layout="column" class="selection-wrapper">
            <div layout="column" class="selection-information">
              <div class="currency-logo" ng-if="vm.selectedCurrency.bigImage">
                <img ng-src="assets/{{vm.selectedCurrency.bigImage}}" alt="{{vm.selectedCurrency.name}}" />
              </div>
              <div class="currency-logo" ng-if="!vm.selectedCurrency.bigImage">
                <img ng-src="assets/{{vm.selectedCurrency.image}}" alt="{{vm.selectedCurrency.name}}" />
              </div>
              <div class="amount-description">
                Amount
              </div>            
              <div class="deposit-input" layout="row">
                <div class="deposit-symbol">
                  {{vm.selectedCurrency.symbol}}
                </div>
                <input ng-model="vm.amount" required flex min="0" fraction="2" placeholder="0.00">
              </div>
              <div class="currency-balance">
                <span>Balance in {{vm.selectedCurrency.currency}}:</span> {{vm.selectedCurrency.symbol}}{{vm.user.getCurrencyBalance(vm.selectedCurrency.currency)}}
              </div>
            </div>
            <div class="deposit-setup" layout="column">
              <md-progress-linear ng-if="vm.loading" md-mode="indeterminate"></md-progress-linear>
              <div layout="column" ng-if="!vm.loading">
                <div layout="column" ng-if="!vm.pendingDeposit">
                  <div layout="column" class="deposit-methods">
                    <div layout="column" class="selected-method">
                      <div layout="row" class="selected-method-information">
                        <div class="selected-method-image">
                          <img ng-src="{{vm.selectedMethod.icon}}" alt="{{vm.selectedMethod.name}}" />
                        </div>
                        <div class="dropdown-action" ng-click="vm.showMethods()">
                          <img src="assets/dropdown.png" />
                        </div>
                      </div>
                    </div>
                    <div layout="column" class="deposit-method-wrapper" ng-show="vm.canSelectMethod">
                      <div layout="column" ng-repeat="item in vm.depositMethods" ng-click="vm.setDepositMethod(item.id)" class="deposit-method">
                        <div>
                          <img ng-src="{{item.icon}}">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="deposit-button">
                    <submit-and-back
                      disable="!vm.amount || vm.depositing"
                      submit="vm.submit($event)"
                      label="'Deposit ' + vm.selectedCurrency.symbol"
                      image="'assets/deposit-active.png'"
                    >
                    </submit-and-back>
                  </div>
                </div>
                <div layout="column" ng-if="vm.pendingDeposit && !vm.isCrypto">
                  <span translate="translate.deposit.you_have_a_pending_deposit_initiated_on" translate-values="{date:vm.pendingDepositDate}"></span>
                  <span translate="translate.deposit.please_transfer" translate-values="{amount:vm.pendingDepositAmountFormatted, destination: vm.selectedMethod.destination}"></span>
                  <span translate="translate.deposit.receiver_name"></span>
                  <span translate="translate.deposit.beneficiary_name"></span>
                  <span translate="translate.deposit.beneficiary_address"></span>
                  <span translate="translate.deposit.reference" translate-values="{ref:vm.pendingDeposit.ref}"></span>
                  <span class="cancel-button-wrapper">
                    <md-button 
                    class="md-primary cancel"
                    ng-disabled="vm.cancelling"
                    ng-click="vm.cancelDeposit($event, vm.pendingDeposit)">Cancel Topup</md-button>
                  </span>
                </div>
                <div layout="column" ng-if="vm.pendingDeposit && vm.isCrypto">
                  <span translate="translate.deposit.you_have_a_pending_deposit_initiated_on" translate-values="{date:vm.pendingDepositDate}"></span>
                  <span ng-bind-html="vm.pendingDeposit.notes"></span>
                  <span class="cancel-button-wrapper">
                    <md-button 
                    class="md-primary cancel"
                    ng-disabled="vm.cancelling"
                    ng-click="vm.cancelDeposit($event, vm.pendingDeposit)">Cancel Topup</md-button>
                  </span>
                </div>
                <span ng-show="vm.errorMessage">{{vm.errorMessage}}</span>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
@Inject('$scope','$q','user','headerTitleService','depositMethod','server','asset','$mdToast','$location','$translate','keys','errors')
export class DepositComponent {
  depositing: boolean;
  cancelling: boolean;
  currency:string;
  currencyId: string;
  isCrypto: boolean;
  assetInformation: IAsset;
  method:string;
  depositMethods: Array<IServerDepositMethod>;
  paymentAccounts: Array<IPaytahPaymentAccount>;
  selectedMethod: IServerDepositMethod;
  chosenSelectedMethod: boolean;
  selectedPaymentAccount: IPaytahPaymentAccount
  pendingDeposit: IServerDeposit;
  pendingDepositAmountFormatted: string;
  pendingDepositDate: string;
  errorMessage: string;
  loading: boolean = true;
  currencies: ICurrency[];
  amount: string;
  status: string;
  selectedCurrency: ICurrency;
  hasSelectedCurrency: boolean;
  canSelectMethod: boolean;

  constructor(private $scope: angular.IScope,
              private $q: angular.IQService,
              private user: UserService,
              private headerTitleService: HeaderTitleService,
              private depositMethod: DepositMethodService,
              private server: ServerService,
              private asset: AssetService,
              private $mdToast: angular.material.IToastService,
              private $location: angular.ILocationService,
              private $translate: angular.translate.ITranslateService,
              private keys: KeysService,
              private errors: ErrorsService) {
                if (!user.requireLogin())
                  return;

                this.canSelectMethod = false;
                this.depositing = false;
                this.chosenSelectedMethod = false;
                this.hasSelectedCurrency = false;
                this.currencies = currencies.filter(currency => {
                  return !currency.disabled;
                });
                this.updateHeaderTitle();
                if(this.currency) {
                  this.selectCurrency(this.currencies.find(currency => currency.currency.toLowerCase() == this.currency.toLowerCase()), this.method);
                }
  }

  updateHeaderTitle() {
    const title = {
      title: this.$translate.instant('translate.toolbar.deposit.label')
    };

    if(this.currency) {
      title['value'] = this.currency.toUpperCase();
    }

    this.headerTitleService.setTitle(title);
  }

  setDepositMethods(depositMethods: IServerDepositMethod[], method: string) {
    this.$scope.$evalAsync(()=>{
      this.depositMethods = depositMethods;

      if(method) {
        this.setDepositMethod(method);
      } 
      else if(this.depositMethods.length) {
        this.setDepositMethod(this.depositMethods[0].id);
      }
    });
  }

  onCurrencyChange(method?: string) {
    this.loading = true;
    this.chosenSelectedMethod = false;
    this.selectedMethod = null;
    this.amount = '';

    this.assetInformation = this.asset.getAssetByName(this.selectedCurrency.currency);
    this.currencyId = this.assetInformation.asset;

    const methods = this.depositMethod.getDepositMethodsByCurrency(this.currencyId);

    if(methods.length > 0) {
      this.setDepositMethods(methods, method);
    }
    else {
      this.depositMethod.getDepositMethodList(this.selectedCurrency.crypto, this.isCrypto ? [this.currencyId] : null)
        .then(() => {
          const currencyDepositMethods = this.depositMethod.getDepositMethodsByCurrency(this.currencyId);
          this.setDepositMethods(currencyDepositMethods, method);
        });
    }
  }

  showMethods() {
    this.canSelectMethod = !this.canSelectMethod;
  }

  setDepositMethod(method: string) {
    const methodToUse = method.toLowerCase();

    if(!this.method || (this.method.toLowerCase() !== methodToUse)) {
      this.$location.path(`/deposit/${this.currency.toLowerCase()}/${methodToUse}`);
      return;
    }

    const newMethod = this.depositMethod.getDepositMethodsByCurrency(this.currencyId).find(m=>m.id==method);

    if(this.depositing || this.cancelling || this.selectedMethod != newMethod) {
      this.selectedMethod = newMethod;
      this.pendingDeposit = null;
      this.pendingDepositAmountFormatted = null;
      this.pendingDepositDate = null;
      this.chosenSelectedMethod = true;

      this.findExistingDeposit().then(deposit=>{
        /* There are pending deposits */
        if (deposit) {
          this.$scope.$evalAsync(()=>{
            this.loading = false;
            this.pendingDeposit = deposit;
            this.pendingDepositAmountFormatted = `${this.selectedCurrency.symbol} ${utils.formatMoney(deposit.amount, this.assetInformation.decimals)}`;
            this.pendingDepositDate = utils.formatDate(new Date(parseInt(deposit.timestamp)));
          })
        }
        else {
          /* There are no pending deposits */
          this.user.refresh().then(() =>{
            this.$scope.$evalAsync(() => {
              this.loading = false;
              this.paymentAccounts = this.user.getClient().paymentAccounts || []

              /* If there are no payment accounts we use the one on the deposit method */
              if (this.paymentAccounts.length == 0) {
                this.paymentAccounts.push({
                  client: "0",
                  description: "0",
                  iban: this.selectedMethod.destination,
                  onpexPaymentAccount: "0",
                  state: 1
                })
              }
              this.paymentAccounts.forEach(paymentAccount => {
                paymentAccount['formattedIban'] = utils.formatIBAN(paymentAccount.iban)
              })
              this.selectedPaymentAccount = this.paymentAccounts[0];          
            })
          })
        }
      }, () => {
        this.$scope.$evalAsync(()=>{
          this.loading = false;
        })
      });
    }
    else {
      this.$scope.$evalAsync(()=>{
        this.loading = false;
      })
    }

    this.canSelectMethod = false;
  }

  private findExistingDeposit(): angular.IPromise<IServerDeposit> {
    let deferred = this.$q.defer();
    let account = this.user.getAccount();

    if(this.isCrypto) {
      this.server.mwCryptoDepositList(account, false, true, true).then(
        deposits => {
          deferred.resolve(deposits.find(d=>d.depositMethod==this.method));
        },
        deferred.reject
      );
    }
    else {
      this.server.depositList(account, false, true, true, 0, 100).then(
        deposits => {
          deferred.resolve(deposits.find(d=>d.depositMethod==this.method));
        },
        deferred.reject
      );
    }
    return <angular.IPromise<IServerDeposit>> deferred.promise;
  }

  selectCurrency(currency: ICurrency, method?: string) {
    const currencyToDeposit = currency.currency.toLowerCase();

    if(!this.currency || (this.currency.toLowerCase() !== currencyToDeposit)) {
      this.$location.path(`/deposit/${currencyToDeposit}`);
      return;
    }

    if(this.selectedCurrency != currency) {
      this.isCrypto = currency.crypto;
      this.selectedCurrency = currency;
      this.hasSelectedCurrency = true;
      this.onCurrencyChange(method);
    }
  }

  submit() {
    this.depositing = true;
    if(this.isCrypto) {
      this.server.cryptoDeposit({
        account: this.user.getAccount(),
        currency: this.currencyId
      }).then(deposit => {
        this.$mdToast.show(this.$mdToast.simple().textContent("SUCCESS").hideDelay(5000));
        this.setDepositMethod(this.method);
        this.depositing = false;
      });
    }
    else {
      this.keys.getKey().then(
        key => {
          this.server.claimRef().then(
            ref => {
              let amount = Long.fromString(String(Math.round(parseFloat(this.amount)*100)), false)
              let currency = Long.fromString(this.selectedMethod.currency, true)
              DepositCreate.createMessageToSelfTransaction(key, {
                amount,
                currency,
                depositMethod: this.selectedMethod.idStr || this.selectedMethod.id,
                ref: ref.ref,
                refBase: ref.refBase    
              }).then(
                txBytes => {
                  this.server.broadcast(txBytes).then(
                    response => {
                      this.$mdToast.show(this.$mdToast.simple().textContent("SUCCESS").hideDelay(5000));
                      this.setDepositMethod(this.method);
                      this.depositing = false;
                    },
                    e => {
                      const error: ServerEngineError = e;
                      this.errors.error(error.description);
                      this.depositing = false;
                    }
                  )
                },
                e => {
                  const error: ServerEngineError = e;
                  this.errors.error(error.description);
                  this.depositing = false;
                }
              )
            },
            e => {
              const error: ServerEngineError = e;
              this.errors.error(error.description);
              this.depositing = false;
            }
          )
        },
        e => {
          const error: ServerEngineError = e;
          this.errors.error(error.description);
          this.depositing = false;
        }
      )
    }
  }

  cancelDeposit($event, deposit: IServerDeposit) {
    this.cancelling = true;
    if(this.isCrypto) {
      this.server.cancelCryptoDeposit({
        account: this.user.getAccount(),
        currency: this.currencyId
      }).then(deposit => {
        this.$mdToast.show(this.$mdToast.simple().textContent("CANCELLED").hideDelay(5000));
        this.setDepositMethod(this.method);
        this.cancelling = false;
      });
    }
    else {
      this.keys.getKey().then(
        key => {
          DepositUpdateStatus.createMessageToSelfTransaction(key, {
            id: Long.fromString(deposit.id, true),
            newStatus: DepositStatus.CANCELLED
          }).then(
            txBytes => {
              this.server.broadcast(txBytes).then(
                response => {
                  if (response.success) {
                    this.$mdToast.show(this.$mdToast.simple().textContent("CANCELLED").hideDelay(5000));
                    this.setDepositMethod(this.method);
                    this.cancelling = false;
                  }
                  else {
                    this.errors.error(response);
                    this.cancelling = false;
                  }
                },
                e => {
                  const error: ServerEngineError = e;
                  this.errors.error(error.description);
                  this.cancelling = false;
                }
              )
            },
            e => {
              const error: ServerEngineError = e;
              this.errors.error(error.description);
              this.cancelling = false;
            }
          )
        },
        e => {
          const error: ServerEngineError = e;
          this.errors.error(error.description);
          this.cancelling = false;
        }
      )
    }
  }
}