import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService } from '../../services/server';
import * as utils from '../../lib/utils';
declare var angular: angular.IAngularStatic;

@RouteConfig('/payment/:recipient/:amount/:description')
@Component({
  selector: 'payment',
  inputs: ['recipient','amount','currency','description'],
  template: `
    <div layout="column" flex layout-padding ng-if="!vm.recipientEmail">
      <h3>Unknown merchant</h3>
    </div>
    <div layout="column" flex layout-padding ng-if="vm.confirmPayment == false && vm.recipientEmail">
      <div>
        <div flex layout="row" class="heading">
          <a href="/"><img src="../assets/arrow-circle-o-left.png"></a><h2>Payment</h2>
        </div>
      </div>
      <h3>Pay {{vm.amountFormatted}} to {{vm.recipientEmail}}</h3>
      <p>{{vm.description}}</p>
      <form name="loginForm" layout="column">
        <md-input-container flex>
          <label>Id</label>
          <input ng-model="vm.recipient" name="recipient" readonly flex>
        </md-input-container>
        <md-input-container flex>
          <label>Name</label>
          <input ng-model="vm.recipientName" name="recipientName" readonly flex>
        </md-input-container>
        <md-input-container flex ng-if="vm.user.isUnlocked() === false">
          <label>Email</label>
          <input type="email" ng-model="vm.email" name="email" required flex>
        </md-input-container>
        <md-input-container flex ng-if="vm.user.isUnlocked() === false">
          <label>Password</label>
          <input type="password" ng-model="vm.password" name="password" required flex>
        </md-input-container>
        <submit-and-back
          disable="!loginForm.$valid"
          submit="vm.proceed($event)"
          label="'PROCEED'"
        >
        </submit-and-back>
        <div ng-show="vm.errorMessage">{{vm.errorMessage}}</div>
      </form>
    </div>
    <div layout="column" flex layout-padding ng-if="vm.confirmPayment == true && vm.recipientEmail">
      <div>
        <div flex layout="row" class="heading">
          <a href="/"><img src="../assets/arrow-circle-o-left.png"></a><h2>Confirm Payment</h2>
        </div>
      </div>
      <h3>Confirm payment of {{vm.amountFormatted}} to {{vm.recipientName}}</h3>
      <div layout="row" flex>
        <md-button  ng-click="vm.cancel($event)">CANCEL</md-button>
        <md-button class="md-primary" ng-click="vm.confirm($event)">CONFIRM</md-button>
      </div>
    </div>
  `
})
@Inject('$scope','user','server','$mdToast','$location')
export class PaymentComponent {
  /* @input */
  recipient: string
  amount: string
  currency: string
  description: string
  recipientName: string
  recipientEmail: string
  amountFormatted: string
  password: string
  email:string

  isUnknownMerchant = false
  errorMessage: string
  confirmPayment = false

  constructor(
      private $scope: angular.IScope,
      private user: UserService,
      private server: ServerService,
      private $mdToast: angular.material.IToastService,
      private $location: angular.ILocationService) {
    this.amountFormatted =  utils.formatMoney(this.amount, 2) + ' EUR';

    this.loadMerchantData()
  }

  loadMerchantData() {
    // this.server.findUser(this.recipient).then(
    //   merchant => {
    //     this.$scope.$evalAsync(() => {
    //       this.recipientName = merchant.name
    //       this.recipientEmail = merchant.email
    //     })
    //   },
    //   error => {
    //     this.$scope.$evalAsync(() => {
    //       this.isUnknownMerchant = true
    //     })
    //   }
    // )
  }

  proceed($event) {
/*    this.server.signin({email:this.email,password:this.password}).then(
      (account)=>{
        if (angular.isString(account)) {
          this.user.unlock(this.email, this.password, account).then(()=>{
            if (this.user.getUser()) {
              let user = this.user.getUser()
              if (parseInt(this.amount) > parseInt(user.eurBalance)) {
                this.$scope.$evalAsync(()=>{
                  this.errorMessage = 'Insufficient balance.';
                })
              }
              else {
                this.$scope.$evalAsync(()=>{
                  this.confirmPayment = true
                })
              }
            }
            else {
              this.$scope.$evalAsync(()=>{
                this.errorMessage = 'There was a problem logging in this user.';
              })
            }
          })
        }
        else {
          this.$scope.$evalAsync(()=>{
            this.errorMessage = 'There was a problem logging in this user.';
          })
        }
      }, () => {
        this.$scope.$evalAsync(()=>{
          this.errorMessage = 'There was a problem logging in this user.';
        })
      }
    )*/
  }

  cancel() {
    window.location.reload()
  }

  confirm() {
    // this.server.transfer(this.user.getEmail(), this.user.getPassword(), this.amount, EUR.id, this.recipient, this.description||"").then(
    //   success => {
    //     this.$mdToast.show(this.$mdToast.simple().textContent("SUCCESS").hideDelay(5000));
    //     setTimeout(() => {
    //       this.$location.path('history');
    //     }, 2000)
    //   },
    //   (response) => {
    //     this.$mdToast.show(this.$mdToast.simple().textContent(utils.parseFailedReturnMessage(response)).hideDelay(5000).toastClass('failed-toast'));
    //   }
    // )
  }
}