import { Service, Inject } from '../core/decorators';
import { ServerService, IBeneficiary } from './server';
import { UserService } from './user-service';
declare var angular: angular.IAngularStatic;

@Service('beneficiary')
@Inject('$q','server','user')
export class BeneficiaryService {
  private readyPromise: angular.IPromise<{}>;
  private beneficiaries: Array<IBeneficiary>;

  constructor(private $q: angular.IQService,
              private server: ServerService,
              private user: UserService) {
    let deferred = $q.defer();
    this.readyPromise = deferred.promise;

    server.getBeneficiaries(this.user.getAccount()).then(beneficiaries=>{
      this.beneficiaries = beneficiaries;
      deferred.resolve(beneficiaries);
    }, deferred.reject);
  }

  public ready(): angular.IPromise<{}> {
    return this.readyPromise;
  }

  public getBeneficiaries() {
    return this.beneficiaries;
  }  
}