export const EUR = {
  id: "1246430022292240565",
  label: "EUR"
};

export const WSURL = "wss://wallet.paytah.com:6570/ws/";
export const HOST = "https://wallet.paytah.com";
export const PORT = "6590";
export let MIDDLEWARE_HOST = "https://wallet.paytah.com";
export let MIDDLEWARE_PORT = "6640";
export let PAYOUT_FEE_RECIPIENT = "18220089872319130407";

export interface ICurrency {
  name: string;
  currency: string;
  symbol: string;
  image: string;
  imageHover?: string;
  bigImage?: string;
  disabled?: boolean;
  crypto: boolean;
}

export const currencies: ICurrency[] = [
  {
    name: 'Euro',
    currency: 'EUR',
    symbol: '€',
    image: 'euro.png',
    imageHover: 'euro-hover.png',
    bigImage: 'euro-big-orange.png',
    crypto: false,
  },
  {
    name: 'Bitcoin',
    currency: 'BTC',
    symbol: 'Ƀ',
    image: 'bitcoin.png',
    imageHover: 'bitcoin-hover.png',
    bigImage: 'bitcoin-big.png',
    crypto: true,
  },
  {
    name: 'Ethereum',
    currency: 'ETH',
    symbol: 'Ξ',
    image: 'ethereum.png',
    imageHover: 'ethereum-hover.png',
    crypto: true,
    disabled: true
  }
];

export enum ExchangeType {
  BUY = 1,
  SELL = 2,
  TRADE = 3
}

export const exchangeFunctionalityDisabled: ExchangeType[] = [
  ExchangeType.TRADE 
]

export interface IExchange {
  name: string;
  type: ExchangeType;
  description: string;
  toDescription: string;
  disabled?: boolean;
  selected?: boolean;
}

export enum MessageType {
  INBOX = 1,
  SENT = 2,
  DRAFTS = 3,
  TRASH = 4,
  STARRED = 5,
  NEW = 6
}

export const messageFunctionalityDisabled: MessageType[] = [
  MessageType.DRAFTS,
  MessageType.STARRED
]

export interface IMessage {
  name: string;
  type: MessageType;
  description: string;
  route: string;
  disabled?: boolean;
  selected?: boolean;
  notAsTab?: boolean;
}