import { Service, Inject } from "../core/decorators";
import { keystore } from "../lib/keystore"
import { UserService } from "./user-service";
import { instance } from '../lib/sdk'

@Service('keys')
@Inject('user')
export class KeysService {
  oneTimeReturnedKey: string = null

  constructor(private user: UserService) {}

  setOneTimeKey(secretPhrase: string) {
    this.oneTimeReturnedKey = secretPhrase
  }

  getKey(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.oneTimeReturnedKey) {
        let key = this.oneTimeReturnedKey
        this.oneTimeReturnedKey = null
        resolve(key);
        return;
      }
      this.user.getPasswordPromise().then(
        password => {
          let key = keystore.loadKey(this.user.getEmail(), password);
          resolve(key);
        },
        error => {
          reject(error)
        }
      )
    })
  }

  getPublicKey(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getKey()
        .then(key => {
          resolve(instance.crypto.secretPhraseToPublicKey(key));
        },
        () => {
          resolve(null);
        })
    });
  }

  getAccount(email: string, password: string) {
    let key = keystore.loadKey(email, password);
    if (!key)
      return null
    let publicKey = instance.crypto.secretPhraseToPublicKey(key)
    return instance.crypto.getAccountIdFromPublicKey(publicKey)
  }
}