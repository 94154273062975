export class StorageService {
  
  constructor() {}

  get(key:string):string {
    return window.localStorage.getItem(key);
  }

  set(key:string, value:string): void {
    window.localStorage.setItem(key, value);
  }
}

export var storage = new StorageService();