import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService, IServerTransfer, IServerDeposit, IPaytahPayout, IServerHistory } from '../../services/server';
import { SubscriberService } from '../../services/subscriber';
import { HeaderTitleService } from '../../services/headerTitle';
import * as utils from '../../lib/utils';
import { AssetService } from '../../services/asset';
import { DepositMethodService } from '../../services/deposit-method';

interface IHistoryListItem {
  id: string;
  date: string;
  currency: string;
  type: string;
  method?: string;
  amount: string;
  status?: string;
  hasMessage: boolean;
  showMessage: boolean;
  showAll: boolean;
  message?: string;
  toggleMessage(item: IHistoryListItem): void;
  toggleTransaction(item: IHistoryListItem): void;
}

@RouteConfig('/history')
@Component({
  selector: 'history',
  template: `
    <div layout="column" flex>
      <div class="transactions" ng-if="!vm.showEmpty">
        <table class="show-gt-xs">
          <tr>
            <th>
              Date
            </th>
            <th>
              Currency
            </th>
            <th>
              Operation Type
            </th>
            <th>
              Payment Method
            </th>
            <th>
              Amount
            </th>
            <th>
              Status
            </th>
          </tr>
          <tr class="transaction" ng-repeat-start="item in vm.history" ng-class="{true: 'hasMessage'}[item.hasMessage]" ng-click="item.toggleMessage(item)" data-id="{{item.id}}">
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.currency }}
            </td>
            <td>
              {{ item.type }}
            </td>
            <td>
              {{ item.method }}
            </td>
            <td>
              {{ item.amount }}
            </td>
            <td>
              {{ item.status }}
            </td>
          </tr>
          <tr ng-repeat-end ng-if="item.hasMessage && item.showMessage" class="message" data-id="{{item.id}}">
            <td colspan="6">
              <p ng-bind-html="item.message"></p>
            </td>
          </tr>
        </table>
        <div class="hide-gt-xs">
          <div layout="column" class="transaction small" ng-repeat="item in vm.history" ng-class="{true: 'showAll'}[item.showAll]" ng-click="item.toggleTransaction(item)" data-id="{{item.id}}">
            <div class="preview" ng-if="!item.showAll">
              <div>{{ item.date }}</div>
              <hr />
              <div>{{ item.amount }}</div>
            </div>
            <div class="information" ng-if="item.showAll">
              <div class="date">
                {{ item.date }}
              </div>
              <div class="currency">
                <div>Currency</div>
                <hr />
                <div>{{ item.currency }}</div>
              </div>
              <div class="type">
                <div>Operation Type</div>
                <hr />
                <div>{{ item.type }}</div>
              </div>
              <div class="method" ng-if="item.method != undefined">
                <div>Payment Method</div>
                <hr />
                <div>{{ item.method }}</div>
              </div>
              <div class="amount">
                <div>Amount</div>
                <hr />
                <div>{{ item.amount }}</div>
              </div>
              <div class="status" ng-if="item.status != undefined">
                <div>Status</div>
                <hr />
                <div>{{ item.status }}</div>
              </div>
              <div class="message" ng-if="item.hasMessage">
                <p ng-bind-html="item.message"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="show-more-wrapper" ng-if="vm.showViewMore">
          <div class="show-more" ng-click="vm.viewMore()">
            View More
          </div>
        </div>
      </div>
      <div ng-show="vm.showEmpty">
        <div class="no-history">
          <div class="top">
            No history
          </div>
          <img src="assets/placeholder@2x.png">
          <div class="bottom">
            Once you have made transactions they will appear here
          </div>
        </div>
      </div>
    </div>
  `
})
@Inject('$scope','user','server','asset','depositMethod','headerTitleService','subscriber','$translate')
export class HistoryComponent {
  currentPage: number = 1;
  pageSize: number = 10;
  amount: number = 0;
  total: number = 0;
  history: Array<IHistoryListItem> = [];
  showEmpty: boolean;
  showViewMore: boolean;
  constructor(private $scope: angular.IScope,
              private user: UserService,
              private server: ServerService,
              private asset: AssetService,
              private depositMethod: DepositMethodService,
              private headerTitleService: HeaderTitleService,
              private subscriber: SubscriberService,
              private $translate: angular.translate.ITranslateService) {
                if (!user.requireLogin())
                  return;

    this.headerTitleService.setTitle({
      title: this.$translate.instant('translate.toolbar.history.label')
    });

    this.server.historyListCount(this.user.getAccount())
      .then(count => {
        this.total = count;
        if(count > 0) {
          this.load();
        }
        else {
          this.showEmpty = true;
        }
      });

    let reload = utils.debounce(()=>{ this.load() }, 1000, false);
    this.subscriber.unconfirmedTransaction({sender:user.getAccount()},reload,$scope);
    this.subscriber.unconfirmedTransaction({recipient:user.getAccount()},reload,$scope);
  }

  viewMore() {
    this.currentPage++;
    this.load();
  }

  addItemsInHistory(entries: IServerHistory[]): number {
    const currentHistory = this.history;

    entries.map((entry:IServerHistory|any) => {
      const historyFound = currentHistory.find(history => history.id === entry.id);

      if(!historyFound) {
        let historyToAdd = null;
        switch (entry.historyType) {
          case 1: 
            historyToAdd = this.renderDeposit(entry);
            break;
          case 2: 
            historyToAdd = this.renderPayout(entry);
            break;
          case 3: 
            historyToAdd = this.renderTransfer(entry);
            break;
        }
        currentHistory.push(historyToAdd);
      }
    });

    return currentHistory.length;
  }

  load() {
    const page = this.currentPage;
    const pageSize = this.pageSize;

    const firstItem = (page - 1) * pageSize;
    const lastItem = page * pageSize - 1;
    this.server.historyList(this.user.getAccount(), firstItem, lastItem).then(
      entries => {
        this.$scope.$evalAsync(()=>{
          this.showViewMore = entries.length == pageSize;
          this.amount = this.addItemsInHistory(entries);
        });
      }
    );
  }

  private hideAllTransactions() {
    this.history.forEach(item => {
      item.showMessage = false;
      item.showAll = false;
    });
  }

  private toggleMessage(item: IHistoryListItem) {
    if(!item.hasMessage) {
      return;
    }

    const itemIsHidden = !item.showMessage;

    if(itemIsHidden) {
      this.hideAllTransactions();
    }

    item.showMessage = !item.showMessage;
    item.showAll = !item.showAll;
  }

  private toggleTransaction(item: IHistoryListItem) {
    const itemIsHidden = !item.showAll;

    if(itemIsHidden) {
      this.hideAllTransactions();
    }

    item.showAll = !item.showAll;

    if(item.hasMessage)
      item.showMessage = !item.showMessage;
  }

  private otherAccount(transfer: IServerTransfer): {account:string,accountName:string,accountEmail:string} {
    if (this.user.getAccount() == transfer.recipient) {
      return {
        account: transfer.sender,
        accountName: transfer.senderName,
        accountEmail: transfer.senderEmail
      }
    }
    return {
      account: transfer.recipient,
      accountName: transfer.recipientName,
      accountEmail: transfer.recipientEmail
    }
  }

  private renderDepositStatus(deposit: IServerDeposit) {
    if (deposit.status==0) return 'Pending';
    if (deposit.status==1) return 'Completed';
    if (deposit.status==2) return 'Cancelled';
    return 'Unknown';
  }

  private renderPayoutStatus(payout: IPaytahPayout) {
    if (payout.status==0) return this.$translate.instant('translate.history.status.pending');
    if (payout.status==1) return this.$translate.instant('translate.history.status.completed');
    if (payout.status==2) return this.$translate.instant('translate.history.status.cancelled');
    if (payout.status==3) return this.$translate.instant('translate.history.status.frozen');
    return 'Unknown';
  }

  private renderDepositMethod(deposit: IServerDeposit) {
    const depositMethod = this.depositMethod.getDepositMethodByCurrencyById(deposit.currency, deposit.depositMethod);

    if(depositMethod)
      return depositMethod.name;

    return 'Unknown Deposit Method';
  }

  private renderDepositMessage(deposit: IServerDeposit) {
    let message = '';

    if(deposit.destination) {
      message += `Deposited to ${deposit.destination}`;
    }

    if(deposit.ref) {
      message += `<br />Reference: ${deposit.ref}`;
    }

    if(deposit.notes) {
      message += `<br />Notes: ${deposit.notes}`;
    }

    return message;
  }

  private renderDeposit(deposit: IServerDeposit): IHistoryListItem {
    const assetInformation = this.asset.getAssetById(deposit.currency);
    let dateStr = utils.formatDate(new Date(parseInt(deposit.timestamp)), 'dd/mm/yyyy');
    let amountStr = utils.formatMoney(deposit.amount, assetInformation.decimals);
    const message = this.renderDepositMessage(deposit);
    
    const item: IHistoryListItem = {      
      id: deposit.id,
      date: `${dateStr}`,
      currency: assetInformation.name,
      type: 'Deposit',
      method: this.renderDepositMethod(deposit),
      amount: `+${amountStr} ${assetInformation.name}`,
      status: this.renderDepositStatus(deposit),
      message,
      hasMessage: message.length > 0,
      showMessage: false,
      toggleMessage: this.toggleMessage.bind(this),
      showAll: false,
      toggleTransaction: this.toggleTransaction.bind(this)
    };

    return item;
  }

  private renderTransfer(transfer: IServerTransfer): IHistoryListItem {
    const assetInformation = this.asset.getAssetById(transfer.currency);
    let dateStr = utils.formatDate(new Date(parseInt(transfer.timestamp)), 'dd/mm/yyyy');
    let amountStr = utils.formatMoney(transfer.amount, assetInformation.decimals);
    let isFee = transfer.isFeeForTransaction!='0';
    let otherAccount = this.otherAccount(transfer);
    let addTo = transfer.recipient == this.user.getAccount();
    let normalMessage = '';

    if(transfer.message) {
      normalMessage += addTo ? 
        `From ${transfer.senderName} (${transfer.senderEmail})<br />Message: ${transfer.message}` :
        `To ${transfer.recipientName} (${transfer.recipientEmail})<br />Message: ${transfer.message}`;
    }

    let message = isFee ?
      (this.$translate.instant('translate.history.transfer_fee_for', {transaction:transfer.isFeeForTransaction})) :
      (normalMessage || '');

    const item: IHistoryListItem = {
      id: transfer.id,
      date: `${dateStr}`,
      currency: assetInformation.name,
      type: 'Transfer',
      amount: `${addTo?'+':'-'}${amountStr} ${assetInformation.name}`,
      message,
      hasMessage: message.length > 0,
      showMessage: false,
      toggleMessage: this.toggleMessage.bind(this),
      showAll: false,
      toggleTransaction: this.toggleTransaction.bind(this)
    };

    return item;
  }

  private renderPayoutMessage(payout: IPaytahPayout) {
    let message = `Withdrawal to`;

    if(payout.bic) {
      message += `<br />BIC: ${payout.bic}`;
    }

    if(payout.iban) {
      message += `<br />IBAN: ${payout.iban}`;
    }

    if(payout.country) {
      message += `<br />Country: ${payout.country}`;
    }

    if(payout.reference) {
      message += `<br />Reference: ${payout.reference}`;
    }

    if(payout.paymentAccount) {
      message += `<br />Payment Account: ${payout.paymentAccount}`;
    }

    if(payout.detailsOfCharges) {
      message += `<br />Details of Charges: ${payout.detailsOfCharges}`;
    }

    if(payout.notes) {
      message += `<br /><br />${payout.notes}`;
    }

    return message;
  }

  private renderPayout(payout: IPaytahPayout): IHistoryListItem {
    const assetInformation = this.asset.getAssetById(payout.currency);
    let dateStr = utils.formatDate(new Date(parseInt(payout.timestamp)), 'dd/mm/yyyy');
    let amountStr = utils.formatMoney(payout.amount, assetInformation.decimals);
    const message = this.renderPayoutMessage(payout);
    
    const item: IHistoryListItem = {
      id: payout.id,
      date: `${dateStr}`,
      currency: assetInformation.name,
      type: 'Withdrawal',
      amount: `-${amountStr} ${assetInformation.name}`,
      status: this.renderPayoutStatus(payout),
      message,
      hasMessage: message.length > 0,
      showMessage: false,
      toggleMessage: this.toggleMessage.bind(this),
      showAll: false,
      toggleTransaction: this.toggleTransaction.bind(this)
    };

    return item;
  }
}