import { Service, Inject } from '../core/decorators';
import { HttpService } from './http';
import { HOST, PORT, MIDDLEWARE_HOST, MIDDLEWARE_PORT } from './settings';

export interface IBeneficiary {
  id: string;
  amount?: string;
  name?: string;
  street?: string;
  streetNo?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  iban?: string;
  bic?: string;
  swift?: string;
  destinationCountry?: string;
  detailsOfCharges?: string;
  reference?: string;
}

export interface BroadcastResponse {
  success: boolean
  errorDescription: string
  errorCode: number
}

export interface PaytahSignupParams {
  account: string
  publicKey: string
  name: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  clientType: number
  companyRegistrationNumber: string
}

export interface IPaytahReferenceNumber {
  ref: string
  refBase: number
  success: boolean
}

export interface IPaytahPayoutMethod {
  id: string
  idStr: string
  currency: string
  disabled: boolean
  name: string
  fee: number
  notes: string
  icon: string
  recipient: string
}

export interface PaytahValidateIBANResponse {
  iban: string
  bic: string
  valid: string
}

export interface IAsset {
  asset: string;
  decimals: number;
  name: string;
  symbol: string;
}

@Service('server')
@Inject('http','$q')
export class ServerService {
  constructor(private http: HttpService, private $q: angular.IQService){}

  private confirmDialog (msg: string, requestType: string, path: string) {
    return new Promise((resolve, reject) => {
      const confirmed = window.confirm(msg);

      if(confirmed) {
        return this[requestType](path)
                .then(
                  response => {
                    resolve(response);
                  },
                  error => {
                    reject(error);
                  });
      }
    });
  }
  
  private get(route: string, returns?: string) {
    return this.http.get(HOST, PORT, '/api/v1'+route, returns);
  }

  private post(route: string, request: any, returns?: string) {
    return this.http.post(HOST, PORT, '/api/v1'+route, request, returns);
  }

  private middlewareGet(route: string, returns?: string) {
    return this.http.get(MIDDLEWARE_HOST, MIDDLEWARE_PORT, route, returns);
  }

  private middlewarePost(route: string, request?: any, returns?: string) {
    return this.http.post(MIDDLEWARE_HOST, MIDDLEWARE_PORT, route, request, returns);
  }

  /* @AUTHOKEN = ANYONE */
  public validateIBAN(iban: string): angular.IPromise<PaytahValidateIBANResponse> {
    return <angular.IPromise<PaytahValidateIBANResponse>> this.post('/paytah-onpex/validateiban', {iban:iban});
  }

  /* @AUTHOKEN = TXN SENDR */
  public broadcast(transactionBytesHEX: string, noConfirm?: boolean): angular.IPromise<BroadcastResponse> {
    if(noConfirm)
      return <any> this.get(`/paytah-broadcast/broadcast/${transactionBytesHEX}`);

    return <any> this.confirmDialog("Are you sure you want to perform this action?", 'get', `/paytah-broadcast/broadcast/${transactionBytesHEX}`);
  }

  /* @AUTHOKEN = ANYONE */
  public signup(params: PaytahSignupParams): angular.IPromise<BroadcastResponse> {
    return <any> this.post(`/paytah-signup/signup`, params);
  }

  /* @AUTHOKEN = ANYONE */
  eurAssetId(): angular.IPromise<string> {
    return <angular.IPromise<string>> this.get('/blocktech-system/eur-asset-id', 'value');
  }

  /* @AUTHOKEN = ANYONE */
  public claimRef(): angular.IPromise<IPaytahReferenceNumber> {
    return <angular.IPromise<IPaytahReferenceNumber>> this.get('/blocktech-deposit/claim-ref')
  }

  /* @AUTHOKEN = ANYONE */
  depositMethodList(): angular.IPromise<Array<IServerDepositMethod>> {
    return <angular.IPromise<Array<IServerDepositMethod>>> this.get(`/blocktech-deposit-method/list/true/name/false/0/100`);
  }

  /* @AUTHOKEN = ANYONE */
  cryptoDepositMethodList(currencies: string[]): angular.IPromise<Array<IServerDepositMethod>> {
    let currencyQS = '?';

    currencies.forEach(currency => {
      currencyQS += `currency=${currency}`;
    });

    return <angular.IPromise<Array<IServerDepositMethod>>> this.middlewareGet(`/deposit/methods${currencyQS}`);
  }  

  /* @AUTHOKEN = ANYONE */
  cryptoDeposit(payload: ICryptoDepositPayload): angular.IPromise<Array<IServerDeposit>> {
    return <angular.IPromise<Array<IServerDeposit>>> this.middlewarePost('/deposit', payload);
  }

  cancelCryptoDeposit(payload: ICryptoDepositPayload): angular.IPromise<Array<IServerDeposit>> {
    const {
      account,
      currency
    } = payload;
    return <angular.IPromise<Array<IServerDeposit>>> this.middlewarePost(`/deposit/cancel/${account}/${currency}`);
  }

  mwCryptoDepositList(account:string, excludePending: boolean, excludeCompleted: boolean, excludeCancelled: boolean): angular.IPromise<Array<IServerDeposit>> {
    return <angular.IPromise<Array<IServerDeposit>>> this.middlewareGet(`/deposit/list/${account}/${excludePending}/${excludeCompleted}/${excludeCancelled}`);
  }

  /* @AUTHOKEN = ANYONE */
  payoutMethodList(): angular.IPromise<Array<IPaytahPayoutMethod>> {
    return <angular.IPromise<Array<IPaytahPayoutMethod>>> this.get(`/paytah-payout-method/list/true/name/false/0/100`);
  }

  /* @AUTHOKEN = ACCOUNT */
  depositList(account:string, excludePending: boolean, excludeCompleted: boolean, excludeCancelled: boolean, from: number, to: number): angular.IPromise<Array<IServerDeposit>> {
    return <angular.IPromise<Array<IServerDeposit>>> this.get(`/blocktech-deposit/list/0/${account}/${excludePending}/${excludeCompleted}/${excludeCancelled}/timestamp/false/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  depositListCount(account:string, excludePending: boolean, excludeCompleted: boolean, excludeCancelled: boolean): angular.IPromise<number> {
    return <angular.IPromise<number>> this.get(`/blocktech-deposit/count/0/${account}/${excludePending}/${excludeCompleted}/${excludeCancelled}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  cryptoDepositList(account:string, excludePending: boolean, excludeCompleted: boolean, excludeCancelled: boolean, from: number, to: number): angular.IPromise<Array<IServerDeposit>> {
    return <angular.IPromise<Array<IServerDeposit>>> this.get(`/blocktech-deposit/list/1/${account}/${excludePending}/${excludeCompleted}/${excludeCancelled}/timestamp/false/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  cryptoDepositListCount(account:string, excludePending: boolean, excludeCompleted: boolean, excludeCancelled: boolean): angular.IPromise<number> {
    return <angular.IPromise<number>> this.get(`/blocktech-deposit/count/1/${account}/${excludePending}/${excludeCompleted}/${excludeCancelled}`);
  }

  /* @AUTHOKEN = ACCOUNT for DEPOSIT */
  depositFind(id: string): angular.IPromise<IServerDeposit> {
    return <angular.IPromise<IServerDeposit>> this.get(`/blocktech-deposit/find/${id}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  historyList(account:string, from: number, to: number): angular.IPromise<Array<IServerHistory>> {
    return <angular.IPromise<Array<IServerHistory>>> this.get(`/blocktech-history/list/${account}/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  historyListCount(account:string): angular.IPromise<number> {
    return <angular.IPromise<number>> this.get(`/blocktech-history/count/${account}`, 'count');
  }

  /* @AUTHOKEN = ACCOUNT */
  messageHeaders(account:string, inbox:boolean, outbox:boolean, from: number, to: number): angular.IPromise<Array<IServerMessage>> {
    return <angular.IPromise<Array<IServerMessage>>> this.get(`/blocktech-message/headers/${account}/${inbox}/${outbox}/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  messageHeaders2(account:string, receivedOnly:boolean, sentOnly:boolean, contact:string, trashed:boolean, from: number, to: number): angular.IPromise<Array<IServerMessageHeader>> {
    return <angular.IPromise<Array<IServerMessageHeader>>> this.get(`/blocktech-message/headers2/${account}/${receivedOnly}/${sentOnly}/${contact}/${trashed}/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT for THREAD, MINIMAL INFO FOR `OTHER ACCOUNT IN THREAD` */
  messages(thread:string, from: number, to: number): angular.IPromise<Array<IServerMessage>> {
    return <angular.IPromise<Array<IServerMessage>>> this.get(`/blocktech-message/messages/${thread}/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT for CONTACT, MINIMAL INFO FOR `OTHER ACCOUNT IN CONTACTS` */
  contacts(account:string, query:string, from:number, to:number): angular.IPromise<Array<IServerContact>> {
    return <angular.IPromise<Array<IServerContact>>> this.get(`/blocktech-history/contacts/${account}/${query}/${from}/${to}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  clientFindById(id: string): angular.IPromise<IPaytahClient> {
    return <angular.IPromise<IPaytahClient>> this.get(`/paytah-client/find-by-account/101/${id}`);
  }

  /* @AUTHOKEN = ACCOUNT */
  clientFindByEmail(email: string): angular.IPromise<IPaytahClient> {
    return <angular.IPromise<IPaytahClient>> this.get(`/paytah-client/find-by-email/101/${email}`);
  }

  /* @AUTHOKEN = ANYONE */
  clientFindIdByEmail(email: string): angular.IPromise<string> {
    return <angular.IPromise<string>> this.get(`/paytah-client/find-id-by-email/101/${email}`,'value');
  }

  /* @AUTHOKEN = ACCOUNT */
  signupFindById(id: string): angular.IPromise<IPaytahSignup> {
    return <angular.IPromise<IPaytahSignup>> this.get(`/paytah-signup/find-by-account/${id}`);
  }

  /* @AUTHOKEN = ACCOUNT for EMAIL */
  signupFindByEmail(email: string): angular.IPromise<IPaytahSignup> {
    return <angular.IPromise<IPaytahSignup>> this.get(`/paytah-signup/find-by-email/${email}`);
  }

  /* @AUTHOKEN = ANYONE */
  public upload(account: string, request: any): angular.IPromise<any> {
    return <angular.IPromise<any>> this.http.post(HOST, PORT,
      `/api/v1/paytah-upload/uploadDoc/${account}`, request, undefined, true);
  }

  /* @AUTHOKEN = ANYONE */
  public alertIban(account: string): angular.IPromise<any> {
    return <angular.IPromise<any>> this.post('/paytah-onpex/alert-iban', {account:account});
  }

  /* @AUTHOKEN = ANYONE */
  getAllAssets(): angular.IPromise<IAsset[]> {
    return <angular.IPromise<any>> this.get('/exchange/assets/protocol1/0/1000');
  }

  /* @AUTHOKEN = ANYONE */
  getAssetBySymbol(symbol: string): angular.IPromise<IAsset> {
    return <angular.IPromise<any>> this.get(`/exchange/assets/protocol1/${symbol}`);
  }

  public getBeneficiaries(account: string): angular.IPromise<IBeneficiary[]> {
    return <angular.IPromise<IBeneficiary[]>> this.beneficiaries();
  }

  beneficiaries(): angular.IPromise<IBeneficiary[]> {
    const deferred = this.$q.defer();
    const beneficiaries: IBeneficiary[] = [
      {
        id: 'abc123',
        name: 'Dennis De Klerk',
        amount: '100000',
        street: 'The Hague Train Station',
        streetNo: '69',
        zipCode: '6969',
        city: 'The Hague',
        country: 'NL',
        iban: '1234567890',
        bic: '1234567890',
        swift: '1234567890',
        destinationCountry: 'NL',
        detailsOfCharges: 'SHA',
        reference: 'NA'
      },
      {
        id: 'def345',
        name: 'Marco Lavanna',
        amount: '200000',
        street: 'The Crypto Valley',
        streetNo: '69',
        zipCode: '6969',
        city: 'Zug',
        country: 'CH',
        iban: '1234567890',
        bic: '1234567890',
        swift: '1234567890',
        destinationCountry: 'CH',
        detailsOfCharges: 'SHA',
        reference: 'NA'
      }
    ];
    return this.$q(resolve => {
      setTimeout(function() {
        resolve(beneficiaries);
      }, 1000);
    });
  }
}

export interface IPaytahClient {
  id: string
  addresses: Array<IPaytahAddress>
  paymentAccounts: Array<IPaytahPaymentAccount>
  onpexClient: string
  description: string
  category: IServerCategory
  name: string
  onpexParent: string
  state: number
  taxCountryCode: string
  tin: string
  type: number
  supportEmail: string
  supportPhone: string
  birthDate: string
  firstName: string
  gender: number
  lastName: string
  middleName: string
  attention: string
  balance: string
  wantsIban: boolean
}

export interface IPaytahSignup {
  account: string
  publicKey: string
  signature: string
  name: string
  email: string
  countryCode: string
  clientType: number
  companyRegistrationNumber: string
  confirmationToken: string
  state: number
}

export interface IPaytahAddress {
  client: string
  name: string
  attention: string
  city: string
  country: string
  postalBox: string
  street: string
  streetNo: string
  zipCode: string
  billingEmail: string
  addressType: number
  timestamp: number
  _state: number
}

export interface IPaytahPaymentAccount {
  client: string
  iban: string
  state: number
  onpexPaymentAccount: string
  description: string
}

export interface IServerContact {
  account:string;
  email:string;
  name:string;
}

export interface IServerMessageHeader {
  id:string;
  account:string;
  email:string;
  name:string;
  timestamp:number;
  topic:string;
  preview:string;
  read:boolean;
  trashed:boolean;
  starred:boolean;
  count:number;
  checked?:boolean;
}

export interface IServerMessage {
  id: string;
  sender: string;
  recipient: string;
  senderEmail: string;
  recipientEmail: string;
  timestamp: number;
  content: string;
  topic: string;
  replyTo: string;
  isRead: boolean;
  isTrashed: boolean;
  isReplied: boolean;
}

export interface IServerHistory {
  historyType: HistoryType;
}

export interface IServerSuccess {
  success:boolean;
}

export interface IServerSignup {
  name: string;
  email: string;
  country: string;
  password: string;
  isCompany: boolean;
  dateOfBirth: string;
  placeOfBirth: string;
  address: string;
  socialId: string;
}

export interface IServerSignin {
  email: string;
  password: string;
}

export interface IServerSignupComplete {
  email: string;
  password: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  socialId: string;
  iban: string;
}

export interface IServerUser {
  account: string;
  timestamp: number;
  email: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  socialId: string;
  iban: string;
  notes: string;
  category: IServerCategory;
  eurBalance: string;
}

export interface IServerCategory {
  disabled: boolean;
  id: string;
  name: string;
  icon: string;
  notes: string;
  timestamp: number;
  txFeeEUR: string;
  minFeeEUR: string;
  unloadFeeEUR: string;
}

export interface IServerDepositCreate {
  email: string;
  password: string;
  amount: string;
  depositMethod: string;
  sender: string;  // always pass `user.getUser().name`
  destination: string;
}

export interface ICryptoDepositPayload {
    account: string;
    currency: string;
}

export interface IServerCurrencyDepositMethod {
  [x: string]: IServerDepositMethod[];
}

export interface IServerDepositMethod {
  /**
   * @deprecated  use idStr
   **/
  id: string;
  idStr: string;
  destination: string;
  timestamp: string;
  disabled: boolean;
  name: string;
  icon: string;
  currency: string;
  notes: string;
  recipient: string
}

export enum HistoryType {
  Deposit = 1,
  Withdrawal = 2,
  Transfer = 3
}

export interface IServerDeposit {
  id: string;
  sender: string;
  destination: string;
  timestamp: string;
  account: string;
  amount: string;
  currency: string;
  depositMethod: string;
  status: number;
  ref: string;
  notes: string;
  userName: string;
  userEmail: string;
  historyType: HistoryType
}

export interface IPaytahPayout {
  account: string;
  amount: string;
  bic: string;
  city: string;
  country: string;
  currency: string;
  destinationCountry: string;
  detailsOfCharges: string;
  historyType: HistoryType;
  iban: string;
  id: string;
  name: string;
  notes: string;
  paymentAccount: string;
  reference: string;
  status: number;
  street: string;
  streetNo: string;
  timestamp: string;
  type: number;
  userEmail: string;
  userName: string;
  zipCode: string;
}

export interface IServerTransfer {
  id: string;
  type: number;
  timestamp: string;
  isFeeForTransaction: string;
  sender: string;
  recipient: string;
  currency: string;
  amount: string;
  senderName: string;
  senderEmail: string;
  recipientName: string;
  recipientEmail: string;
  message: string;
}
