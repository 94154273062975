import { Service, Inject } from '../core/decorators';
import { ServerService, IAsset } from './server';

const eurAsset: IAsset = {
  asset: "1246430022292240565",
  decimals: 2,
  name: "EUR",
  symbol: "EUR"
};

@Service('asset')
@Inject('$q','server')
export class AssetService {
  private readyPromise: angular.IPromise<IAsset[]>;
  private assets: IAsset[];

  constructor(private $q: angular.IQService,
              private server: ServerService) {
    let deferred = $q.defer();
    this.readyPromise = <angular.IPromise<IAsset[]>> deferred.promise;

    server.getAllAssets().then(assets => {
      const euroAssetFound = assets.find(asset => asset.asset === eurAsset.asset);
      if(!euroAssetFound) {
        assets.push(eurAsset);
      }

      this.assets = assets;
      deferred.resolve(assets);
    }, deferred.reject);
  }

  public ready() {
    return this.readyPromise;
  }

  public getAssets() {
    return this.assets;
  }

  public getAssetById(id: string) {
    return this.assets.find(asset => asset.asset === id);
  }

  public getAssetByName(name: string) {
    return this.assets.find(asset => asset.symbol.toLowerCase() === name.toLowerCase());
  }
}