import { Service } from '../core/decorators';
import { capitalizeAll } from '../lib/utils';

@Service('headerTitleService')
export class HeaderTitleService {
  private currentTitle: string;
  private subscriptions: any[] = [];

  constructor() {
  }

  public setTitle(item: any) {
    this.currentTitle = item;

    let title = capitalizeAll(item.title);
    if(item.value) {
      title += ` - ${capitalizeAll(item.value)}`;
    }
    title += ` - Monetum.io`;

    document.title = title;

    this.subscriptions.forEach((cb) => {
      cb(this.currentTitle);
    })
  }

  public subscribe(cb: any) {
    this.subscriptions.push(cb);
  }
}
