import { instance, createAssetTransfer, createMultiAssetTransfer } from './sdk'
import * as utils from './utils';
import * as ByteBuffer from "bytebuffer"
import * as Long from "long"
import * as _heatsdkType from '../../node_modules/heat-sdk/dist/heat-sdk'
declare var angular: angular.IAngularStatic;
declare var heatsdk: typeof _heatsdkType
import { createArbitraryMessage } from './sdk'
import { AtomicTransfer } from '../../node_modules/heat-sdk/dist/attachment';
import { material } from 'angular';

/**
 * Adds a description to each transfer, the description is used client side only
 * and only for display purposes to the user.
 */
export interface ExtendedAtomicTransfer extends AtomicTransfer {
  description: string
}

export class Protocol<T> {

  private avroType = null
  constructor(private namespace: string,
              private method: number,
              private schema: any) { }

  private getAvroType() {
    return this.avroType || (this.avroType = heatsdk.Type.forSchema(this.schema))
  }

  private confirm(quantity) {
    return new Promise((accept, reject) => {
      const dialog = <any>angular.element(document).injector().get('$mdDialog');

      // TODO: Asset hardcoded as EUR here.
      const confirm = dialog.confirm()
        .title('Money Transfer')
        .textContent(`Are you sure you want to transfer €${utils.formatMoney(quantity, 2)}?`)
        .ok('Submit')
        .cancel('Cancel');

      dialog.show(confirm).then(function() {
        accept('accept');
      }, function() {
        reject('reject');
      });
    })
  }

  // TODO: Asset hardcoded as EUR here.
  private confirm2(transfers: Array<ExtendedAtomicTransfer>) {
    return new Promise((accept, reject) => {
      const dialog = <any>angular.element(document).injector().get('$mdDialog');

      // The first transfer is the actual 'transfer' the other transfers are for fees.
      // We 'add up' all fees and display these separately, we also display a total
      let amount = parseInt(transfers[0].quantity)
      let fees = 0
      for (let i=1; i<transfers.length; i++)
        fees += parseInt(transfers[i].quantity)
      let total = amount + fees
      let amountFormatted = utils.formatMoney(amount+"", 2)
      let feesFormatted = utils.formatMoney(fees+"", 2)
      let totalFormatted = utils.formatMoney(total+"", 2)

      let html = `
        <p>Transfer €${amountFormatted}</p>
        <p>+ Fees €${feesFormatted}</p>
        <p>Total €${totalFormatted}</p>
      `      
      const confirm = dialog.confirm()
        .title('Money Transfer')
        .htmlContent(html)
        .ok('Submit')
        .cancel('Cancel');

      dialog.show(confirm).then(function() {
        accept('accept');
      }, function() {
        reject('reject');
      });
    })
  }  

  public toHex(params: T): string {
    let avroType = this.getAvroType()
    let buffer = ByteBuffer.allocate(1000).order(ByteBuffer.LITTLE_ENDIAN);
    let prefixBytes = [69, 76, 69, 65, 78, 79, 82, 65]
    prefixBytes.forEach(b => buffer.writeByte(b));
    let namespaceLong = Long.fromString(this.namespace, true)
    buffer.writeInt64(namespaceLong)
    buffer.writeByte(this.method)
    let paramBytes = Uint8Array.from(avroType.toBuffer(params))
    paramBytes.forEach(b => buffer.writeByte(b))
    buffer.flip()
    return buffer.toHex()
  }

  public createMessageToSelfTransaction(secretPhrase: string, params: T): Promise<string> {
    let publicKey = instance.crypto.secretPhraseToPublicKey(secretPhrase)
    let recipient = instance.crypto.getAccountIdFromPublicKey(publicKey)
    return createArbitraryMessage(secretPhrase, recipient, this.toHex(params))
  }

  public createMessageTransaction(secretPhrase: string, recipientOrRecipientPublickey: string, params: T): Promise<string> {
    return createArbitraryMessage(secretPhrase, recipientOrRecipientPublickey, this.toHex(params))
  }

  public createAssetTransferTransaction(secretPhrase: string, recipientOrRecipientPublickey: string, quantity: string, asset: string, params: T): Promise<string> {
    return new Promise((accept, reject) => {
      this.confirm(quantity).then(() => {
          createAssetTransfer(secretPhrase, recipientOrRecipientPublickey, quantity, asset, this.toHex(params))
            .then((result) => accept(result), reject)
        }, reject);
    })
  }

  public createMultiAssetTransferTransaction(secretPhrase: string, transfers: Array<ExtendedAtomicTransfer>, params: T): Promise<string> {
    return new Promise((accept, reject) => {
      this.confirm2(transfers).then(() => {
          let recipient = instance.crypto.getAccountId(secretPhrase)
          createMultiAssetTransfer(secretPhrase, recipient, transfers, this.toHex(params))
            .then((result) => accept(result), reject)
        }, reject);
    })
  }

}
