import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { HeaderTitleService } from '../../services/headerTitle';

@RouteConfig('/greet')
@Component({
  selector: 'greet',
  template: `
    <div layout="row" flex>
      <div class="container actions-wrapper" layout="column" flex>
        <div layout="row" flex class="actions">
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/deposit">
                <img alt="Add Funds" src="assets/funds.png" />
                <div class="title">Add Funds</div>
              </md-button>
            </div>
          </div>
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/payout">
                <img alt="Withdraw" src="assets/withdraw.png" />
                <div class="title">Withdraw</div>
              </md-button>
            </div>
          </div>
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/exchange/buy">
                <img alt="Buy / Sell" src="assets/buysell.png" />
                <div class="title">Buy / Sell</div>
              </md-button>
            </div>
          </div>
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/history">
                <img alt="Transaction History" src="assets/history.png" />
                <div class="title">History</div>
              </md-button>
            </div>
          </div>
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/settings">
                <img alt="Profile" src="assets/profile.png" />
                <div class="title">Profile</div>
              </md-button>
            </div>
          </div>
          <div class="button-container">
            <div class="circle-button">
              <md-button href="#/exchange/trade">
                <img alt="Trade" src="assets/trade.png" />
                <div class="title">Trade</div>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
@Inject('user','headerTitleService','$translate')
export class GreetComponent {

  constructor(private user: UserService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService) {
                if (!user.requireLogin())
                  return;

    this.headerTitleService.setTitle({
      title: this.$translate.instant('translate.toolbar.greet.label'),
      isMainMenu: true
    });
  }
}