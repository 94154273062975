declare var angular: angular.IAngularStatic;

export class SubscriberTopic {

  public listeners: Array<(any) => void> = [];
  private subscribed: boolean = false;

  constructor(public topicId: string, public params: any) {
    if (!angular.isString(topicId))
      throw new Error("Topic must be a string");
    if (!angular.isObject(params))
      throw new Error("Params must be an object");
    var names = Object.getOwnPropertyNames(params);
    names.forEach(key => {
      if (!angular.isString(params[key]))
        throw new Error(`Params property ${key} is not a string`);
    })
  }

  public setSubscribed(subscribed: boolean) {
    this.subscribed = subscribed;
  }

  public isSubscribed(): boolean {
    return this.subscribed;
  }

  public addListener(callback: (any) => void) {
    if (this.listeners.find(cb => cb === callback))
      throw new Error("Duplicate listener");
    this.listeners.push(callback);
  }

  public removeListener(callback: (any) => void) {
    this.listeners = this.listeners.filter(c => c !== callback);
  }

  public isEmpty(): boolean {
    return this.listeners.length == 0;
  }

  public equals(other: SubscriberTopic): boolean {
    if (this.topicId != other.topicId) return false;
    return this.objectEquals(this.params, other.params);
  }

  private objectEquals(a: Object, b: Object): boolean {
    let namesA = Object.getOwnPropertyNames(a);
    let namesB = Object.getOwnPropertyNames(b);
    if (namesA.length != namesB.length) return false;
    for (var i = 0; i < namesA.length; i++) {
      let key = namesA[i];
      if (a[key] != b[key]) return false;
    }
    return true;
  }
}