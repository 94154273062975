export const startCamera = (elementId: string) => {
    const camera = document.getElementById(elementId) as HTMLVideoElement;
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
            camera.srcObject = stream;
        });
    }
};

export const stopCamera = (elementId: string) => {
    const camera = document.getElementById(elementId) as HTMLVideoElement;
    const stream = camera.srcObject as MediaStream;
    const track = stream.getTracks()[0];
    track.stop();
};

export const getImageFromCamera = (elementId: string, callback?: any): string => {
    const camera = document.getElementById(elementId) as HTMLVideoElement; 
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.height = camera.videoHeight;
    canvas.width = camera.videoWidth;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(camera, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(callback);
    return canvas.toDataURL();
};