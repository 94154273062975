import { Protocol } from "./protocol-class";

export const SourceOfFunds = [
  'STUDENT',
  'UNEMPLOYED',
  'RETIRED',
  'EMPLOYED',
  'SELF-EMPLOYED',
  'BUSINESS OWNER'
];

export const SourceOfWealth = [
  'SAVINGS',
  'REAL ESTATE',
  'INVESTMENTS',
  'INHERITANCE'
];

export const Occupations = [
  'STUDENT',
  'RETIRED',
  'EMPLOYED',
  'SELF-EMPLOYED'
];

export var ClientType = {
  BANK: 1,
  LEGAL: 2,
  NATURAL: 4,
  SYSTEM: 8
}

export var ClientState = {
  ACTIVE:1,
  DELETED:2,
  DISABLED:4  
}

export var AddressType = {
  BILLING: 1,
  POST: 2
}

export var SignupState = {
  PENDING:1,
  ACCEPTED:2,
  REJECTED:3
}

export var ClientGender = {
  MALE: 1,
  FEMALE: 2,
  NULL: 3
}

export var DepositStatus = {
  PENDING: 0,
  COMPLETED: 1,
  CANCELLED: 2
}

export var PayoutType = {
  SEPA: 0,
  INTERNATIONAL: 1
}

export var PayoutStatus = {
  PENDING: 0,
  COMPLETED: 1,
  CANCELLED: 2,
  FROZEN: 3
}

export interface UpdateAddressParams {
  name: string
  attention: string
  city: string
  countryCode: string
  postalBox: string
  street: string
  streetNo: string
}
export var UpdateAddress = new Protocol<UpdateAddressParams>("5", 1, {
  "name": "UserUpdateAddressParams",
  "type": "record",
  "fields": [
    { "default": null, "name": "name", "type": ["null","string"] },
    { "default": null, "name": "attention", "type": ["null","string"] },
    { "default": null, "name": "city", "type": ["null","string"] },
    { "default": null, "name": "countryCode", "type": ["null","string"] },
    { "default": null, "name": "postalBox", "type": ["null","string"] },
    { "default": null, "name": "street", "type": ["null","string"] },
    { "default": null, "name": "streetNo", "type": ["null","string"] },
    { "default": null, "name": "zipCode", "type": ["null","string"] },
    { "default": null, "name": "billingEmail", "type": ["null","string"] },
    { "name": "addressType", "type": { "java-class": "java.lang.Byte", "type": "int" } }
  ]
})

export interface ThreadStartParams {
  topic: string
  contents: string
}
export var ThreadStart = new Protocol<ThreadStartParams>("7", 1, {
  "name":"ThreadStartParams",
  "type":"record",
  "fields":[
    { "default":null, "name":"topic", "type":["null","string"] },
    { "default":null, "name":"contents", "type":["null","string"] }
  ]
})

export interface ThreadReplyParams {
  replyTo: Long
  contents: string
} 
export var ThreadReply = new Protocol<ThreadReplyParams>("7", 2, {
  "name":"ThreadReplyParams",
  "type":"record",
  "fields":[
    { "name":"replyTo", "type":"long" },
    { "default":null, "name":"contents", "type":["null","string"] }
  ]
})

export interface UpdateReadParams {
  id: Long
  value: boolean
}
export var UpdateRead = new Protocol<UpdateReadParams>("7", 3, {
  "name":"UpdateReadParams",
  "type":"record",
  "fields":[
    { "name":"id", "type":"long" },
    { "name":"value", "type":"boolean" }
  ]
})

export interface UpdateStarredParams {
  id: Long
  value: boolean
}
export var UpdateStarred = new Protocol<UpdateStarredParams>("7", 4, {
  "name":"UpdateStarredParams",
  "type":"record",
  "fields":[
    { "name":"id", "type":"long" },
    { "name":"value", "type":"boolean" }
  ]
})

export interface UpdateTrashedParams {
  id: Long
  value: boolean
}
export var UpdateTrashed = new Protocol<UpdateTrashedParams>("7", 5, {
  "name":"UpdateTrashedParams",
  "type":"record",
  "fields":[
    { "name":"id", "type":"long" },
    { "name":"value", "type":"boolean" }
  ]
})

export interface TransferParams {
  message: string
}
export var Transfer = new Protocol<TransferParams>("8", 1, {
  "name": "TransferParams",
  "type": "record",
  "fields": [
    { "default": null, "name": "message", "type": ["null","string"] }
  ]
})

export interface DepositCreateParams {
  amount: Long
  currency: Long
  depositMethod: string
  ref: string
  refBase: number
}
export var DepositCreate = new Protocol<DepositCreateParams>("3",1,{
  "name": "CreateDepositParams",
  "type": "record",
  "fields": [
    { "name": "amount", "type": "long" },
    { "name": "currency", "type": "long" },
    { "default": null, "name": "depositMethod", "type": ["null","string"]},
    { "default": null, "name": "ref", "type": ["null","string" ] },
    { "name": "refBase", "type": "int" }
  ]
})

export interface DepositUpdateStatusParams {
  id: Long
  newStatus: number
}
export var DepositUpdateStatus = new Protocol<DepositUpdateStatusParams>("3",2,{
  "name": "UpdateStatusParams",
  "type": "record",
  "fields": [
    { "name": "id","type": "long" },
    { "name": "newStatus", "type": {"java-class": "java.lang.Byte","type": "int" } }
  ]
})

export interface PayoutCreateParams {
  payoutMethod: string
  speed: number
  receiver: string
  destination: string
  extra: string
}
export var PayoutCreate = new Protocol<PayoutCreateParams>("9",1,{
  "name": "CreatePayoutParams",
  "type": "record",
  "fields": [
    { "default": null,"name": "payoutMethod","type": ["null","string"] },
    { "name": "speed", "type": {"java-class": "java.lang.Byte","type": "int" } },
    { "default": null, "name": "receiver", "type": ["null","string"] },
    { "default": null, "name": "destination", "type": ["null","string" ] },
    { "default": null, "name": "extra", "type": ["null", "string"] }
  ]
})

export interface CreateBankPayoutParams {
  type: number
  paymentAccount: Long
  amount: Long
  name: string
  street: string
  streetNo: string
  zipCode: string
  city: string
  country: string
  iban: string
  bic: string
  reference: string
  destinationCountry: string
  detailsOfCharges: string
}
export var CreateBankPayout = new Protocol<CreateBankPayoutParams>("9",3,{
  "name": "CreateBankPayoutParams",
  "type": "record",
  "fields": [
    { "name": "type", "type": {"java-class": "java.lang.Byte","type": "int" } },
    { "name": "paymentAccount", "type": "long" },
    { "name": "amount", "type": "long" },
    { "default": null, "name": "name", "type": ["null","string"] },
    { "default": null, "name": "street", "type": ["null","string"] },
    { "default": null, "name": "streetNo", "type": ["null","string"] },
    { "default": null, "name": "zipCode", "type": ["null","string"] },
    { "default": null, "name": "city", "type": ["null","string"] },
    { "default": null, "name": "country", "type": ["null","string"] },
    { "default": null, "name": "iban", "type": ["null","string" ] },
    { "default": null, "name": "bic", "type": ["null","string"] },
    { "default": null, "name": "reference", "type": ["null","string"] },
    { "default": null, "name": "destinationCountry", "type": ["null","string"] },
    { "default": null, "name": "detailsOfCharges", "type": ["null", "string" ] }
  ]
})

export interface UserUpdateSettingsParams {
  firstName: string
  middleName: string
  lastName: string
  email: string
  dateOfBirth: Long
  taxCountry: string
  taxIdNum: string
  gender: number
  streetName: string
  streetNo: string
  city: string
  zipCode: string
  country: string
  phoneNumber: string
}
export var UserUpdateSettings = new Protocol<UserUpdateSettingsParams>("1",9,{
  "name": "UserUpdateSettingsParams",
  "type": "record",
  "fields": [
    { "default": null,"name": "firstName","type": ["null","string"] },
    { "default": null,"name": "middleName","type": ["null","string"] },
    { "default": null,"name": "lastName","type": ["null","string"] },
    { "default": null,"name": "email","type": ["null","string"] },
    { "name": "dateOfBirth","type": "long" },
    { "default": null,"name": "taxCountry","type": ["null","string"] },
    { "default": null,"name": "taxIdNum","type": ["null","string"] },
    { "name": "gender", "type": {"java-class": "java.lang.Byte","type": "int" } },
    { "default": null, "name": "streetName", "type": ["null","string"] },
    { "default": null,"name": "streetNo","type": ["null","string"] },
    { "default": null, "name": "city","type": ["null","string"] },
    { "default": null, "name": "zipCode", "type": ["null","string"] },
    { "default": null,"name": "country", "type": ["null","string"] },
    { "default": null, "name": "phoneNumber", "type": ["null","string"] }
  ]
})
