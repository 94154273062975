declare var dateFormat: any;
declare var Big: BigJsLibrary.BigJS;
let dateformat = 'dd-mm-yyyy HH:MM:ss';
let timeformat = 'HH:MM:ss';

export function timestampToDate(timestamp: number) {
  return new Date(Date.UTC(2013, 10, 24, 12, 0, 0, 0) + timestamp * 1000);
}

export function formatDate(date: Date, format?: string) {
  return dateFormat(date, format || dateformat);
}

export function formatMoney(quantity: string, decimals: number, asNumber?: boolean) {
  let num = new Big(quantity);
  let asNum = parseFloat(num.div(new Big(Math.pow(10, decimals))).toString());

  if(asNumber)
    return asNum.toString();

  return asNum.toLocaleString(undefined, { minimumFractionDigits: decimals });
}

export function convertMoney(numeric: string, decimals: number) {

}

export function debounce(func: Function, wait?: number, immediate?: boolean) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait||100);
    if (callNow) func.apply(context, args);
  }
}

export function parseFailedReturnMessage(response: any) {
  let errorMessage = 'FAILED';
  if (response && response.data && response.data.data && response.data.data.errorDescription) {
    let s = response.data.data.errorDescription;
    let description = s[0].toUpperCase() + s.slice(1);
    errorMessage = `${errorMessage}: ${description}`;
  }
  return errorMessage; 
}

const EVERY_FOUR_CHARS =/(.{4})(?!$)/g;
export function formatIBAN(iban:string, separator?:string) {
  if (typeof separator == 'undefined'){
    separator = ' ';
  }  
  return iban.toUpperCase().replace(EVERY_FOUR_CHARS, "$1" + separator);
}

export function getUniqueRandomNumbers(min: number, max: number, amount: number, sort?: boolean): number[] {
  const n = [];
  for(let i=0; i < amount; i++) {
    const randomNumber = Math.floor(Math.random() * max) + min;

    if(n.indexOf(randomNumber) == -1) {
      n.push(randomNumber);
    }
    else {
      i = i -1;
    }
  }

  if(sort) {
    return n.sort((a, b) => a - b);
  }

  return n;
}

export const capitalizeAll = (sentence: string): string => {
  return sentence.replace(/\b\w/g, l => l.toUpperCase());
}

export function roundTo(n: number, digits: number, floor?: boolean) {
  let negative = false;

  if (digits === undefined) {
      digits = 0;
  }

  if(n < 0) {
    negative = true;
    n = n * -1;
  }

  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  let nS = (Math[floor ? 'floor' : 'round'](n) / multiplicator).toFixed(2);

  if( negative ) {    
    nS = (+nS * -1).toFixed(2);
  }

  n = +nS;

  return n;
}

export function validatePassword(password) {
  var minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

  if (minMaxLength.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      number.test(password) &&
      special.test(password)
  ) {
      return true;
  }

  return false;
}