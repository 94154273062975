import {Inject, Service} from '../core/decorators';
import {KeysService} from './key-service';
import {instance} from '../lib/sdk';

declare var angular: angular.IAngularStatic;

/* Wraps all API returned server errors */
export class ServerEngineError {
  public description: string;
  public code: number;
  constructor(public response: any) {
    if (angular.isObject(response)) {
      if (angular.isObject(response.data) && response.data['errorDescription']) {
        this.description = response.data['errorDescription']
        this.code = response.data['errorCode'] || -1;
      }
      else if (response.error) {
        this.description = response.error;
        this.code = 99;
      }
    } else {
      this.description = 'misc error';
      this.code = 99;
    }
  }
}

export class InternalServerTimeoutError extends ServerEngineError {
  constructor() {
    super({ error: 'Internal timeout' });
  }
}

@Service('http')
@Inject('$q','$http')
export class HttpService {

  private keys: KeysService = null

  constructor(private $q: angular.IQService,
              private $http: angular.IHttpService) {}

  private getAuthData(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.keys) {
        /* Circular dependency found: user <- keys <- http <- server <- user */
        this.keys = <any>angular.element(document).injector().get('keys');
      }
      this.keys.getKey().then(key => {
        let publicKey = instance.crypto.secretPhraseToPublicKey(key)
        var timestamp = Date.now();
        var baseMessage = publicKey + timestamp;
        var message = instance.converters.stringToHexString(baseMessage);
        var secret = instance.converters.stringToHexString(key)
        var signature = instance.crypto.signBytes(message, secret);
        resolve(`${timestamp}|${publicKey}|${signature}`)
      },
      () => {
        /* Not having a key returns null */
        resolve(null)
      })
    })
  }

  public get(host: string, port: string, route:string, returns?: string): angular.IPromise<{}> {
    let deferred = this.$q.defer();
    this.getAuthData().then(
      token => {
        this.$http.get(this.createURL(host, port, route), {
          headers: Object.assign(
            {'Content-Type': 'application/text'},
            token ? {'xPaytah-Auth-Token': token}: {}
          )
        }).then(
          (response: any) => {
            if (angular.isDefined(response.data.errorDescription)) {
              this.logErrorResponse(route, null, response);
              deferred.reject(new ServerEngineError(response));
            }
            else if (angular.isDefined(returns)) {
              this.logResponse(route, null, response);
              deferred.resolve(response.data[returns]);
            }
            else {
              this.logResponse(route, null, response);
              deferred.resolve(response.data)
            }
          },
          (response) => {
            this.logErrorResponse(route, null, response);
            deferred.reject(new ServerEngineError(response))
          }
        );
      }, deferred.reject
    )
    return <angular.IPromise<{}>> deferred.promise;
  }

  public post(host: string, port: string, route:string, request: any, returns?: string, fileUpload?: boolean): angular.IPromise<{}> {
    let deferred = this.$q.defer();
    let contentType: string = fileUpload ? undefined : 'application/x-www-form-urlencoded';
    this.getAuthData().then(
      token => {
        this.$http({
          method: 'POST',
          url: this.createURL(host, port, route),
          headers: Object.assign(
            {"Content-Type": contentType},
            token ? {'xPaytah-Auth-Token': token}: {}),
          transformRequest: fileUpload
            ? undefined
            : function (obj) {
              var str = [];
              for (var p in obj)
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
            },
          data: request
        }).then(
          (response: any) => {
            if (angular.isDefined(response.data.errorDescription)) {
              this.logErrorResponse(route, request, response);
              deferred.reject(new ServerEngineError(response));
            }
            else if (angular.isDefined(returns)) {
              this.logResponse(route, request, response);
              deferred.resolve(response.data[returns]);
            }
            else {
              this.logResponse(route, request, response);
              deferred.resolve(response.data)
            }
          },
          (response) => {
            this.logErrorResponse(route, request, response);
            deferred.reject(new ServerEngineError(response))
          }
        );
      }, deferred.reject
    )
    return <angular.IPromise<{}>> deferred.promise;
  }

  private createURL(host:string, port:string, route:string) {
    return (port ? `${host}:${port}` : host) + route;
  }

  private logResponse(route: string, request: any, response: any) {
    console.log(`HTTP [${route}]`, {
      request: request,
      response: response
    })
  }

  private logErrorResponse(route: string, request: any, response: any) {
    console.error(`HTTP [${route}]`, {
      request: request,
      response: response
    })
  }
}
