import * as numeral from 'numeral';
import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { ServerService, IPaytahPayoutMethod, IPaytahClient, IBeneficiary } from '../../services/server';
import { CountriesService } from '../../services/countries';
import { HeaderTitleService } from '../../services/headerTitle';
import * as utils from '../../lib/utils';
import { KeysService } from '../../services/key-service';
import { ErrorsService } from '../../services/errors';
import { PayoutMethodService } from '../../services/payout-method';
import { PayoutType, CreateBankPayout, CreateBankPayoutParams } from '../../lib/protocol';
import * as Long from 'long';
import { currencies, ICurrency } from '../../services/settings';
import { roundTo } from '../../lib/utils';
import { BeneficiaryService } from '../../services/beneficiary';

interface Sepa {
  amount: string;
  name: string;
  street: string;
  streetNo: string;
  zipCode: string;
  city: string;
  country: string;
  iban: string;
  bic: string;
  reference: string;
}

interface International {
  amount: string;
  name: string;
  street: string;
  streetNo: string;
  zipCode: string;
  city: string;
  country: string;
  iban: string;
  bic: string;
  destinationCountry: string;
  detailsOfCharges: string;
  reference: string;
}

@RouteConfig('/payout', '/payout/:currency', '/payout/:currency/:method')
@Component({
  selector: 'payout',
  inputs: ['currency', 'method'],
  template: `
    <div layout="column" flex>
      <div class="wrapper">
        <div layout="row" class="currencies">
          <div class="currency" ng-repeat="currency in vm.currencies" ng-click="vm.selectCurrency(currency)">
            <img ng-if="!vm.selectedCurrency || vm.selectedCurrency.name !== currency.name" ng-src="assets/{{currency.image}}" alt="{{currency.name}}" />
            <img ng-if="vm.selectedCurrency && vm.selectedCurrency.name === currency.name" ng-src="assets/{{currency.imageHover}}" alt="{{currency.name}}" />
          </div>
        </div>
        <div layout="row" class="selected-currency" ng-if="vm.hasSelectedCurrency">
          <div layout="column" class="selection-wrapper">
            <div layout="column" class="selection-information">
              <div class="currency-logo" ng-if="vm.selectedCurrency.bigImage">
                <img ng-src="assets/{{vm.selectedCurrency.bigImage}}" alt="{{vm.selectedCurrency.name}}" />
              </div>
              <div class="currency-logo" ng-if="!vm.selectedCurrency.bigImage">
                <img ng-src="assets/{{vm.selectedCurrency.image}}" alt="{{vm.selectedCurrency.name}}" />
              </div>
              <div class="amount-description">
                Amount
              </div>            
              <div class="withdraw-input" layout="row">
                <div class="withdraw-symbol">
                  {{vm.selectedCurrency.symbol}}
                </div>
                <input ng-model="vm.amount" ng-change="vm.amountChange()" required flex min="0" fraction="2" placeholder="0.00">
              </div>
              <div class="currency-balance">
                <span>Balance in {{vm.selectedCurrency.currency}}:</span> {{vm.selectedCurrency.symbol}}{{vm.user.getCurrencyBalance(vm.selectedCurrency.currency)}}
              </div>
            </div>
            <div class="withdraw-setup" layout="column">
              <md-progress-linear ng-if="vm.loading" md-mode="indeterminate"></md-progress-linear>
              <div layout="column" ng-if="!vm.loading">
                <div ng-if="!vm.paymentAccountId || !vm.isVerified" class="unverified">
                  <p>Withdrawals are not available for unverified accounts</p>
                </div>
                <div layout="column" class="withdraw-methods" ng-if="vm.paymentAccountId && vm.isVerified">
                  <div layout="column" class="selected-method">
                    <div layout="row" class="selected-method-information">
                      <div class="selected-method-image">
                        <img ng-src="{{vm.selectedMethod.icon}}" alt="{{vm.selectedMethod.name}}" />
                      </div>
                      <div class="dropdown-action" ng-click="vm.showMethods()">
                        <img src="assets/dropdown.png" />
                      </div>
                    </div>
                  </div>
                  <div layout="column" class="withdraw-method-wrapper" ng-show="vm.canSelectMethod">
                    <div layout="column" ng-repeat="item in vm.payoutMethods" ng-click="vm.setPayoutMethod(item.id)" class="withdraw-method">
                      <div>
                        <img ng-src="{{item.icon}}">
                        {{item.name}}
                      </div>
                    </div>
                  </div>
                </div>
                <div layout="column" ng-if="vm.chosenSelectedMethod && vm.paymentAccountId && vm.isVerified">
                  <div layout="column" class="fee">
                    <div translate="translate.payout.fee" translate-values="{fixedFee:vm.fixedFeeFormatted,unloadFee:vm.feeFormatted}"></div>
                  </div>
                  <div layout="column" flex ng-if="vm.selectedMethod.id===vm.SEPA_METHOD">
                    <form name="ibanForm" layout="column" flex>
                      <div class="container" layout="column" flex>
                        <div layout="row">
                          <span>Maximum: €{{vm.maximumAmountFormatted}}</span>
                        </div>
                        <div layout="row">
                          <span>Payout Amount: €{{vm.payoutAmount}}</span>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Beneficiary</label>
                            <md-select ng-model="vm.selectedBeneficiary" name="beneficiary" ng-change="vm.onBeneficiaryChange()" required flex>
                              <md-option ng-repeat="item in vm.beneficiary.getBeneficiaries()" ng-value="item">{{ item.name }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <h4>Receiver Info</h4>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Name</label>
                            <input ng-model="vm.iban.name" name="name" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Street</label>
                            <input ng-model="vm.iban.street" name="street" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Street No</label>
                            <input ng-model="vm.iban.streetNo" name="streetNo" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Zip Code</label>
                            <input ng-model="vm.iban.zipCode" name="zipCode" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>City</label>
                            <input ng-model="vm.iban.city" name="city" required>
                          </md-input-container>
                        </div>
                        <div layout="row" class="extra-space">
                          <md-input-container flex>
                            <label>Country</label>
                            <md-select ng-model="vm.iban.country" name="country" required flex>
                              <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <h4>IBAN</h4>
                        <div layout="row">
                          <md-input-container flex>
                            <label>IBAN</label>
                            <input ng-model="vm.iban.iban" name="iban" required ng-change="vm.ibanChanged(vm.iban, ibanForm.iban)">
                            <span class="invalid" ng-show="ibanForm.iban.$touched && !ibanForm.iban.$valid">Please enter a valid IBAN</span>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>BIC</label>
                            <input ng-model="vm.iban.bic" name="bic" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Reference</label>
                            <input ng-model="vm.iban.reference" name="reference" required>
                          </md-input-container>
                        </div>
                        <submit-and-back
                          disable="!ibanForm.$valid || !vm.canWithdraw"
                          submit="vm.submit($event)"
                          label="'Withdraw ' + vm.selectedCurrency.symbol"
                          image="'assets/withdrawal-active.png'"
                          imageHover="'assets/withdrawal-hover.png'"
                        >
                        </submit-and-back>
                      </div>
                    </form>
                  </div>
                  <div layout="column" flex ng-if="vm.selectedMethod.id===vm.INTERNATIONAL_METHOD">
                    <form name="internationalForm" layout="column" flex>
                      <div class="container" layout="column" flex>
                        <div layout="row">
                          <span>Maximum: €{{vm.maximumAmountFormatted}}</span>
                        </div>
                        <div layout="row">
                          <span>Minimum: €{{vm.minimumAmountFormatted}}</span>
                        </div>
                        <div layout="row">
                          <span>Payout Amount: €{{vm.payoutAmount}}</span>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Beneficiary</label>
                            <md-select ng-model="vm.selectedBeneficiary" name="beneficiary" ng-change="vm.onBeneficiaryChange()" required flex>
                              <md-option ng-repeat="item in vm.beneficiary.getBeneficiaries()" ng-value="item">{{ item.name }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <h4>Receiver Info</h4>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Name</label>
                            <input ng-model="vm.international.name" name="name" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Street</label>
                            <input ng-model="vm.international.street" name="street" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Street No</label>
                            <input ng-model="vm.international.streetNo" name="streetNo" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Zip Code</label>
                            <input ng-model="vm.international.zipCode" name="zipCode" required>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>City</label>
                            <input ng-model="vm.international.city" name="city" required>
                          </md-input-container>
                        </div>
                        <div layout="row" class="extra-space">
                          <md-input-container flex>
                            <label>Country</label>
                            <md-select ng-model="vm.international.country" name="country" required flex>
                              <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <h4>International</h4>
                        <div layout="row">
                          <md-input-container flex>
                            <label>IBAN</label>
                            <input ng-model="vm.international.iban" name="iban" required ng-change="vm.ibanChanged(vm.international, internationalForm.iban)">
                            <span class="invalid" ng-show="internationalForm.iban.$touched && !internationalForm.iban.$valid">Please enter a valid IBAN</span>                  
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>SWIFT</label>
                            <input ng-model="vm.international.bic" name="bic" required>
                          </md-input-container>
                        </div>
                        <div layout="row" class="extra-space">
                          <md-input-container flex>
                            <label>Destination country of Transfer</label>
                            <md-select ng-model="vm.international.destinationCountry" name="destinationCountry" flex>
                              <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <div layout="row" class="extra-space">
                          <md-input-container flex>
                            <label>Details of Charges</label>
                            <md-select ng-model="vm.international.detailsOfCharges" name="detailsOfCharges" ng-change="vm.updateDetailsOfCharges()" required flex>
                              <md-option ng-repeat="item in vm.detailsOfCharges" ng-value="item">{{ item }}</md-option>
                            </md-select>
                          </md-input-container>
                        </div>
                        <div layout="row">
                          <md-input-container flex>
                            <label>Reference</label>
                            <input ng-model="vm.international.reference" name="reference" required>
                          </md-input-container>
                        </div>              
                        <submit-and-back
                          disable="!internationalForm.$valid || !vm.canWithdraw"
                          submit="vm.submit($event)"
                          label="'Withdraw ' + vm.selectedCurrency.symbol"
                          image="'assets/withdrawal-active.png'"
                          imageHover="'assets/withdrawal-hover.png'"
                        >
                        </submit-and-back>
                      </div>
                    </form>
                  </div>
                </div>
                <span ng-show="vm.errorMessage">{{vm.errorMessage}}</span>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
@Inject('$scope','user','$location','server','$mdToast','headerTitleService','$translate','keys','errors','payoutMethod','countries','beneficiary')
export class PayoutComponent {
  withdrawing: boolean;
  cancelling: boolean;
  currency: string;
  method:string;
  payoutMethods: Array<IPaytahPayoutMethod>;
  selectedMethod: IPaytahPayoutMethod;
  chosenSelectedMethod: boolean;
  feeFormatted = '10.00';
  fixedFeeFormatted = '0';
  international: International = <any>{};
  iban: Sepa = <any>{};
  amount: string;
  detailsOfCharges: string[] = [
    'SHA',
    'OUR',
    'BEN'
  ];
  client: IPaytahClient
  paymentAccountId: string = "0"
  isVerified: boolean
  currencies: ICurrency[];
  SEPA_METHOD = 'com.payout_method.iban'
  INTERNATIONAL_METHOD = 'com.payout_method.international';
  selectedCurrency: ICurrency;
  hasSelectedCurrency: boolean;
  loading: boolean = true;
  canSelectMethod: boolean;
  selectedBeneficiary: IBeneficiary;
  minimumAmountFormatted: string = '0.00';
  maximumAmountFormatted: string = '0.00';
  payoutAmount: string = '0.00';
  canWithdraw: boolean;
  isCrypto: boolean;
  constructor(private $scope: angular.IScope,
              private user: UserService,
              private $location: angular.ILocationService,
              private server: ServerService,
              private $mdToast: angular.material.IToastService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService,
              private keys: KeysService,
              private errors: ErrorsService,
              private payoutMethod: PayoutMethodService,
              public countries: CountriesService,
              private beneficiary: BeneficiaryService) {
                if (!user.requireLogin())
                  return;

                this.canSelectMethod = false;
                this.withdrawing= false;
                this.chosenSelectedMethod = false;
                this.hasSelectedCurrency = false;
                this.currencies = currencies.filter(currency => {
                  return !currency.disabled;
                });
                this.updateHeaderTitle();
                if(this.currency) {
                  this.selectCurrency(this.currencies.find(currency => currency.currency.toLowerCase() == this.currency.toLowerCase()), this.method);
                }

                user.refresh().then(() => {
                  $scope.$evalAsync(() => {
                    this.client = user.getClient();
                    if (this.client) {
                      if (this.selectedMethod)
                        this.fixedFeeFormatted = utils.formatMoney(String(this.selectedMethod.fee), 2);
                      this.feeFormatted = utils.formatMoney((parseFloat(this.client.category.unloadFeeEUR) / 10) + '', 2);
                      this.paymentAccountId = this.client.paymentAccounts[0] ? this.client.paymentAccounts[0].onpexPaymentAccount : "0"
                      this.isVerified = this.client.category.id.endsWith('.verified');
                      
                      if(!this.isCrypto) {
                        this.international.detailsOfCharges = 'SHA'
                        this.updateDetailsOfCharges();
                      }
                    }
                  })
                });
  }  

  updateHeaderTitle() {
    const title = {
      title: this.$translate.instant('translate.toolbar.payout.label')
    };

    if(this.currency) {
      title['value'] = this.currency.toUpperCase();
    }

    this.headerTitleService.setTitle(title);
  }

  onBeneficiaryChange() {
    const selectedBeneficiary: IBeneficiary = this.selectedBeneficiary;

    for(const key in selectedBeneficiary) {
      let value = selectedBeneficiary[key];

      if(key === 'amount') {
        value = utils.formatMoney(value, 2, true);
        this.amount = value;
      }
      else {
        if (this.selectedMethod.id === this.SEPA_METHOD) {
          this.iban[key] = value;
        }
        else {
          this.international[key] = value;
        }
      }
    }

    this.amountChange();
  }

  getAmount(amount: number, getMaximum?: boolean) {
    const fixedFee = parseFloat(this.fixedFeeFormatted);
    const unloadFee = parseFloat(this.feeFormatted);
    let roundedAmount;

    if(getMaximum) {
      roundedAmount = roundTo(amount - (amount * (unloadFee / 100)) - fixedFee, 2, true).toString();
    }
    else {
      roundedAmount = roundTo(amount + (amount * (unloadFee / 100)) + fixedFee, 2, true).toString();
    }

    return numeral(roundedAmount).format('0,0.00');
  }

  updateDetailsOfCharges() {
    this.$scope.$evalAsync(()=>{
      let natural = this.client.category.id.indexOf('category.user') != -1 ? true : false
      if (this.international.detailsOfCharges == 'OUR') {
        this.international['minAmount'] = natural ? 25 : 30
      }
      else if (this.international.detailsOfCharges == 'BEN') {
        this.international['minAmount'] = natural ? 15 : 10
      }
      else if (this.international.detailsOfCharges == 'SHA') {
        this.international['minAmount'] = natural ? 15 : 10
      }
      this.minimumAmountFormatted = utils.formatMoney((this.international['minAmount']*100)+'', 2);

      const currentBalance = parseFloat(this.user.getBalance());

      this.canWithdraw = currentBalance > 0;

      if(this.canWithdraw) {
        this.maximumAmountFormatted = this.getAmount(currentBalance, true);
      }
      else {
        this.maximumAmountFormatted = '0.00';
      }
    })
  }

  amountChange() {
    const maximumAmount = parseFloat(this.maximumAmountFormatted);
    const currentAmount = parseFloat(this.amount);
    this.canWithdraw = !isNaN(currentAmount) && maximumAmount > 0 && currentAmount <= maximumAmount;
    this.payoutAmount = !isNaN(currentAmount) ? this.getAmount(currentAmount) : '0.00';
  }

  onCurrencyChange(method?: string) {
    this.loading = true;
    this.chosenSelectedMethod = false;
    this.selectedMethod = null;
    this.amount = '';
    this.payoutMethod.ready().then(
      () => {
        this.$scope.$evalAsync(()=>{
          this.payoutMethods = this.payoutMethod.getPayoutMethods();
  
          if(method) {
            this.setPayoutMethod(method);
          }
          else if(this.payoutMethods.length) {
            this.setPayoutMethod(this.payoutMethods[0].id);
          }
        });
      }
    )
  }

  showMethods() {
    this.canSelectMethod = !this.canSelectMethod;
  }

  setPayoutMethod(method: string) {
    const methodToUse = method.toLowerCase();

    if(!this.method || (this.method.toLowerCase() !== methodToUse)) {
      this.$location.path(`/payout/${this.currency.toLowerCase()}/${methodToUse}`);
      return;
    }

    const newMethod = this.payoutMethod.getPayoutMethods().find(m=>m.id==this.method);

    if(this.selectedMethod != newMethod) {
      this.selectedMethod = newMethod;
      this.chosenSelectedMethod = true;
      this.loading = false;
    }
    this.canSelectMethod = false;
  }

  selectCurrency(currency: ICurrency, method?: string) {
    this.loading = true;
    const currencyToWithdraw = currency.currency.toLowerCase();

    if(!this.currency || (this.currency.toLowerCase() !== currencyToWithdraw)) {
      this.$location.path(`/payout/${currencyToWithdraw}`);
      return;
    }

    if(this.selectedCurrency != currency) {
      this.selectedCurrency = currency;
      this.isCrypto = currency.crypto;
      this.hasSelectedCurrency = true;
      this.onCurrencyChange(method);
    }
  }

  submit() {
    this.keys.getKey().then(
      key => {
        let amount: string
        let params: CreateBankPayoutParams
        if (this.selectedMethod.id === this.SEPA_METHOD) {
          amount = String(Math.round(parseFloat(this.amount)*100));          
          params = {
            type: PayoutType.SEPA,
            paymentAccount: Long.fromString(this.paymentAccountId),
            amount: Long.fromString(amount),
            name: this.iban.name,
            street: this.iban.street,
            streetNo: this.iban.streetNo,
            zipCode: this.iban.zipCode,
            city: this.iban.city,
            country: this.iban.country,
            iban: this.iban.iban.replace(/\s/g,''),
            bic: this.iban.bic,
            reference: this.iban.reference,
            destinationCountry: null,
            detailsOfCharges: null
          }
        } 
        else {
          amount = String(Math.round(parseFloat(this.amount)*100));
          params = {
            type: PayoutType.INTERNATIONAL,
            paymentAccount: Long.fromString(this.paymentAccountId),
            amount: Long.fromString(amount),
            name: this.international.name,
            street: this.international.street,
            streetNo: this.international.streetNo,
            zipCode: this.international.zipCode,
            city: this.international.city,
            country: this.international.country,
            iban:  this.international.iban.replace(/\s/g,''),
            bic: this.international.bic,
            reference: this.international.reference,
            destinationCountry: this.international.destinationCountry,
            detailsOfCharges: this.international.detailsOfCharges
          }
        }

        CreateBankPayout.createAssetTransferTransaction(key, 
          this.selectedMethod.recipient, 
          amount, 
          this.selectedMethod.currency, 
          params).then(
          txBytes => {
            this.server.broadcast(txBytes).then(
              response => {
                if (response.success) {
                  this.$mdToast.show(this.$mdToast.simple().textContent("SUCCESS").hideDelay(5000));
                  setTimeout(() => window.history.back(), 2000)                  
                }
                else {
                  this.errors.error(response)
                }
              },
              this.errors.error
            )
          },
          this.errors.error
        )
      },
      this.errors.error
    )
  }

  /* Pass the parent object, so thats vm.iban or vm.international */
  ibanChanged(parent:any, formField) {
    let iban = parent.iban.replace(/\s/g,'')
    this.server.validateIBAN(iban).then(response=> {
      this.$scope.$evalAsync(() => {
        formField.$setValidity("iban", response.valid=='true');
        if (response.bic) {
          parent.bic = response.bic
        }
      })
    })
  }
}