import { RouteConfig, Component, Inject} from '../../core/decorators';
import { CountriesService } from '../../services/countries';
import { ServerService } from '../../services/server';
import { ClientType } from '../../lib/protocol';
import { ErrorsService } from '../../services/errors';
import { ServerEngineError } from '../../services/http';
import { KeysService } from '../../services/key-service';
import { instance } from '../../lib/sdk';
import { keystore } from '../../lib/keystore';
import { getUniqueRandomNumbers } from '../../lib/utils';
import { HeaderTitleService } from '../../services/headerTitle';

@RouteConfig('/signup/:type')
@Component({
  selector: 'signup',
  inputs: ['type'],
  template: `
    <div layout="column" flex layout-padding ng-if="vm.type=='company'">
      <h1>Register new <span>Company</span></h1>
      <hr />
      <p>Please enter your email and choose a password, upon submit you will receieve an email with a confirmation link.</p>
      <form name="signupForm" layout="column" flex>
        <md-input-container flex md-no-float ng-class="!vm.nameIsValid && signupForm.name.$touched ? 'md-input-invalid' : ''">
          <input ng-model="vm.name" placeholder="Company Name" name="name" required flex ng-keyup="vm.validate()">
          <span class="invalid" ng-show="signupForm.name.$touched && !vm.nameIsValid">Please enter a valid name.</span>
        </md-input-container>
        <md-input-container flex md-no-float>
          <input type="email" placeholder="Email" ng-model="vm.email" name="email" required flex>
          <span class="invalid" ng-show="signupForm.email.$touched && !signupForm.email.$valid">Please enter a valid email address.</span>
        </md-input-container>
        <md-input-container flex>
          <md-select md-no-asterisk md-no-underline ng-model="vm.country" placeholder="Country" name="country" required flex>
            <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
          </md-select>
        </md-input-container>
        <md-input-container flex md-no-float>
          <input ng-model="vm.socialId" placeholder="Company Registration Number" name="socialId" required flex>
        </md-input-container>
        <md-input-container flex md-no-float ng-class="!vm.passwordIsValid && signupForm.password.$touched ? 'md-input-invalid' : ''">
          <input type="password" placeholder="Password" ng-model="vm.password" name="password" required flex ng-keyup="vm.validate()">
          <span class="invalid" ng-show="signupForm.password.$touched && !vm.passwordIsValid">Your password should have a minimum length of 8 characters, contain 1 capital letter and 1 number.</span>
        </md-input-container>
        <md-input-container flex md-no-float ng-class="vm.password!=vm.passwordConfirm && signupForm.passwordConfirm.$touched ? 'md-input-invalid' : ''">
          <input type="password" placeholder="Password Confirm" ng-model="vm.passwordConfirm" name="passwordConfirm" required flex>
          <span class="invalid" ng-show="signupForm.passwordConfirm.$touched && vm.password!=vm.passwordConfirm">Your passwords do not match.</span>
        </md-input-container>
        <div layout="row" flex>
          <submit-and-back flex
            disable="!signupForm.$valid || vm.password!=vm.passwordConfirm || !vm.nameIsValid || !vm.passwordIsValid"
            submit="vm.submitCompany($event)"
            label="'Register'"
          >
          </submit-and-back>
        </div>
        <p ng-show="vm.confirmationMessage" ng-bind-html="vm.confirmationMessage"></p>
        <div ng-show="vm.errorMessage">{{vm.errorMessage}}</div>
      </form>
    </div>
    <div layout="column" flex layout-padding ng-if="vm.type=='individual'">
      <h1>Register new <span>Individual</span></h1>
      <hr />
      <p>Please enter your email and choose a password, upon submit you will receieve an email with a confirmation link.</p>
      <form name="signupForm" layout="column" flex>
        <md-input-container flex md-no-float ng-class="!vm.firstNameIsValid && signupForm.firstName.$touched ? 'md-input-invalid' : ''">
          <input ng-model="vm.firstName" placeholder="First Name" name="firstName" required ng-keyup="vm.validate()">
          <span class="invalid" ng-show="signupForm.firstName.$touched && !vm.firstNameIsValid">Please enter a valid name.</span>
        </md-input-container>
        <md-input-container flex md-no-float ng-class="!vm.lastNameIsValid && signupForm.lastName.$touched ? 'md-input-invalid' : ''">
          <input ng-model="vm.lastName" placeholder="Last Name" name="lastName" required ng-keyup="vm.validate()">
          <span class="invalid" ng-show="signupForm.lastName.$touched && !vm.lastNameIsValid">Please enter a valid name.</span>
        </md-input-container>
        <md-input-container flex md-no-float>
          <input type="email" placeholder="Email" ng-model="vm.email" name="email" required flex>
          <span class="invalid" ng-show="signupForm.email.$touched && !signupForm.email.$valid">Please enter a valid email address.</span>
        </md-input-container>
        <md-input-container flex>
          <md-select md-no-asterisk md-no-underline ng-model="vm.country" placeholder="Country" name="country" required flex>
            <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
          </md-select>
        </md-input-container>
        <md-input-container flex md-no-float ng-class="!vm.passwordIsValid && signupForm.password.$touched ? 'md-input-invalid' : ''">
          <input type="password" placeholder="Password" ng-model="vm.password" name="password" required flex ng-keyup="vm.validate()">
          <span class="invalid" ng-show="signupForm.password.$touched && !vm.passwordIsValid">Your password should have a minimum length of 8 characters, contain 1 capital letter and 1 number.</span>
        </md-input-container>
        <md-input-container flex md-no-float ng-class="vm.password!=vm.passwordConfirm && signupForm.passwordConfirm.$touched ? 'md-input-invalid' : ''">
          <input type="password" placeholder="Password Confirm" ng-model="vm.passwordConfirm" name="passwordConfirm" required flex>
          <span class="invalid" ng-show="signupForm.passwordConfirm.$touched && vm.password!=vm.passwordConfirm">Your passwords do not match.</span>
        </md-input-container>
        <div layout="row" flex>
          <submit-and-back flex
            disable="!signupForm.$valid || vm.password!=vm.passwordConfirm || !vm.firstNameIsValid || !vm.lastNameIsValid || !vm.passwordIsValid"
            submit="vm.getKeys()"
            label="'Register'"
          >
          </submit-and-back>
        </div>
        <p ng-show="vm.confirmationMessage" ng-bind-html="vm.confirmationMessage"></p>
        <div ng-show="vm.errorMessage">{{vm.errorMessage}}</div>
      </form>
    </div>
  `
})
@Inject('$scope','countries','server','errors','keys','$location', '$mdDialog','headerTitleService','$translate')
export class SignupComponent {

  /* @input */
  type: string;
  name: string = "";
  nameIsValid: boolean = false;
  firstName: string;
  firstNameIsValid: boolean = false;
  lastName: string;
  lastNameIsValid: boolean = false;
  email: string = "";
  country: string = "";
  password: string = "";
  passwordIsValid: boolean = false;
  passwordConfirm: string = "";
  confirmationMessage: string;
  errorMessage: string;
  socialId: string = ""

  constructor(private $scope: angular.IScope,
              public countries: CountriesService,
              private server: ServerService,
              private errors: ErrorsService,
              private keys: KeysService,
              private $location: angular.ILocationService,
              private $mdDialog: angular.material.IDialogService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService) {
                this.headerTitleService.setTitle({
                  title: this.$translate.instant(`translate.register.${this.type}.label`)
                });
  }

  onSubmitted() {
    this.$scope.$evalAsync(()=>{
      this.$mdDialog.show(
        this.$mdDialog.alert()
          .clickOutsideToClose(true)
          .title('Signup Success')
          .textContent('Thank you for signing up! Please verify your email address by clicking on the link in your email.')
          .ok('OK')
      ).then(() => {
        this.$location.path('login');
      });
    })
  }

  getKeys() {
    instance.secretGenerator.generate().then(
      key => {
        const phraseIndicesToGet = getUniqueRandomNumbers(1, 12, 3, true);
        const keysInKey = key.split(/\s+/);
        const exactPhraseKey = () => {
          let wordsToWrite = [];

          phraseIndicesToGet.forEach(phrase => {
            wordsToWrite.push(keysInKey[phrase - 1]);
          });

          return wordsToWrite.join(" ");
        };
        const getPhraseDescription = () => {
          let description = '';

          phraseIndicesToGet            
            .forEach((phraseIndex, index) => {
              let part = `${phraseIndex}`;

              switch(phraseIndex) {
                case 1:
                  part += 'st';
                  break;
                case 2:
                  part += 'nd';
                  break;
                case 3:
                  part += 'rd';
                  break;
                default:
                  part += 'th';
                  break;
              }

              description += part;

              if(index < (phraseIndicesToGet.length - 1)) {
                if(index != (phraseIndicesToGet.length - 2)) {
                  description += ', ';
                }
                else {
                  description += ' and ';
                }
              }
            });

          return description;
        }

        function DialogController($scope, $mdDialog) {
          $scope.keyCheck = '';
          $scope.mayContinue = false;

          $scope.hide = function() {
            $mdDialog.hide();
          };
        
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        
          $scope.answer = function(answer) {
            if (answer === 'ok') {
              $mdDialog.hide();
            } else {
              $mdDialog.cancel();
            }
          };

          $scope.keyCheckChange = function() {
            const words = exactPhraseKey();
            if (words === $scope.keyCheck) {
              $scope.mayContinue = true;
            } else {
              $scope.mayContinue = false;
            }
          }
  
        }
        this.$mdDialog.show({
          controller: DialogController,
          template: `
          <md-dialog aria-label="Private Key Check" class="private-key-warning">
            <form ng-cloak>
              <md-dialog-content>
                <div class="md-dialog-content">
                  <h3>
                    You need to back up your private key.
                  </h3>
                  <h4 ng-hide="isConfirmed" class="heading">
                    This is your private key:
                  </h4>
                  <div ng-hide="isConfirmed" class="key">
                    ${key}
                  </div>
                  <div ng-hide="isConfirmed">
                    <md-checkbox ng-model="isConfirmed" aria-label="Checkbox 1">
                      I safely stored my private key
                    </md-checkbox>
                  </div>
                  <div ng-hide="!isConfirmed">
                    <h4 class="heading">
                      Please enter the ${getPhraseDescription()} words of your private key
                    </h4>
                    <md-input-container class="md-block" flex-gt-xs>
                      <label>3 words</label>
                      <input ng-model="keyCheck" ng-keyUp="keyCheckChange($event)" name="keyCheck">
                    </md-input-container>
                  </div>
                </div>
              </md-dialog-content>          
              <md-dialog-actions layout="row">
                <span flex></span>
                <md-button ng-click="answer('cancel')">
                  Cancel
                </md-button>
                <md-button ng-click="answer('ok')" ng-disabled="!mayContinue">
                  OK
                </md-button>
              </md-dialog-actions>
            </form>
          </md-dialog>
          `,
          clickOutsideToClose:true
        })
        .then(() => {
          if(this.type=='individual') {
            this.submitIndividual(key)
          } else {
            this.submitCompany(key);
          }
        });
      })
  }

  submitCompany(key) {
    let publicKey = instance.crypto.secretPhraseToPublicKey(key)
    let account = instance.crypto.getAccountIdFromPublicKey(publicKey)
        
    this.server.signup({
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      clientType: ClientType.LEGAL,
      countryCode: this.country,
      companyRegistrationNumber: this.socialId,
      account: account,
      publicKey: publicKey
    }).then(response => {
      if (response.success) {
        keystore.storeKey(this.email, this.password, key)
        this.onSubmitted();
      }
      else {
        this.errors.error('unexpected error', response)
      }
    }).catch(e => {
      const error: ServerEngineError = e;
      this.errors.error(error.description)
    })
  }

  submitIndividual(key) {
    let publicKey = instance.crypto.secretPhraseToPublicKey(key)
    let account = instance.crypto.getAccountIdFromPublicKey(publicKey)

    this.server.signup({
      // this needs fixing server side. The SignupHndler must support firstName + lastName
      name: this.firstName + " " + this.lastName,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      clientType: ClientType.NATURAL,
      countryCode: this.country,
      companyRegistrationNumber: "NA",
      account: account,
      publicKey: publicKey
    }).then(response => {
      if (response.success) {
        keystore.storeKey(this.email, this.password, key)
        this.onSubmitted();
      }
      else {
        this.errors.error('unexpected error', response)
      }
    }).catch(e => {
      const error: ServerEngineError = e;
      this.errors.error(error.description)
    })
  }

  validate() {
    this.passwordIsValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.password);
    this.nameIsValid = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(this.name);
    this.firstNameIsValid = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(this.firstName);
    this.lastNameIsValid = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(this.lastName);
  }
}