import { Service, Inject } from '../core/decorators';
import { ServerService, IServerUser, IPaytahPayoutMethod } from './server';
declare var angular: angular.IAngularStatic;

@Service('payoutMethod')
@Inject('$q','server')
export class PayoutMethodService {
  private readyPromise: angular.IPromise<{}>;
  private payoutMethods: Array<IPaytahPayoutMethod>;

  constructor(private $q: angular.IQService,
              private server: ServerService) {
    let deferred = $q.defer();
    this.readyPromise = deferred.promise;
    // this.payoutMethods = [
    //   {
    //     id: 'iban',
    //     idStr: 'iban',
    //     currency: 'EUR',
    //     disabled: false,
    //     name: 'Iban',
    //     fee: 0,
    //     notes: '',
    //     icon: '',
    //     recipient: ''
    //   },
    //   {
    //     id: 'international',
    //     idStr: 'international',
    //     currency: 'EUR',
    //     disabled: false,
    //     name: 'International',
    //     fee: 0,
    //     notes: '',
    //     icon: '',
    //     recipient: ''
    //   },
    // ]
    // deferred.resolve();

    server.payoutMethodList().then(payoutMethods=>{
      this.payoutMethods = payoutMethods;
      deferred.resolve();
    }, deferred.reject);
  }

  public ready(): angular.IPromise<{}> {
    return this.readyPromise;
  }

  public getPayoutMethods() {
    return this.payoutMethods;
  }
}
