declare var dateFormat: DateFormatStatic;
import { RouteConfig, Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { CountriesService } from '../../services/countries';
import { ServerService, IPaytahClient, IPaytahAddress } from '../../services/server';
import { HeaderTitleService } from '../../services/headerTitle';
import { KeysService } from '../../services/key-service';
import { ErrorsService } from '../../services/errors';
import { UserUpdateSettings, ClientGender, AddressType } from '../../lib/protocol';
import * as Long from "long";
import * as utils from '../../lib/utils';
import { ServerEngineError } from '../../services/http';

@RouteConfig('/settings')
@Component({
  selector: 'settings',
  template: `
    <div layout="column" flex>
      <form name="settingsForm" layout="column" flex>
        <div class="container" layout="column" flex>
          <div ng-if="vm.clientType!=='user'">
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.name" disabled name="name" placeholder="Company Name">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.account" disabled placeholder="Account Id">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.verified" disabled placeholder="Verified">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.supportEmail" name="email" required placeholder="Email *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input type="date"
                      ng-model="vm.clientDetail.birthDateAsDate"
                      min="{{vm.minRegDate | date:'dd-MM-yyyy'}}"
                      max="{{vm.maxRegDate | date:'dd-MM-yyyy'}}"
                      placeholder="Date of Incorporation (dd-MM-yyyy) *" 
                      ng-disabled="vm.verified" 
                      name="dateOfBirth"
                      required>
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.tin" ng-disabled="vm.verified" name="companyRegistrationNumber" required placeholder="Company Registration Number *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <md-select ng-model="vm.clientDetail.taxCountryCode" name="taxCountryCode" required flex ng-disabled="vm.verified" placeholder="Tax Country Code *">
                  <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                </md-select>
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.street" name="streetName" required placeholder="Street name *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.streetNo" name="streetNo" required placeholder="House number *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.city" name="city" required placeholder="City *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.zipCode" name="zipCode" required placeholder="Zip Code *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <md-select ng-model="vm.postAddress.country" name="country" required flex name="country" placeholder="Country *">
                  <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                </md-select>
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.supportPhone" name="supportPhone" required placeholder="Phone Number *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.iban" disabled placeholder="Monetum IBAN account">
              </md-input-container>
            </div>
            <div layout="row" ng-if="!vm.privateKey">
              <md-button class="md-primary" ng-click="vm.reveal($event)">Reveal Private Key</md-button>
            </div>
            <div layout="row" ng-if="vm.privateKey">
              <md-button class="md-primary" ng-click="vm.hide($event)">Hide Private Key</md-button>
            </div>
            <div layout="row" ng-if="vm.privateKey">
              <md-input-container flex md-no-float>
                <textarea ng-model="vm.privateKey" readonly rows="3" style="font-family:monospace" placeholder="Private Key"></textarea>
              </md-input-container>
            </div>
            <div layout="row">
              <md-switch md-invert ng-model="vm.emailNotification" aria-label="email notification">
                Email notifications: {{ vm.emailNotification? 'ON':'OFF' }}
              </md-switch>
            </div>            
            <div layout="row" flex>
              <md-button
                class="md-primary upload-docs"
                href="#/upload"
              >
                Verify Account
              </md-button>
            </div>            
            <div layout="row" flex>
              <submit-and-back
                disable="!settingsForm.$valid"
                submit="vm.updateSettings($event)"
                label="'Submit changes'"
              >
              </submit-and-back>
            </div>
          </div>
          <!--
          ***************************************************************************************************************
          ***************************************************************************************************************
          ***************************************************************************************************************
          -->
          <div ng-if="vm.clientType==='user'">
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.firstName" ng-disabled="vm.verified" name="firstName" required placeholder="First name *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.middleName" ng-disabled="vm.verified" name="middleName" placeholder="Middle name">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.lastName" ng-disabled="vm.verified" name="lastName" required placeholder="Last name *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.account" disabled placeholder="Account Id">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.verified" disabled placeholder="Verified">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.supportEmail" name="email" required placeholder="Email *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input type="date"
                      ng-model="vm.clientDetail.birthDateAsDate"
                      min="{{vm.minBirthDate | date:'dd-MM-yyyy'}}"
                      max="{{vm.maxBirthDate | date:'dd-MM-yyyy'}}"
                      placeholder="Birth Date (dd-MM-yyyy) *" 
                      ng-disabled="vm.verified" 
                      name="dateOfBirth"
                      required>              
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <md-select ng-model="vm.clientDetail.taxCountryCode" name="taxCountry" required flex ng-disabled="vm.verified" placeholder="Tax Country Code *">
                  <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                </md-select>
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.tin" ng-disabled="vm.verified" name="taxIdNum" required placeholder="Tax ID number *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-radio-group ng-model="vm.clientDetail.gender" ng-disabled="vm.verified" required>
                <md-radio-button ng-value="vm.ClientGender.MALE" aria-label="MALE">MALE</md-radio-button>
                <md-radio-button ng-value="vm.ClientGender.FEMALE" aria-label="FEMALE">FEMALE</md-radio-button>
              </md-radio-group>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.street" name="streetName" required placeholder="Street name *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.streetNo" name="streetNo" required placeholder="House number *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.city" name="city" required placeholder="City *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.postAddress.zipCode" name="zipCode" required placeholder="Zip Code *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <md-select ng-model="vm.postAddress.country" name="country" required flex placeholder="Country *">
                  <md-option ng-repeat="item in vm.countries.all" ng-value="item.code">{{ item.name }}</md-option>
                </md-select>
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.clientDetail.supportPhone" name="phoneNumber" required placeholder="Phone *">
              </md-input-container>
            </div>
            <div layout="row">
              <md-input-container flex md-no-float>
                <input ng-model="vm.iban" disabled placeholder="Monetum IBAN account">
              </md-input-container>
            </div>            
            <div layout="row" ng-if="!vm.privateKey">
              <md-button class="md-primary" ng-click="vm.reveal($event)">Reveal Private Key</md-button>
            </div>
            <div layout="row" ng-if="vm.privateKey">
              <md-button class="md-primary" ng-click="vm.hide($event)">Hide Private Key</md-button>
            </div>
            <div layout="row" ng-if="vm.privateKey">
              <md-input-container flex md-no-float>
                <textarea ng-model="vm.privateKey" readonly rows="3" style="font-family:monospace" placeholder="Private Key"></textarea>
              </md-input-container>
            </div>
            <div layout="row">
              <md-switch md-invert ng-model="vm.emailNotification" aria-label="email notification">
                Email notifications: {{ vm.emailNotification? 'ON':'OFF' }}
              </md-switch>
            </div>            
            <div layout="row" flex>
              <md-button
                class="md-primary upload-docs"
                href="#/upload"
              >
                Verify Account
              </md-button>
            </div>            
            <div layout="row" flex>
              <submit-and-back
                disable="!settingsForm.$valid"
                submit="vm.updateSettings($event)"
                label="'Submit changes'"
              >
              </submit-and-back>
            </div>
          </div>
        </div>
      </form>
    </div>
  `
})
@Inject('$scope','user','countries', 'server','headerTitleService','$translate','keys','errors')
export class SettingsComponent {
  email: string;
  clientDetail: IPaytahClient;
  clientType: string;
  verified: boolean = false;
  birthDateIsValid: boolean = true;
  incorpDateIsValid: boolean = true;
  incorpDate: Date;
  birthDate: Date;
  gender: any[];
  iban: string;

  /* A client has both billing and post address but for now we use only post address */
  postAddress: IPaytahAddress
  billingAddress: IPaytahAddress

  emailNotification: boolean = false;

  // for displaying the system id and key to the user
  privateKey: string
  account: string

  minBirthDate: Date
  maxBirthDate: Date
  minRegDate: Date
  maxRegDate: Date

  ClientGender = ClientGender

  constructor(private $scope: angular.IScope,
              private user: UserService,
              public countries: CountriesService,
              private server: ServerService,
              private headerTitleService: HeaderTitleService,
              private $translate: angular.translate.ITranslateService,
              private keys: KeysService,
              private errors: ErrorsService) {
                if (!user.requireLogin())
                  return;

    this.headerTitleService.setTitle({
      title: this.$translate.instant('translate.toolbar.settings.label')
    });
    let today = new Date()
    this.minBirthDate = new Date(1900, 1+1, 1);
    this.maxBirthDate = new Date(today.getFullYear()-18, today.getMonth()+1, today.getDate());
    this.minRegDate = new Date(1700, 1+1, 1);
    this.maxRegDate = today

    this.email = user.getEmail();
    this.gender = [{
        code: 0,
        name: 'Male'
      },
      {
        code: 1,
        name: 'Female'
      }
    ];

    user.refresh().then(
      () => {
        $scope.$evalAsync(() => {
          this.clientDetail = user.getClient();
          if (this.clientDetail) {
            this.clientDetail['birthDateAsDate'] = new Date(this.clientDetail.birthDate)
            this.clientType = this.clientDetail.category.id.substring(13, this.clientDetail.category.id.indexOf('.', 13));
            this.verified = this.clientDetail.category.id.substring(14 + this.clientType.length) === 'verified';
            if (this.clientDetail.paymentAccounts && this.clientDetail.paymentAccounts.length > 0) {
                this.iban = utils.formatIBAN(this.clientDetail.paymentAccounts[0].iban);
            }
            this.clientDetail.addresses.forEach(address => {
              if (address.addressType == AddressType.BILLING)
                this.billingAddress = address
              if (address.addressType == AddressType.POST)
                this.postAddress = address
            })

          }
        })
      }
    )
  }

  reveal() {
    this.keys.getKey().then(
      key => {
        this.$scope.$evalAsync(() => {
          this.privateKey = key
        })
      }
    )
  }

  hide() {
    this.privateKey = undefined;
  }

  updateSettings() {
    this.keys.getKey().then(
      key => {
        let birthDate;
        if (this.clientDetail['birthDateAsDate']) {
          birthDate = this.clientDetail['birthDateAsDate'].getTime()+""
        }
        else {
          birthDate = "0"
        }

        let gender = ClientGender.NULL
        if (this.clientDetail.gender == ClientGender.MALE || this.clientDetail.gender == ClientGender.FEMALE)
          gender = this.clientDetail.gender

        UserUpdateSettings.createMessageToSelfTransaction(key, {
          firstName: this.clientDetail.firstName ? this.clientDetail.firstName : null,
          middleName: this.clientDetail.middleName ? this.clientDetail.middleName : null,
          lastName: this.clientDetail.lastName ? this.clientDetail.lastName : null,
          email: this.clientDetail.supportEmail ? this.clientDetail.supportEmail : null,
          dateOfBirth: Long.fromString(birthDate),
          taxCountry: this.clientDetail.taxCountryCode,
          taxIdNum: this.clientDetail.tin,
          gender: gender,
          streetName: this.postAddress.street,
          streetNo: this.postAddress.streetNo,
          city: this.postAddress.city,
          zipCode: this.postAddress.zipCode,
          country: this.postAddress.country,
          phoneNumber: this.clientDetail.supportPhone
        }).then(
          txBytes => {
            this.server.broadcast(txBytes).then(
              response => {
                if (response.success) {
                  this.errors.error("Hooray settings saved.. Now what? Wanna navigate somewhere?");
                }
                else {
                  this.errors.error(response)
                }
              },
              e => {
                const error: ServerEngineError = e;
                this.errors.error(error.description) 
              }
            )
          },
          this.errors.error
        )
      },
      this.errors.error
    )
  }
}