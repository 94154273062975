// importing just the Typescript typings here so global heatsdk comes with typings
import { HOST, PORT, WSURL } from '../services/settings';
import * as _heatsdkType from '../../node_modules/heat-sdk/dist/heat-sdk'
import { AtomicTransfer } from '../../node_modules/heat-sdk/dist/attachment';
declare var heatsdk: typeof _heatsdkType

export var instance = new heatsdk.HeatSDK(new heatsdk.Configuration({
  // TODO we need a genesis key for Monetum.io
  //genesisKey: [33, 0, 0, 0, 0, 0, 0, 0],
  baseURL: `${HOST}:${PORT}/api/v1`,
  websocketURL: WSURL
}))

/**
 * Creates and signs an ARBITRARY_MESSAGE transaction
 * 
 * @param secretPhrase 
 * @param recipientOrRecipientPublicKey 
 * @param messageBytesHex 
 * @returns Promise<transactionBytesAsHEX>
 */
export function createArbitraryMessage(secretPhrase: string, recipientOrRecipientPublicKey: string, messageBytesHex: string): Promise<string> {
  return new Promise((resolve,reject) => {
    var builder = new heatsdk.Builder()
      //.genesisKey(this.instance.config.genesisKey)
      .attachment(heatsdk.attachment.ARBITRARY_MESSAGE)
      .feeHQT("0")
      .amountHQT("0")
    var transaction = new heatsdk.Transaction(this.instance, recipientOrRecipientPublicKey, builder)
    transaction.publicMessage(messageBytesHex, true)
    transaction.sign(secretPhrase).then(
      t => {
        resolve(t.getTransaction().getBytesAsHex())
      }, 
      e => reject(e)
    )
  })
}

/**
 * Creates and signs an ASSET_TRANSFER transaction
 * 
 * @param secretPhrase 
 * @param recipientOrRecipientPublicKey 
 * @param quantity 
 * @param asset 
 * @param messageBytesHex 
 * @returns Promise<transactionBytesAsHEX>
 */
export function createAssetTransfer(secretPhrase: string, recipientOrRecipientPublicKey: string, quantity: string, asset: string, messageBytesHex?: string): Promise<string> {
  return new Promise((resolve,reject) => {
    var builder = new heatsdk.Builder()
      //.genesisKey(this.instance.config.genesisKey)
      .attachment((new heatsdk.attachment.AssetTransfer()).init(asset, quantity))
      .feeHQT("0")
      .amountHQT("0")
    var transaction = new heatsdk.Transaction(this.instance, recipientOrRecipientPublicKey, builder)
    if (messageBytesHex) {
      transaction.publicMessage(messageBytesHex, true)
    }
    transaction.sign(secretPhrase).then(
      t => {
        resolve(t.getTransaction().getBytesAsHex())
      }, 
      e => reject(e)
    )
  })
}

/**
 * Creates and signs a ATOMIC_MULTI_TRANSFER transaction
 * 
 * @param secretPhrase 
 * @param recipientOrRecipientPublicKey 
 * @param transfers 
 * @param messageBytesHex 
 */
export function createMultiAssetTransfer(secretPhrase: string, recipientOrRecipientPublicKey: string, transfers: Array<AtomicTransfer>, messageBytesHex?: string): Promise<string> {
  return new Promise((resolve,reject)=> {
    var builder = new heatsdk.Builder()
      //.genesisKey(this.instance.config.genesisKey)
      .attachment(new heatsdk.attachment.AtomicMultiTransfer().init(transfers))
      .feeHQT("0")
      .amountHQT("0")
    var transaction = new heatsdk.Transaction(this.instance, recipientOrRecipientPublicKey, builder)
    if (messageBytesHex) {
      transaction.publicMessage(messageBytesHex, true)
    }
    transaction.sign(secretPhrase).then(
      t => {
        resolve(t.getTransaction().getBytesAsHex())
      }, 
      e => reject(e)
    )
  })
}
