import { Component, Inject} from '../../core/decorators';
import { UserService } from '../../services/user-service';
import { HeaderTitleService } from '../../services/headerTitle';

@Component({
  selector: 'mainblock',
  template: `
    <div layout="column" layout-fill resize="vm.isSmallerVersion" ng-class="{'mobile': vm.mobileVersion, 'tablet': vm.tabletVersion}">
      <header>
        <toolbar></toolbar>
      </header>
      <main layout="row" flex ng-class="(vm.user.isUnlocked() === false) ? 'logged-out' : 'logged-in'">
        <div layout="column" layout-fill>
          <div layout="row" flex >
            <div layout="column" flex class="application-content">
              <div layout="row" ng-if="vm.user.isUnlocked()" class="page-title">
                <img class="hide-gt-sm" alt="Back" src="assets/back-top.png" ng-if="!vm.showTitleOnly" ng-click="vm.back()" />
                <h2 class="title">{{vm.title}}</h2>
              </div>
              <div layout="row">
                <div ng-viewport flex layout="column"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <disclaimer></disclaimer>
      </footer>
    </div>
  `
})
@Inject('$scope','user','$location','headerTitleService')
export class MainComponent {
  tabletVersion: boolean = false;
  mobileVersion: boolean = false;
  title: string;
  showTitleOnly: boolean = true;
  constructor(private $scope: angular.IScope,
              private user: UserService,
              private $location: angular.ILocationService,
              private headerTitleService: HeaderTitleService) {
                this.headerTitleService.subscribe(this.setTitle.bind(this));
                this.isSmallerVersion.bind(this);
                this.isSmallerVersion();
  }

  getWindowWidth() {
    return this.$scope['windowWidth'];
  }

  isSmallerVersion() {
    this.$scope.$evalAsync(() => {
      this.tabletVersion = this.getWindowWidth() < 960;
      this.mobileVersion = this.getWindowWidth() < 600;
    })
  }

  setTitle(title: any) {
    this.title = title.title;

    if (title.isMainMenu || !this.user.isUnlocked()) {
      this.showTitleOnly = true;
    } else {
      this.showTitleOnly = false;
    }
  }

  back() {
    this.$location.path('greet');
  }
}